import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventType = (config: any) => void;

type UseAnalyticsType = () => {
  dispatchGenericEvent: (event: EventType, config: Record<string, unknown>) => void;
};

export const useAnalytics: UseAnalyticsType = () => {
  const dispatchGenericEvent = useCallback((event: EventType, config: Record<string, unknown>) => {
    event(config);
  }, []);

  return { dispatchGenericEvent };
};
