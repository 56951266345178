export const GROW_MISSIONS_SERVICE_CONTEXT = '/grow-missions-service';
export const GROW_ADMIN_CONFIG_SERVICE_CONTEXT = '/api/v1/grow-admin-config-service';

export const getApiHost = (): string => {
  return '';
};

export const getApiHostGrowMissionsService = (): string => {
  return GROW_MISSIONS_SERVICE_CONTEXT;
};

export const getApiHostGrowAdminConfigService = (): string => {
  return GROW_ADMIN_CONFIG_SERVICE_CONTEXT;
};
