import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { getApiHostGrowMissionsService } from '../../host/HostService';

export async function deleteServicesCatalog(id: string, vendorId?: string): Promise<any> {
  const isCustomMissionsEndpointV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const log = useLogService();
  let url = `${getApiHostGrowMissionsService()}/grow-missions/admin/services/${id}`;

  if (isCustomMissionsEndpointV2Enabled) {
    url = `${getApiHostGrowMissionsService()}/grow-missions/admin/v2/services/${id}?vendorId=${
      vendorId ?? ''
    }`;
  }

  return axios
    .delete(`${url}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
