import { updateServicesCatalog } from '../../../services/servicesMission/updateServicesCatalog/updateServicesCatalog';
import { servicesCatalogError400 } from './ServicesCatalogEvents';
import { UpdateServiceCatalogRequest } from './domains/updateServicesCatalogRequest';

export function execute(request: UpdateServiceCatalogRequest): Promise<any> {
  return updateServicesCatalog(request, request?.vendorId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;

      if (code === 400) {
        servicesCatalogError400();
      }

      throw error;
    });
}
