import palette from './palette';

export default {
  MuiCssBaseline: {
    '@global': {
      '*': {
        fontFamily: 'Roboto',
      },
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px transparent',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: palette.grey[100],
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: palette.grey[100],
      },
    },
  },
  MuiDrawer: {
    paperAnchorDockedLeft: {
      borderRight: 'none',
      boxShadow: '0 0.75rem 0.3rem rgb(0 0 0 / 10%)',
    },
    paper: {
      top: '56px',
    },
  },
  MuiButton: {
    root: {
      padding: '8px 16px 8px 16px',
    },
    contained: {
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.24)',
    },
    outlined: {
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      padding: '8px 16px 8px 16px',
    },
    containedSizeSmall: {
      fontSize: '14px',
      height: '32px',
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0 0 0 16px',
        '& $input:first-child': {
          padding: 0,
        },
      },
    },
  },

  MuiTableCell: {
    root: {
      fontSize: '16px',
      lineHight: '20px',
      letterSpacing: '0',
    },
    head: {
      border: 'none',
      boxShadow: 'none',
      padding: '0px 32px',
      '&:first-child': {
        paddingLeft: '0',
      },
      '&:last-child': {
        paddingRight: '0',
      },
    },
    body: {
      border: 'none',
      boxShadow: 'inset 0 -1px 0 0 #DFDEDE',
      padding: '32px',
      '&:first-child': {
        paddingLeft: '0',
      },
      '&:last-child': {
        paddingRight: '0',
      },
    },
  },
  MuiPaginationItem: {
    page: {
      color: palette.info.main,
      textDecoration: 'underline',
      fontSize: '16px',
      lineHeight: '20px',
      '&$selected': {
        backgroundColor: palette.common.white,
        color: palette.common.black,
        fontWeight: '600',
        textDecoration: 'initial',
        cursor: 'unset',
        '&:hover': {
          backgroundColor: palette.common.white,
        },
        '&:active': {
          backgroundColor: palette.common.white,
        },
      },
      '& svg': {
        color: palette.common.black,
      },
    },
  },
  MuiDialog: {
    paperScrollPaper: {
      overflowY: 'hidden',
    },
  },
  MuiDialogContent: {
    root: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 80px - 70px - 72px)', // modal padding, modal header and footer height
      '&:first-child': {
        paddingTop: '0px',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#707372',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    },
  },
  MuiTextField: {
    root: {
      flexDirection: 'column-reverse',
      marginTop: '0px',
      '& p.Mui-error': {
        marginBottom: '4px',
        marginLeft: '16px',
      },
      '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
      },
      '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
      '& div': {
        '& input': {
          paddingTop: '5px',
          paddingBottom: '5px',
          height: '20px',
        },
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: '12px',
      lineHeight: '16px',
      color: palette.text.secondary,
      margin: '0 0 4px 0',
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$checked': {
        color: palette.text.primary,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: '0px',
    },
  },
};
