import { getServiceMission } from '../../../services/servicesMission/getServiceMission/getServiceMission';
import { servicesMissionError400 } from './ServicesMissionEvents';

export function execute(serviceId: string) {
  return getServiceMission(serviceId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;
      if (code === 400) {
        servicesMissionError400();
      }
      throw error;
    });
}
