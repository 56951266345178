import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    formWrapper: {
      width: '640px',
      height: '640px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '12px 0px',
    },
    availabilityLabelWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingRight: '10px',
    },
    applyButton: {
      backgroundColor: '#000',
      color: '#fff',
      border: 'solid 1px #000',
      padding: '10px 20px',
      fontFamily: 'Work Sans',
    },
    cancelButton: {
      padding: '10px 20px',
      marginRight: 16,
      fontFamily: 'Work Sans',
      border: 'solid 1px #000',
    },
    modalButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    princingMethodWrapper: {
      height: '110px',
    },
    princingValueWrapper: {
      position: 'relative',
    },
    princingValueSufix: {
      position: 'absolute',
      right: '16px',
      bottom: '24px',
      height: '0px',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Work Sans',
      color: 'rgba(0,0,0,0.55)',
      userSelect: 'none',
    },
    princingCheckboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 0,
    },
    textArea: {
      height: '152px',
      resize: 'none',
    },
  }),
);
