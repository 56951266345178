import { Store, createStore } from 'effector';
import { OrganizationByIdState, initialOrganizationByIdState } from './OrganizationByIdState';
import { getOrganizationByIdEffect } from './OrganizationByIdEvents';

export const OrganizationByIdStore: Store<OrganizationByIdState> = createStore(
  initialOrganizationByIdState,
)
  .on(getOrganizationByIdEffect, (state: OrganizationByIdState) => ({
    ...state,
    errorOrganizationById: false,
    doneOrganizationById: false,
    loadingOrganizationById: true,
  }))
  .on(getOrganizationByIdEffect.fail, (state: OrganizationByIdState) => ({
    ...state,
    errorOrganizationById: true,
    doneOrganizationById: true,
    loadingOrganizationById: false,
  }))
  .on(getOrganizationByIdEffect.done, (state: OrganizationByIdState, { result }) => ({
    ...state,
    errorOrganizationById: false,
    doneOrganizationById: true,
    loadingOrganizationById: false,
    organization: result,
  }));
