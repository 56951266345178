import React from 'react';

function MarketplaceMissionsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <defs>
        <linearGradient id="eijz4jzslc" x1="50%" x2="50%" y1="-26%" y2="122.277%">
          <stop offset="0%" stopColor="#FF0" />
          <stop offset="100%" stopColor="#EEEB00" />
        </linearGradient>
        <circle id="ozl2h9rt0a" cx="21.094" cy="21.094" r="21.094" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.406 1.406)">
          <mask id="mrh2hngltb" fill="#fff">
            <use xlinkHref="#ozl2h9rt0a" />
          </mask>
          <g fill="url(#eijz4jzslc)" mask="url(#mrh2hngltb)">
            <path d="M0 0h42.188v42.188H0z" />
          </g>
        </g>
        <g fillRule="nonzero">
          <path
            fill="#BEE75E"
            d="M35.967 17.18l-1.028-1.025V13.05a1.02 1.02 0 00-1.02-1.018h-3.062a1.02 1.02 0 00-1.021 1.018v3.106l-1.028 1.025a5.722 5.722 0 00-1.694 4.079v.407l5.274 6.107h4.252a1.02 1.02 0 001.021-1.017V21.26a5.76 5.76 0 00-1.694-4.079z"
          />
          <path
            fill="#00CB75"
            d="M35.967 17.18l-1.028-1.025V13.05a1.02 1.02 0 00-1.02-1.018h-1.531v15.742h4.252a1.02 1.02 0 001.021-1.017V21.26a5.76 5.76 0 00-1.694-4.079z"
          />
          <path
            fill="#AF8663"
            d="M34.94 10.334a1.02 1.02 0 00-1.021-1.018h-3.062a1.02 1.02 0 00-1.021 1.018v2.715h5.103v-2.715z"
          />
          <path
            fill="#FFD7A0"
            d="M34.259 26.756v-4.072a1.02 1.02 0 00-1.02-1.018h-6.125v5.09a1.02 1.02 0 001.02 1.017h5.104a1.02 1.02 0 001.02-1.017z"
          />
          <path
            fill="#FFB042"
            d="M33.238 27.773a1.02 1.02 0 001.02-1.017v-4.072a1.02 1.02 0 00-1.02-1.018h-.85v6.107h.85z"
          />
          <path
            fill="#896645"
            d="M34.94 10.334a1.02 1.02 0 00-1.021-1.018h-1.531v3.733h2.551v-2.715z"
          />
          <path
            fill="#BEE75E"
            d="M25.397 17.18l-1.02-1.025V13.05c0-.563-.455-1.018-1.015-1.018H20.32c-.56 0-1.014.455-1.014 1.018v3.106l-1.022 1.025a5.741 5.741 0 00-1.683 4.079v.407l5.24 6.107h4.225c.56 0 1.014-.455 1.014-1.017V21.26c0-1.53-.605-2.997-1.683-4.079z"
          />
          <path
            fill="#00CB75"
            d="M25.397 17.18l-1.02-1.025V13.05c0-.563-.455-1.018-1.015-1.018h-1.521v15.742h4.225c.56 0 1.014-.455 1.014-1.017V21.26c0-1.53-.605-2.997-1.683-4.079z"
          />
          <path
            fill="#AF8663"
            d="M24.376 10.334c0-.562-.454-1.018-1.014-1.018H20.32c-.56 0-1.014.456-1.014 1.018v2.715h5.07v-2.715z"
          />
          <path
            fill="#FFD7A0"
            d="M23.7 26.756v-4.072c0-.562-.454-1.018-1.014-1.018H16.6v5.09c0 .562.454 1.017 1.014 1.017h5.07c.561 0 1.015-.455 1.015-1.017z"
          />
          <path
            fill="#FFB042"
            d="M22.686 27.773c.56 0 1.014-.455 1.014-1.017v-4.072c0-.562-.454-1.018-1.014-1.018h-.845v6.107h.845z"
          />
          <path
            fill="#896645"
            d="M24.376 10.334c0-.562-.454-1.018-1.014-1.018h-1.521v3.733h2.535v-2.715z"
          />
          <path
            fill="#BEE75E"
            d="M16.192 17.18l-1.028-1.025V13.05a1.02 1.02 0 00-1.02-1.018H11.08a1.02 1.02 0 00-1.02 1.018v3.106L9.033 17.18a5.722 5.722 0 00-1.694 4.079v.407l5.273 6.107h4.253a1.02 1.02 0 001.02-1.017v-5.497a5.76 5.76 0 00-1.693-4.079z"
          />
          <path
            fill="#00CB75"
            d="M16.192 17.18l-1.028-1.025V13.05a1.02 1.02 0 00-1.02-1.018h-1.532v15.742h4.253a1.02 1.02 0 001.02-1.017V21.26a5.76 5.76 0 00-1.693-4.079z"
          />
          <path
            fill="#AF8663"
            d="M15.164 10.334a1.02 1.02 0 00-1.02-1.018H11.08a1.02 1.02 0 00-1.02 1.018v2.715h5.103v-2.715z"
          />
          <path
            fill="#FFD7A0"
            d="M14.484 26.756v-4.072a1.02 1.02 0 00-1.021-1.018H7.339v5.09a1.02 1.02 0 001.02 1.017h5.104a1.02 1.02 0 001.02-1.017z"
          />
          <path
            fill="#FFB042"
            d="M13.463 27.773a1.02 1.02 0 001.02-1.017v-4.072a1.02 1.02 0 00-1.02-1.018h-.85v6.107h.85z"
          />
          <path
            fill="#896645"
            d="M15.164 10.334a1.02 1.02 0 00-1.02-1.018h-1.532v3.733h2.552v-2.715zM6.02 26.455h32.3v15.82H6.02z"
          />
          <path fill="#63544F" d="M22.5 26.455h15.82v15.82H22.5z" />
          <path
            fill="#AF8663"
            d="M38.634 25.137H6.366c-.555 0-1.005.459-1.005 1.025v16.406c0 .567.45 1.026 1.005 1.026h32.268c.555 0 1.005-.46 1.005-1.026V26.162c0-.566-.45-1.025-1.005-1.025zM12.124 37.44v-2.05h20.753v2.05H12.123zm20.753 2.051v2.051H12.123v-2.05h20.754zm0-8.203v2.05H12.123v-2.05h20.754zm-20.754-2.05v-2.052h20.754v2.051H12.123zm25.507 4.1h-2.745v-2.05h2.745v2.05zm-27.515 0H7.37v-2.05h2.745v2.05zM7.37 35.392h2.745v2.05H7.37v-2.05zm27.515 0h2.745v2.05h-2.745v-2.05zm2.745-6.153h-2.745v-2.05h2.745v2.05zm-30.26-2.05h2.745v2.05H7.37v-2.05zm0 12.304h2.745v2.051H7.37v-2.05zm30.26 2.051h-2.745v-2.05h2.745v2.05z"
          />
          <path
            fill="#896645"
            d="M38.634 25.137H22.5v2.05h10.377v2.051H22.5v2.051h10.377v2.05H22.5v2.052h10.377v2.05H22.5v2.051h10.377v2.051H22.5v2.05h16.134c.555 0 1.005-.458 1.005-1.025V26.162c0-.566-.45-1.025-1.005-1.025zM37.63 41.543h-2.745v-2.05h2.745v2.05zm0-4.102h-2.745v-2.05h2.745v2.05zm0-4.101h-2.745v-2.05h2.745v2.05zm0-4.102h-2.745v-2.05h2.745v2.05z"
          />
        </g>
      </g>
    </svg>
  );
}

export default MarketplaceMissionsIcon;
