import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import GetServiceScriptRequest from '../domains/GetServiceScriptRequest';
import GetServiceScriptResponse from '../domains/GetServiceScriptResponse';

export async function getServicesScript({
  country,
  vendorId,
}: GetServiceScriptRequest): Promise<GetServiceScriptResponse> {
  const log = useLogService();
  const isCustomMissionV2 = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);
  const baseUrl = `${getApiHostGrowMissionsService()}/grow-missions/admin`;

  const url = isCustomMissionV2
    ? `${baseUrl}/v2/services/${country}/script?vendorId=${vendorId ?? ''}`
    : `${baseUrl}/services/${country}/script`;

  return axios
    .get<GetServiceScriptResponse>(`${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
