import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    listItem: {
      width: '100%',
      display: 'block',
      padding: '1rem',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&:last-child': {
        border: 'none',
      },
    },
    draggingListItem: {
      background: '#fff',
      width: '100%',
      display: 'block',
      padding: '1rem',
      borderRadius: '5px',
      border: '1px solid rgba(0,0,0,0.15)',
      '&:last-child': {
        border: 'none',
      },
    },
  }),
);
