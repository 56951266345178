import { useLogService } from 'admin-portal-shared-services';
import axiosRetry from 'axios-retry';
import { getUserInfos } from '../../config/utils/functions';
import { Organization } from '../../domains/Organization';
import axios from '../../config/axios/axiosInstance';

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getOrganizationById = async () => {
  const ENDPOINT = `/bees-grow-organizations-service/v1/organizations/${getUserInfos().orgId}`;

  const log = useLogService();
  const url = `${ENDPOINT}`;

  try {
    const response = await axios.get<Organization>(url);
    log.info(`Organization of specified id successfully received from ${url}`);
    return response.data;
  } catch (error) {
    log.error(error);
    throw error;
  }
};
