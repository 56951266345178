import { editServiceMission } from '../../../services/servicesMission/editServiceMission/editServiceMission';
import EditServicesMissionRequest from './domains/EditServicesMissionRequest';
import { servicesMissionError400 } from './ServicesMissionEvents';

export function execute(service: EditServicesMissionRequest, vendorId?: string) {
  return editServiceMission(service, vendorId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;
      if (code === 400) {
        servicesMissionError400();
      }
      throw error;
    });
}
