import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const StarUnfilled = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ACD537B2-CDFF-4C24-8536-E07AB83017DD</title>
      <defs>
        <path
          d="M15.8751767,13.945 L1.71292025,16.0150279 L1.56210176,16.0426211 C0.0340405757,16.3798544 -0.543376393,18.306531 0.60671107,19.4267162 L10.8521767,29.406 L8.43495943,43.5059133 L8.41491771,43.6533377 C8.25844012,45.2134958 9.91428249,46.3623815 11.3370792,45.614147 L24.0021767,38.953 L36.6672741,45.614147 L36.8012816,45.6787814 C38.2366463,46.3098969 39.8411389,45.0903249 39.5693939,43.5059133 L37.1511767,29.406 L47.3976423,19.4267162 L47.5035743,19.315874 C48.5430182,18.1461502 47.8800178,16.2472233 46.2914331,16.0150279 L32.1281767,13.945 L25.7955914,1.11475217 C25.0619206,-0.371584057 22.9424327,-0.371584057 22.208762,1.11475217 L15.8751767,13.945 Z M24.0021767,6.518 L29.006762,16.6572478 L29.0850779,16.8008284 C29.3921833,17.3129167 29.9144376,17.6637873 30.5109202,17.7509721 L41.7051767,19.386 L33.6067111,27.2752838 L33.494157,27.3942539 C33.1013444,27.8450512 32.9289151,28.4511163 33.0309594,29.0460867 L34.9411767,40.186 L24.9330792,34.923853 L24.7714994,34.8478685 C24.2246564,34.6199152 23.6010611,34.6452433 23.0712741,34.923853 L13.0621767,40.186 L14.9733939,29.0460867 L14.9944597,28.8836719 C15.0470467,28.2880593 14.8300774,27.6964756 14.3976423,27.2752838 L6.29817667,19.386 L17.4934331,17.7509721 C18.1441415,17.6558614 18.7065119,17.2469428 18.9975914,16.6572478 L24.0021767,6.518 Z"
          id="star-path-1"
        />
      </defs>
      <g id="___Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ZZ_Color-Override/Primary/Black" transform="translate(7.997823, 9.000000)">
          <mask id="star-unfilled-b" fill="white">
            <use xlinkHref="#star-path-1" />
          </mask>
          <g id="Mask" fillRule="nonzero" />
          <g id="ZZ_Color-Override/04_Neutral/100" mask="url(#star-unfilled-b)" fill="#000000">
            <g transform="translate(-7.997823, -9.000000)" id="Rectangle">
              <rect x="0" y="0" width="64" height="64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

StarUnfilled.defaultProps = {
  width: 24,
  height: 24,
};

export default StarUnfilled;
