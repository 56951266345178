import { getServicesCatalog } from '../../../services/servicesMission/getServicesCatalog/getServicesCatalog';
import { servicesCatalogError400 } from './ServicesCatalogEvents';
import { CatalogItem } from './domains/ServicesCatalogResponse';

export function execute(vendorId?: string): Promise<CatalogItem[]> {
  return getServicesCatalog(vendorId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;

      if (code === 400) {
        servicesCatalogError400();
      }

      throw error;
    });
}
