import { createEffect, createEvent } from 'effector';
import { execute as createServiceUseCase } from './CreateServicesMissionUserCase';
import { execute as editServiceMission } from './EditServicesMissionUserCase';
import { execute as getServiceMissionUseCase } from './GetServicesMissionUserCase';

import CreateServicesMissionRequest from './domains/CreateServicesMissionRequest';
import EditServicesMissionRequest from './domains/EditServicesMissionRequest';

interface EditServiceProps {
  serviceMission: EditServicesMissionRequest;
  vendorId?: string;
}

export const getServicesMissionEffect = createEffect({
  async handler(serviceMissionId: string) {
    return getServiceMissionUseCase(serviceMissionId);
  },
});

export const servicesMissionError400 = createEvent('servicesMissionError400');

export const createServicesMissionEffect = createEffect({
  async handler(serviceMission: CreateServicesMissionRequest) {
    return createServiceUseCase(serviceMission);
  },
});

export const editServicesMissionEffect = createEffect({
  async handler({ serviceMission, vendorId = null }: EditServiceProps) {
    return editServiceMission(serviceMission, vendorId);
  },
});

export const clearServicesMissionErrors = createEvent('servicesMissionClearErrors');
