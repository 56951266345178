import React from 'react';

interface Props {
  size?: string;
  disabled?: boolean;
}

const Plus = ({ size = '24px', disabled }: Props): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M24,0 C25.5385075,0 26.8065215,1.15812057 26.9798168,2.65013662 L27,3 L27,21 L45,21 C46.6568542,21 48,22.3431458 48,24 C48,25.5385075 46.8418794,26.8065215 45.3498634,26.9798168 L45,27 L27,27 L27,45 C27,46.6568542 25.6568542,48 24,48 C22.4614925,48 21.1934785,46.8418794 21.0201832,45.3498634 L21,45 L21,27 L3,27 C1.34314575,27 0,25.6568542 0,24 C0,22.4614925 1.15812057,21.1934785 2.65013662,21.0201832 L3,21 L21,21 L21,3 C21,1.34314575 22.3431458,0 24,0 Z"
          id="grow-plus-path-1"
        />
      </defs>
      <g id="___Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ZZ_Color-Override/Black" transform="translate(8.000000, 8.000000)">
          <mask id="grow-plus-mask-2" fill="white">
            <use href="#grow-plus-path-1" />
          </mask>
          <g id="Mask" fillRule="nonzero" />
          <g
            id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            mask="url(#grow-plus-mask-2)"
            fill={disabled ? 'rgba(0,0,0,0.15)' : '#000000'}
            fillOpacity="0.9"
          >
            <g transform="translate(-8.000000, -8.000000)" id="Rectangle">
              <rect x="0" y="0" width="64" height="64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Plus;
