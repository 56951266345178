import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Gift = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="gift-path-1"
          d="M33.818 0a7.636 7.636 0 016.901 10.91h5.1c1.152 0 2.096.893 2.176 2.025l.005.156V24a2.182 2.182 0 01-2.026 2.176l-.156.006-2.182-.001v19.637a2.182 2.182 0 01-1.927 2.167l-.254.015H6.545a2.182 2.182 0 01-2.167-1.927l-.014-.255-.001-19.637H2.182a2.182 2.182 0 01-2.177-2.025L0 24V13.09c0-1.152.894-2.096 2.026-2.175l.156-.006 5.099.001A7.636 7.636 0 0114.182 0c4.278 0 7.465 2.55 9.684 6.585l.136.25.132-.25C26.354 2.549 29.54 0 33.818 0zm-12 26.181H8.727v17.455h13.091V26.181zm17.454 0H26.181v17.455h13.092l-.001-17.455zM21.818 15.272H4.364v6.546h17.454v-6.546zm21.818 0H26.181v6.546h17.455v-6.545zM14.182 4.365a3.273 3.273 0 00-.186 6.54l.186.005h6.877l-.088-.239a18.72 18.72 0 00-.384-.898l-.209-.44-.335-.644c-1.53-2.783-3.458-4.324-5.861-4.324zm19.636 0c-2.403 0-4.33 1.541-5.86 4.324-.262.475-.502.97-.719 1.474l-.21.508-.09.24h6.88a3.274 3.274 0 003.235-2.782l.022-.176.015-.316a3.273 3.273 0 00-3.273-3.272z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(8 8)">
          <mask id="gift-mask-2" fill="#fff">
            <use xlinkHref="#gift-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#gift-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-7.989 -8)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Gift.defaultProps = {
  width: 24,
  height: 24,
};

export default Gift;
