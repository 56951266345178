import { Organization } from '../../domains/Organization';

export interface OrganizationByIdState {
  errorOrganizationById: boolean;
  loadingOrganizationById: boolean;
  doneOrganizationById: boolean;
  organization: Organization;
}

export const initialOrganizationByIdState: OrganizationByIdState = {
  errorOrganizationById: false,
  loadingOrganizationById: false,
  doneOrganizationById: false,
  organization: undefined,
};
