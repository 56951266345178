import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { getUserInfos } from '@config/utils/functions';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { ServiceMission } from '../../../domains/ServiceMission';
import { getApiHostGrowMissionsService } from '../../host/HostService';

export async function getServiceMission(serviceId: string): Promise<ServiceMission> {
  const { vendorId } = getUserInfos();

  const isCustomMissionV2 = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const log = useLogService();

  const baseUrl = `${getApiHostGrowMissionsService()}/grow-missions/admin`;
  const url = isCustomMissionV2
    ? `${baseUrl}/v2/services/${serviceId}?vendorId=${vendorId}`
    : `${baseUrl}/services/${serviceId}`;

  return axios
    .get<ServiceMission>(`${url}`)
    .then((response) => {
      log.info(`${url} returned success`);
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
