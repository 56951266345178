import { createEffect, createEvent } from 'effector';
import { execute as GetServicesCatalogUseCase } from './GetServicesCatalogUseCase';
import { execute as UpdateServicesCatalogUseCase } from './UpdateServicesCatalogUseCase';
import { execute as UpdateServicesCatalogPositionUseCase } from './UpdateServicesCatalogPositionUseCase';
import { execute as DeleteServicesCatalogUseCase } from './DeleteServicesCatalogUseCase';
import { UpdateServiceCatalogPositionRequest } from './domains/updateServicesCatalogPositionRequest';
import { UpdateServiceCatalogRequest } from './domains/updateServicesCatalogRequest';
import { CatalogItem } from './ServicesCatalogState';

export const getServicesCatalogEffect = createEffect({
  async handler(vendorId?: string) {
    return GetServicesCatalogUseCase(vendorId);
  },
});

export const servicesCatalogError400 = createEvent('servicesCatalogError400');

export const deleteServicesCatalogEffect = createEffect({
  async handler({ id, vendorId }: { id: string; vendorId?: string }) {
    return DeleteServicesCatalogUseCase(id, vendorId);
  },
});

export const setItemAvailability = createEvent<CatalogItem>('setItemAvailability');
export const setNewOrder = createEvent<CatalogItem[]>('setNewOrder');

export const deleteCatalogItem = createEvent<string>('deleteCatalogItem');

export const updateServicesCatalogEffect = createEffect({
  async handler(request: UpdateServiceCatalogRequest) {
    return UpdateServicesCatalogUseCase(request);
  },
});

export const updateServicesCatalogPositionEffect = createEffect({
  async handler({
    request,
    vendorId,
  }: {
    request: UpdateServiceCatalogPositionRequest;
    vendorId?: string;
  }) {
    return UpdateServicesCatalogPositionUseCase(request, vendorId);
  },
});
