import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Package = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="package-path-1"
          d="M25.05.745l.247.135 15.412 8.807a6.594 6.594 0 012.068 1.875 2.207 2.207 0 01.542.903 6.6 6.6 0 01.681 2.59l.01.344v17.607a6.604 6.604 0 01-3.085 5.58l-.225.135-15.395 8.797a6.6 6.6 0 01-2.252.8 2.204 2.204 0 01-2.094 0 6.538 6.538 0 01-2-.66l-.246-.135L3.3 38.716a6.602 6.602 0 01-3.296-5.452L0 33.004V15.396a6.596 6.596 0 01.692-2.935 2.19 2.19 0 01.543-.905A6.609 6.609 0 013.03 9.852l.27-.165 7.161-4.094c.324-.363.753-.6 1.213-.692l7.03-4.016a6.599 6.599 0 016.347-.14zM4.4 16.582l.001 16.419a2.2 2.2 0 00.952 1.81l.14.089 14.311 8.177V25.493L4.4 16.582zm35.208 0l-15.403 8.91v17.585l14.303-8.173a2.2 2.2 0 001.094-1.734l.007-.167-.001-16.421zM12.213 9.663L6.687 12.82l15.317 8.86 5.515-3.19-15.306-8.827zm8.845-5.047l-.162.085-4.259 2.433 15.281 8.812 5.404-3.126-14.217-8.124a2.202 2.202 0 00-2.047-.08z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(10 8)">
          <mask id="package-mask-2" fill="#fff">
            <use xlinkHref="#package-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#package-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-10 -8)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Package.defaultProps = {
  width: 24,
  height: 24,
};

export default Package;
