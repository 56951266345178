import React from 'react';
import Typography from '@material-ui/core/Typography';
import { isFeatureEnabled } from 'grow-shared-services';
import { DragAndDropBullets } from '../../../../assets';
import useStyle from './CatalogItem.styles';
import { getIcons } from '../../../../config/utils/functions';
import Button from '../../../../components/button/Button';
import EditIcon from '../../../../assets/icons/EditIcon';
import { CatalogItem as CatalogItemInterface } from '../../../../stores/servicesMission/ServiceScript/ServicesCatalogState';
import Switch from '../../../../components/Switch/Switch';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import {
  TLP_SERVICE_MISSIONS_EDIT_MODAL,
  TLP_SERVICE_MISSIONS_DRAGGABLE,
} from '../../../../config/featureToggles';

interface CatalogItemProps extends CatalogItemInterface {
  isDraggable: boolean;
  onEdit: () => void;
  onDelete: () => void;
  setIsActive: () => void;
}

const CatalogItem = ({
  icon,
  title,
  availability,
  isDraggable,
  setIsActive,
  onEdit,
  onDelete,
}: CatalogItemProps): JSX.Element => {
  const classes = useStyle();

  const isMissionEditEnabled = isFeatureEnabled(TLP_SERVICE_MISSIONS_EDIT_MODAL);
  const isMissionDraggableEnabled = isFeatureEnabled(TLP_SERVICE_MISSIONS_DRAGGABLE);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <section key="info" className={classes.infoSection} style={{ width: '100%' }}>
        {icon && (
          <figure data-testid="services-icon" className={classes.infoIcon}>
            {getIcons(icon, { width: 24, height: 24 })}
          </figure>
        )}
        <Typography data-testid="services-title" className={classes.itemTitle}>
          {title}
        </Typography>
      </section>
      <section key="actions" className={classes.actionsSection} data-testid="list-item-actions">
        <Button
          key="services-delete"
          className={classes.actionsBtn}
          onClick={() => onDelete()}
          data-testid="actions-delete"
        >
          <TrashIcon width={24} height={24} color="#000" />
        </Button>
        {isMissionEditEnabled && (
          <Button
            key="services-edit"
            data-testid="actions-edit"
            className={classes.actionsBtn}
            onClick={() => onEdit()}
          >
            <EditIcon width={24} height={24} color="#000" />
          </Button>
        )}
        <Switch
          onChange={() => {
            setIsActive();
          }}
          checked={availability}
          data-testid="actions-switch"
        />
        {isMissionDraggableEnabled && (
          <div className={classes.draggableContainer} data-testid="draggable-icon-container">
            {isDraggable && (
              <figure className={classes.draggable} data-testid="draggable-icon">
                <DragAndDropBullets width={20} height={21} />
              </figure>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default CatalogItem;
