import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Zap = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="zap-path-1"
          d="M.56 29.666l-.162.218C-.639 31.439.462 33.6 2.404 33.6H21.28l-2.06 16.5c-.297 2.377 2.69 3.674 4.225 1.833L47.439 23.14l.163-.218c1.037-1.556-.064-3.718-2.006-3.718H26.719l2.062-16.5C29.078.328 26.09-.97 24.556.872L.561 29.666zm22.442-19.434l-1.383 11.074-.019.284a2.4 2.4 0 002.4 2.413h16.47L24.996 42.571l1.385-11.072.019-.283a2.4 2.4 0 00-2.4-2.414H7.527l15.475-18.57z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(8 6)">
          <mask id="zap-mask-2" fill="#fff">
            <use xlinkHref="#zap-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#zap-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-8 -6)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Zap.defaultProps = {
  width: 24,
  height: 24,
};

export default Zap;
