import React, { lazy, Suspense, useEffect } from 'react';

import { getUserInfos } from '@config/utils/functions';
import { isFeatureEnabled } from 'grow-shared-services';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { useStore } from 'effector-react';
import { getOrganizationByIdEffect } from './stores/getOrganizationById/OrganizationByIdEvents';
import { OrganizationByIdStore } from './stores/getOrganizationById/OrganizationByIdStore';
import LoadingView from './components/loadingView/LoadingView';
import { BASE_NAME } from './config/constants';
import {
  TLP_SPONSORED_MISSIONS_EDIT,
  TLP_CREATE_NEW_SPONSORED_MISSION,
  TLP_SERVICE_MISSION_ADMIN,
  TLP_SPONSORED_EDIT_CHILD_MISSION,
  GROW_LOCAL_MISSIONS,
  GROW_ADMIN_LOCAL_MISSIONS_DETAILS,
  GROW_ADMIN_SPONSORED_REDESIGN,
  GROW_ADMIN_LOCAL_MISSIONS_DUPLICATE,
  GROW_ORGANIZATION_ACTIVATION,
} from './config/featureToggles';
import { updateIntlProvider } from './i18n/formatters';
import ServicesMission from './pages/servicesMission/ServicesMission';
import useStyles from './Router.styles';

const MissionsManagementMainPage = lazy(
  () => import('./pages/management/MissionsManagementMainPage'),
);

/* istanbul ignore next */
const NewSponsoredMissionsPage = lazy(
  () => import('./pages/sponsoredMissions/newSponsoredMission/NewSponsoredMission'),
);

/* istanbul ignore next */
const SponsoredMissionsPage = lazy(() => import('./pages/sponsoredMissions/SponsoredMissions'));

/* istanbul ignore next */
const EditSponsoredMissionPage = lazy(
  () => import('./pages/sponsoredMissions/editSponsoredMissions/EditSponsoredMission'),
);

/* istanbul ignore next */
const DetailsLocalMissionPage = lazy(
  () => import('./pages/sponsoredMissions/detailsLocalMission/DetailsLocalMission'),
);

/* istanbul ignore next */
const DuplicateLocalMissionPage = lazy(
  () => import('./pages/sponsoredMissions/duplicateLocalMission/DuplicateLocalMission'),
);

const Router = (): JSX.Element => {
  const { content, containerBody, loadingView } = useStyles();
  const { preferredLanguage, orgId } = getUserInfos();

  React.useEffect(() => {
    updateIntlProvider(preferredLanguage);
  }, [preferredLanguage]);

  const isServiceMissionsEditEnabled = isFeatureEnabled(TLP_SERVICE_MISSION_ADMIN);

  const isSponsoredMissionsEditEnabled = isFeatureEnabled(TLP_SPONSORED_MISSIONS_EDIT);

  const isCreateNewSponsoredMissionEnabled = isFeatureEnabled(TLP_CREATE_NEW_SPONSORED_MISSION);

  const isEditSponsoredMissionEnabled = isFeatureEnabled(TLP_SPONSORED_EDIT_CHILD_MISSION);

  const isLocalMissionsEnabled = isFeatureEnabled(GROW_LOCAL_MISSIONS);

  const isLocalMissionDetailsEnabled = isFeatureEnabled(GROW_ADMIN_LOCAL_MISSIONS_DETAILS);

  const isLocalMissionDuplicateEnabled = isFeatureEnabled(GROW_ADMIN_LOCAL_MISSIONS_DUPLICATE);

  const isSponsoredRedesign = isFeatureEnabled(GROW_ADMIN_SPONSORED_REDESIGN);

  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const { doneOrganizationById } = useStore(OrganizationByIdStore);

  useEffect(() => {
    if (isOrganizationEnabled && !doneOrganizationById && orgId) {
      getOrganizationByIdEffect();
    }
  }, [isOrganizationEnabled, doneOrganizationById, orgId]);

  return (
    <BrowserRouter>
      <div className={containerBody}>
        <Suspense
          fallback={
            <div className={loadingView}>
              <LoadingView />
            </div>
          }
        >
          <div className={content}>
            <Switch>
              <Route
                exact
                path={`${BASE_NAME}/management`}
                component={MissionsManagementMainPage}
              />
              <Route path={`${BASE_NAME}/local-missions/edit-sponsored-mission`}>
                {isEditSponsoredMissionEnabled ? (
                  <EditSponsoredMissionPage data-testid="edit-sponsored-mission-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/management`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/local-missions/details`}>
                {isLocalMissionDetailsEnabled && isLocalMissionsEnabled && isSponsoredRedesign ? (
                  <DetailsLocalMissionPage data-testid="details-local-missions-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/local-missions`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/local-missions/new-sponsored-mission`}>
                {isCreateNewSponsoredMissionEnabled ? (
                  <NewSponsoredMissionsPage data-testid="new-sponsored-missions-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/management`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/local-missions/duplicate`}>
                {isLocalMissionDuplicateEnabled && isLocalMissionsEnabled ? (
                  <DuplicateLocalMissionPage data-testid="duplicate-local-missions-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/local-missions`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/local-missions/sponsored-missions`}>
                {isSponsoredMissionsEditEnabled ? (
                  <SponsoredMissionsPage data-testid="sponsored-missions-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/management`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/local-missions`}>
                {isLocalMissionsEnabled ? (
                  <SponsoredMissionsPage data-testid="local-missions-page" />
                ) : (
                  <Redirect to={`${BASE_NAME}/management`} />
                )}
              </Route>
              <Route path={`${BASE_NAME}/management/services-mission`}>
                {isServiceMissionsEditEnabled ? (
                  <ServicesMission />
                ) : (
                  <Redirect to={`${BASE_NAME}/management`} />
                )}
              </Route>
            </Switch>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
