import { updateServicesScript } from '../../../services/servicesMission/updateServicesScript/updateServicesScript';
import { servicesScriptError400 } from './ServicesScriptEvents';
import ServiceScriptResponse from './domains/ServiceScriptResponse';
import UpdateServicesScriptRequest from '../../../services/servicesMission/domains/UpdateServicesScriptRequest';

export function execute(body: UpdateServicesScriptRequest): Promise<ServiceScriptResponse> {
  return updateServicesScript(body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;

      if (code === 400) {
        servicesScriptError400();
      }

      throw error;
    });
}
