import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../../config/axios/axiosInstance';
import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '../../../../config/featureToggles';
import { getApiHost } from '../../../host/HostService';

const FILENAME = 'POCs';

export async function getPocReportService(
  missionId: string,
  vendorId?: string,
  vendorName?: string,
): Promise<ArrayBuffer> {
  const log = useLogService();

  const isGetProductsAndCombosV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const baseUrl = `${getApiHost()}/api/v1/tlp-account-service/tlp-account`;
  let uri = '';

  if (isGetProductsAndCombosV2Enabled) {
    uri = `/v2/accounts-custom-mission/download-report?csvname=${FILENAME}&vendorId=${
      vendorId ?? ''
    }&missionId=${missionId}`;
  } else {
    uri = `/accounts-custom-mission/download-report?missionId=${missionId}`;
  }

  const headers =
    !isGetProductsAndCombosV2Enabled && vendorName && vendorId ? { vendorId, vendorName } : {};

  return axios
    .get(`${baseUrl}${uri}`, {
      headers,
      responseType: 'blob',
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
