import { Switch as SwitchComponent, SwitchProps } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

export interface ClassesType extends SwitchProps {
  classes: {
    focusVisible: string;
    root: string;
    switchBase: string;
    thumb: string;
    track: string;
    checked: string;
  };
}

const Switch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 22,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  switchBase: {
    paddingTop: 1,
    padding: 1.5,
    paddingLeft: 0.5,
    '&$checked': {
      transform: 'translateX(14px)',
      '& + $track': {
        backgroundColor: '#1F8E4A',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#1F8E4A',
      marginRight: '6px',
    },
    '&.Mui-disabled': {
      '&$checked': {
        transform: 'translateX(14px)',
        '& + $track': {
          backgroundColor: '#E6F2EB',
          opacity: 1,
          border: 'none',
        },
      },
      '& + $track': {
        backgroundColor: 'rgba(0,0,0,0.15)',
        opacity: 1,
      },
    },
  },
  thumb: {
    color: theme.palette.common.white,
    borderRadius: 22 / 2,
    marginTop: 1,
    marginLeft: 2,
    width: 18,
    height: 18,
  },
  track: {
    color: theme.palette.common.white,
    borderRadius: 22 / 2,
    backgroundColor: 'rgba(0,0,0,0.55)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: ClassesType): JSX.Element => {
  return (
    <SwitchComponent
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default Switch;
