export const TLP_TOPLINEGAP_SECONDARY_MISSION = 'tlp-toplinegap-secondary-mission';
export const TLP_TOPLINEGAP_SECONDARY_MISSION_ADMIN = 'tlp-toplinegap-secondary-mission-admin';

export const TLP_SECONDARY_MISSIONS_CONFIRMATION_MODAL =
  'tlp-secondary-missions-confirmation-modal';
export const TLP_SECONDARY_MISSIONS_CONFIRMATION_MODAL_ADMIN =
  'tlp-secondary-missions-confirmation-modal-admin';

export const TLP_REWARDS_CHALLENGES_MISSION = 'tlp-rewards-challenges-mission';
export const TLP_REWARDS_CHALLENGES_MISSION_ADMIN = 'tlp-rewards-challenges-mission-admin';

export const TLP_SPONSORED_MISSION_ADMIN = 'tlp-sponsored-mission-admin';

export const TLP_SPONSORED_MISSIONS_EDIT = 'tlp-sponsored-missions-edit';

export const TLP_CREATE_NEW_SPONSORED_MISSION = 'tlp-create-new-sponsored-mission';

export const TLP_SERVICE_MISSION_ADMIN = 'tlp-service-mission-admin';

export const TLP_SERVICE_MISSION_BLOCKED_ADMIN = 'tlp-disable-service-mission-admin';

export const TLP_SERVICE_MISSIONS_EDIT = 'tlp-service-missions-edit';

export const TLP_SERVICE_MISSIONS_CATALOG = 'tlp-service-missions-catalog';
export const TLP_SERVICE_MISSIONS_PRICING_METHOD = 'tlp-service-missions-pricing-method';
export const TLP_SERVICE_MISSIONS_EDIT_MODAL = 'tlp-service-missions-edit-modal';
export const TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED = 'tlp-check-feature-toggles-are-loaded';
export const TLP_SERVICE_MISSIONS_DRAGGABLE = 'tlp-service-missions-draggable';

export const TLP_SERVICE_FORM_EXISTING_TITLE_CHECK = 'tlp-service-form-existing-title-check';

export const TLP_SPONSORED_DIGITALIZATION_PERCENTAGE = 'tlp-sponsored-digitalization-percentage';

export const TLP_CUSTOM_MISSIONS_ENDPOINT_V2 = 'tlp-custom-missions-endpoint-v2';

export const TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE =
  'tlp-multicontract-grow-admin-config-service';

export const TLP_SPONSORED_EDIT_CHILD_MISSION = 'tlp-sponsored-edit-child-mission';

export const TLP_SPONSORED_SCRIPT_CHARS_INCREASE =
  'grow-admin-sponsored-script-chars-increase-BEESGR-8290';

export const SPONSORED_AUDIENCE_CSV_INCREASE_BEESGR_8290 =
  'grow-admin-sponsored-audience-csv-increase-BEESGR-8290';

export const TLP_MISSIONS_MANAGEMENT_V2 = 'grow-admin-missions-management-v2-BEESGR-5955';

export const GROW_LOCAL_MISSIONS = 'grow-local-missions-BEESGR-8796';

export const GROW_ADMIN_SPONSORED_REDESIGN = 'grow-admin-sponsored-redesign-BEESGR-8290';

export const GROW_ADMIN_SPONSORED_PRIORITIZATION =
  'grow-admin-sponsored-prioritization-BEESGR-8290';

export const GROW_ADMIN_SPONSORED_RICHTEXT_SCRIPT =
  'grow-admin-sponsored-richtext-script-BEESGR-8290';

export const GROW_ADMIN_LOCAL_MISSIONS_STEP2 = 'grow-admin-local-missions-step2-BEESGR-8796';

export const GROW_ADMIN_LOCAL_MISSIONS_STEP3 = 'grow-admin-local-missions-step3-BEESGR-5955';

export const GROW_ADMIN_LOCAL_MISSIONS_BLOCK_LIST_FILTER =
  'grow-admin-local-missions-block-list-filter-BEESGR-5955';

export const GROW_ADMIN_LOCAL_MISSIONS_STOP_CALLING_WHEN_SELECT =
  'grow-admin-local-missions-step3-stopcallingwhen-BEESGR-5955';

export const GROW_ADMIN_LOCAL_MISSIONS_BLOCK_STEP2_SPECIFIED_QUANTITY =
  'grow-admin-local-missions-step2-specified-quantity-BEESGR-8796';

export const GROW_ADMIN_LOCAL_MISSIONS_STEP2_AT_LEAST =
  'grow-admin-local-missions-step2-at-least-BEESGR-8796';

export const GROW_ADMIN_LOCAL_MISSIONS_DETAILS = 'grow-admin-local-mission-details-BEESGR-7963';

export const GROW_ADMIN_LOCAL_MISSIONS_SEARCH = 'grow-admin-local-mission-search-BEEGR-8529';

export const GROW_ADMIN_LOCAL_MISSIONS_DUPLICATE = 'grow-admin-local-missions-dup-beesgr-7960';

export const GROW_ADMIN_FIRST_MISSION_LIMIT = 'grow-admin-first-mission-limit-BEESGR-5955';

export const GROW_ADMIN_LOCAL_MISSIONS_DETAILS_REPORT =
  'grow-admin-local-mission-details-report-BEESGR-7963';

export const GROW_ADMIN_LOCAL_MISSIONS_DELETE_BUTTON =
  'grow-admin-local-missions-delete-button-BEESGR-7964';

export const GROW_ADMIN_LOCAL_MISSIONS_FILTER = 'grow-admin-local-mission-filters-BEEGR-8530';

export const GROW_ADMIN_MISSIONS_SPONSOR_FIELD = 'grow-admin-missions-sponsor-field-beesgr-11182';

export const GROW_ADMIN_MISSIONS_SPONSOR_FIELD_DROPDOWN =
  'grow-admin-local-mission-sponsor-dropdown-beesgr-11154';

export const GROW_ORGANIZATION_ACTIVATION = 'grow-organization-activation';

export const GROW_ADMIN_TECH_3PD_LOCAL_MISSION_PERSONALIZATION =
  'grow-admin-tech-3PD-local-mission-personalization-BEESGR-19027';
