import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme, { error?: boolean }>(() =>
  createStyles({
    text: {
      color: ({ error }) => (error ? 'rgb(201,32,29)' : 'rgba(0,0,0,0.9)'),
      fontFamily: 'Work Sans',
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
    },
    subLabel: {
      paddingBottom: '4px',
      color: 'rgba(0,0,0,0.55)',
      fontFamily: 'Work Sans',
      fontSize: '12px',
      letterSpacing: '0.03px',
      lineHeight: '18px',
    },
  }),
);
