/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  );
  console.warn(msg);
};
let onViolation = defaultValidationErrorHandler;
let analytics = () => {
  return window.analytics;
};
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}
/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message, schema) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));
  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'javascript',
        version: '7.4.1',
      },
    },
  };
}
/**
 * @typedef AgentSegmentationAssigned
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 */
/**
 * @typedef BlockListDeleted
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListDownloaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListUploaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef CampaignCreated
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {any[] | null} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem
 * @property {string} [sku] - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignDeleted
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem[]} products - the array of all product objects in the list
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ProductsItem1
 * @property {string} [sku] - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignEdited
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem1[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem2
 * @property {string} sku - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignRegistrationFailed
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem2[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 */
/**
 * @typedef CampaignStatusViewed
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef ProductsItem3
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef DuplicateLocalMissionSaved
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem3[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef ProductsItem4
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef DuplicateLocalMissionStarted
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem4[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef MissionsItem
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem5
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef EditLocalMissionStarted
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem[]} missions - The array of missions objects
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem5[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef ProductsItem6
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef EditSponsoredMissionStarted
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem6[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} sponsored_segmentation - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ErrorMessageViewed
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 */
/**
 * @typedef LanguageChanged
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 */
/**
 * @typedef LocalMissionAudienceCompleted
 * @property {string} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef LocalMissionContentCompleted
 * @property {string} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef MissionsItem1
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem7
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalMissionCreated
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem1[]} missions - The array of missions objects
 * @property {ProductsItem7[]} products - the array of all product objects in the list
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionDetailsPageViewed
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionMainCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef LocalMissionReportExported
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef MissionsItem2
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem8
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalMissionsChangesSaved
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem2[]} missions - The array of missions objects
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem8[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef PeriodItem
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef LocalMissionsFilterClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {PeriodItem[] | null} period - Period
 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} type - all types selected (Coverage, Simple)
 */
/**
 * @typedef LocalMissionsNoResultsFound
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsPrioritySaved
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionsSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsStatusChanged
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsTabsInteraction
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalProductsAdded
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 */
/**
 * @typedef ProductsItem9
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalProductsLoaded
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {ProductsItem9[]} products - the array of all product objects in the list
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 */
/**
 * @typedef LocalProductsQuantityEdited
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 */
/**
 * @typedef MissionsItem3
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef MissionsSaved
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {MissionsItem3[]} missions - The array of missions objects
 */
/**
 * @typedef MissionsTabInteraction
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef MissionsItem4
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem1
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem10
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef MissionsUxButtonClicked
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem4[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem1[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem10[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef NewLocalMissionStarted
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 */
/**
 * @typedef PageViewed
 * @property {Record<string, any>} [] -
 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef RetryButtonClicked
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef ServiceAdded
 * @property {Record<string, any>} [service_name] - title of the service (Bill payment)
 */
/**
 * @typedef ServiceSaved
 * @property {string | null} country - country being configured
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} service_name - title of the service (Bill payment)
 */
/**
 * @typedef ServiceStatusChanged
 * @property {Record<string, any>} [is_active] - if the service is active or not (T)
 * @property {Record<string, any>} [service_name] - title of the service (Bill payment)
 */
/**
 * @typedef ServicesChangesSaved
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 */
/**
 * @typedef ServicesItem1
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 */
/**
 * @typedef ServicesMissionSaved
 * @property {string | null} country - country being configured
 * @property {ServicesItem1[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 */
/**
 * @typedef ServicesUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef SidebarInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 */
/**
 * @typedef SponsoredAgentSetupCompleted
 * @property {Record<string, any>} [mission_type] - Type of the sponsored mission. Sample: Thrive
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef SponsoredContentCompleted
 * @property {Record<string, any>} [mission_type] - Type of the sponsored mission. Sample: Thrive
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef MissionsItem5
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem2
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem11
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem1
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem2
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem1[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef SponsoredMainDetailsCompleted
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem5[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem2[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem11[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem2[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef ProductsItem12
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef SponsoredMissionChangesSaved
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem12[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} sponsored_segmentation - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ProductsItem13
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef SponsoredMissionCreated
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {Record<string, any>} [mission_type] - Type of the sponsored mission. Sample: Thrive
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem13[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} sponsored_segmentation - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef SponsoredMissionStatusChanged
 * @property {Record<string, any>} [is_active] - if the service is active or not (T)
 * @property {Record<string, any>} [mission_type] - Type of the sponsored mission. Sample: Thrive
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef SponsoredSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 */
/**
 * @typedef SponsoredUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef MissionsItem6
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem3
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem14
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem2
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem3
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem2[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef TeamStructureUxButtonClicked
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} consider_block_list - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem6[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem3[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem14[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem3[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * Fires a '' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emptyIdentifier(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: '',
    type: 'object',
  };
  const message = {
    event: '',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
 *
 * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function agentSegmentationAssigned(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "confirm" in the agent setup modal and assigns a segmentation with success',
    properties: {
      context: {},
      properties: {
        properties: {
          agent_id: {
            description: 'Unique user identification (email)',
            id: '/properties/properties/properties/agent_id',
            type: ['string', 'null'],
          },
          agent_segmentation: {
            description: 'Segmentation of the agent. Sample: C2B',
            type: ['array', 'null'],
          },
          mission_priority_1: {
            description: 'The first mission priority of the agent. Sample: GAP',
            id: '/properties/properties/properties/mission_priority_1',
            type: ['string', 'null'],
          },
          mission_priority_2: {
            description: 'The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION',
            id: '/properties/properties/properties/mission_priority_2',
            type: ['string', 'null'],
          },
          mission_priority_3: {
            description: 'The third mission priority of the agent. Sample: REWARDS_CHALLENGES',
            id: '/properties/properties/properties/mission_priority_3',
            type: ['string', 'null'],
          },
          mission_priority_4: {
            description: 'The fourth mission priority of the agent. Sample: SPONSORED',
            id: '/properties/properties/properties/mission_priority_4',
            type: ['string', 'null'],
          },
          mission_priority_5: {
            description: 'The fifth mission priority of the agent. Sample: SERVICES',
            id: '/properties/properties/properties/mission_priority_5',
            type: ['string', 'null'],
          },
        },
        required: [
          'mission_priority_1',
          'mission_priority_3',
          'mission_priority_4',
          'mission_priority_5',
          'mission_priority_2',
          'agent_segmentation',
          'agent_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Agent Segmentation Assigned',
    type: 'object',
  };
  const message = {
    event: 'Agent Segmentation Assigned',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Agent Segmentation Assigned', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on confirm button inside block list deletion modal and the block list is deleted
 *
 * @param {BlockListDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDeleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on confirm button inside block list deletion modal and the block list is deleted',
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            type: ['string', 'null'],
          },
        },
        required: ['block_list'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Block List Deleted',
    type: 'object',
  };
  const message = {
    event: 'Block List Deleted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Block List Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the block list download button and the list is downloaded
 *
 * @param {BlockListDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDownloaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on the block list download button and the list is downloaded',
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            type: ['string', 'null'],
          },
        },
        required: ['block_list'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Block List Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Block List Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Block List Downloaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on confirm button inside confirmation modal and a new block list is submitted
 *
 * @param {BlockListUploaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListUploaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on confirm button inside confirmation modal and a new block list is submitted',
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            type: ['string', 'null'],
          },
        },
        required: ['block_list'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Block List Uploaded',
    type: 'object',
  };
  const message = {
    event: 'Block List Uploaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Block List Uploaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the "create" button in the creation modal and the campaign is created with success
 *
 * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreated(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the "create" button in the creation modal and the campaign is created with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'direct distribution center. Sample: 0575',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            type: ['string', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          campaign_priority: {
            description: 'The prioritization rank of the campaign. 1 if primary and 2 if secondary',
            type: ['integer', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            type: ['array', 'null'],
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            type: ['string', 'null'],
          },
        },
        required: [
          'segment',
          'campaign_priority',
          'campaign_segmentation',
          'campaign_name',
          'end_date',
          'DDC',
          'campaign_type',
          'products',
          'start_date',
          'campaign_description',
          'tag_name',
          'campaign_script',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Campaign Created',
    type: 'object',
  };
  const message = {
    event: 'Campaign Created',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
 *
 * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDeleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            items: {
              description: 'an individual product in the list',
              properties: {
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  type: 'string',
                },
              },
              required: [],
              type: 'object',
            },
            type: 'array',
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
        },
        required: ['start_date', 'end_date', 'campaign_name', 'products'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Campaign Deleted',
    type: 'object',
  };
  const message = {
    event: 'Campaign Deleted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Deleted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
 *
 * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the "confirm" button in the edit modal and the campaign is edited with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'direct distribution center. Sample: 0575',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            type: ['string', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            items: {
              description: 'an individual product in the list',
              properties: {
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  type: 'string',
                },
              },
              required: [],
              type: 'object',
            },
            type: 'array',
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            type: ['string', 'null'],
          },
        },
        required: [
          'campaign_script',
          'products',
          'campaign_name',
          'tag_name',
          'segment',
          'DDC',
          'campaign_type',
          'end_date',
          'start_date',
          'campaign_segmentation',
          'campaign_description',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Campaign Edited',
    type: 'object',
  };
  const message = {
    event: 'Campaign Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Edited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User tries to create/edit a campaign, but the action returns one or more errors
 *
 * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignRegistrationFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User tries to create/edit a campaign, but the action returns one or more errors',
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'direct distribution center. Sample: 0575',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            type: ['string', 'null'],
          },
          campaign_error_type: {
            description:
              'The error type(s) that occured in the campaign registration. Sample: invalid CSV format',
            type: ['array', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          campaign_priority: {
            description: 'The prioritization rank of the campaign. 1 if primary and 2 if secondary',
            type: ['integer', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            items: {
              description: 'an individual product in the list',
              properties: {
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  type: 'string',
                },
              },
              required: ['sku'],
              type: 'object',
            },
            type: 'array',
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            type: ['string', 'null'],
          },
        },
        required: [
          'campaign_script',
          'segment',
          'start_date',
          'DDC',
          'tag_name',
          'campaign_segmentation',
          'campaign_error_type',
          'campaign_description',
          'end_date',
          'campaign_type',
          'campaign_name',
          'products',
          'campaign_priority',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Campaign Registration Failed',
    type: 'object',
  };
  const message = {
    event: 'Campaign Registration Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Registration Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the search field/button in the Focus SKU page
 *
 * @param {CampaignSearchInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the Focus SKU page',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'search_query'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Campaign Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Campaign Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Search Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
 *
 * @param {CampaignStatusViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignStatusViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            type: ['string', 'null'],
          },
        },
        required: ['campaign_name', 'tag_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Campaign Status Viewed',
    type: 'object',
  };
  const message = {
    event: 'Campaign Status Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Status Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * > User clicks on ""Create new"" to create a new campaign |
> User clicks on ""expand"" or ""collapse"" campaign details
 *
 * @param {CampaignUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '> User clicks on ""Create new"" to create a new campaign |\n> User clicks on ""expand"" or ""collapse"" campaign details',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            type: ['string', 'null'],
          },
          filter_option: {
            description:
              'Filter selection. Samples: gap only, premium, core. Null if not applicable',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'filter_option', 'screen_name', 'screen_section', 'button_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Campaign UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Campaign UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the Save button.
 *
 * @param {DuplicateLocalMissionSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function duplicateLocalMissionSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on the Save button.',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'mission',
          'end_date',
          'consider_block_list',
          'tab_label',
          'products',
          'digitalization_level',
          'screen_name',
          'local_mission_id',
          'mission_type',
          'local_segmentation',
          'start_date',
          'partner',
          'consider_delivery_window',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Duplicate Local Mission Saved',
    type: 'object',
  };
  const message = {
    event: 'Duplicate Local Mission Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Duplicate Local Mission Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the Duplicate button.
 *
 * @param {DuplicateLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function duplicateLocalMissionStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on the Duplicate button.',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'start_date',
          'consider_delivery_window',
          'mission',
          'products',
          'mission_type',
          'screen_name',
          'local_segmentation',
          'tab_label',
          'local_mission_id',
          'end_date',
          'partner',
          'consider_block_list',
          'digitalization_level',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Duplicate Local Mission Started',
    type: 'object',
  };
  const message = {
    event: 'Duplicate Local Mission Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Duplicate Local Mission Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User click on th 'edit' button local of Mission Started
 *
 * @param {EditLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editLocalMissionStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "User click on th 'edit' button local of Mission Started",
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'products',
          'mission_type',
          'consider_block_list',
          'consider_delivery_window',
          'local_segmentation',
          'local_mission_id',
          'missions',
          'tab_label',
          'partner',
          'digitalization_level',
          'start_date',
          'end_date',
          'screen_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Edit Local Mission Started',
    type: 'object',
  };
  const message = {
    event: 'Edit Local Mission Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Edit Local Mission Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the "Edit" button of a Sponsored Mission and goes to the edition page
 *
 * @param {EditSponsoredMissionStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editSponsoredMissionStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the "Edit" button of a Sponsored Mission and goes to the edition page',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: 'string',
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'start_date',
          'end_date',
          'partner',
          'products',
          'sponsored_segmentation',
          'sponsored_mission',
          'consider_block_list',
          'consider_delivery_window',
          'digitalization_level',
          'mission_type',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Edit Sponsored Mission Started',
    type: 'object',
  };
  const message = {
    event: 'Edit Sponsored Mission Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Edit Sponsored Mission Started',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * An error is displayed to the user or an error screen is loaded
 *
 * @param {ErrorMessageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorMessageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'An error is displayed to the user or an error screen is loaded',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_message: {
            description:
              'Text displayed in the UI that describes the error. Sample: Field required',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Error that occured. Sample: no_connection\n',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occured (drill down to hour only). Samples: 15, 09',
            type: ['integer', 'null'],
          },
        },
        required: ['error_type', 'error_message', 'screen_name', 'screen_section', 'time_of_day'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Error Message Viewed',
    type: 'object',
  };
  const message = {
    event: 'Error Message Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Error Message Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User selects a language through header dropdown menu
 *
 * @param {LanguageChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function languageChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User selects a language through header dropdown menu',
    properties: {
      context: {},
      properties: {
        properties: {
          language_selected: {
            description: 'UI language selected. Samples: EN, ES',
            type: ['string', 'null'],
          },
        },
        required: ['language_selected'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Language Changed',
    type: 'object',
  };
  const message = {
    event: 'Language Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Language Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "next" after setting the step 3 of the Local Missions and advances to step 4
 *
 * @param {LocalMissionAudienceCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionAudienceCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "next" after setting the step 3 of the Local Missions and advances to step 4',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: 'string',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['mission_type', 'sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Audience Completed',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Audience Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Mission Audience Completed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on "next" after setting the step 2 of the Local Missions and advances to step 3
 *
 * @param {LocalMissionContentCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionContentCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "next" after setting the step 2 of the Local Missions and advances to step 3',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: 'string',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['sponsored_mission', 'mission_type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Content Completed',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Content Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Mission Content Completed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks in the “finish” button in the step 4 of the creation process, indicating that a new mission is created and saved.
 *
 * @param {LocalMissionCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionCreated(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks in the “finish” button in the step 4 of the creation process, indicating that a new mission is created and saved.',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission',
            type: ['string', 'null'],
          },
          mission_category: {
            description: 'category of the local mission (digital education)',
            id: '/properties/properties/properties/mission_category',
            type: ['string', 'null'],
          },
          mission_tags: {
            description: 'The list of included tags',
            id: '/properties/properties/properties/mission_tags',
            type: ['array', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          sponsor: {
            description: 'sponsor of the mission (Diageo)',
            id: '/properties/properties/properties/sponsor',
            type: ['boolean', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'sponsor',
          'consider_block_list',
          'effectiveness_criteria',
          'local_mission',
          'mission_type',
          'missions',
          'mission_tags',
          'start_date',
          'mission_category',
          'end_date',
          'digitalization_level',
          'products',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Created',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Created',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Mission Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks in the local mission title/arrow to access the Details page.
 *
 * @param {LocalMissionDetailsPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionDetailsPageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks in the local mission title/arrow to access the Details page.',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'end_date',
          'partner',
          'consider_block_list',
          'digitalization_level',
          'screen_name',
          'mission',
          'mission_type',
          'start_date',
          'local_mission_id',
          'local_segmentation',
          'consider_delivery_window',
          'tab_label',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Details Page Viewed',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Details Page Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Mission Details Page Viewed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on "next" after setting the step 1 of the Local Missions and advances to step 2
 *
 * @param {LocalMissionMainCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionMainCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "next" after setting the step 1 of the Local Missions and advances to step 2',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['sponsored_mission', 'mission_type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Main Completed',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Main Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Mission Main Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks to export the report in the Local mission Details Page
 *
 * @param {LocalMissionReportExported} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionReportExported(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks to export the report in the Local mission Details Page ',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'start_date',
          'end_date',
          'consider_block_list',
          'mission',
          'local_segmentation',
          'consider_delivery_window',
          'digitalization_level',
          'screen_name',
          'local_mission_id',
          'mission_type',
          'partner',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission Report Exported',
    type: 'object',
  };
  const message = {
    event: 'Local Mission Report Exported',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Mission Report Exported', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user performs an UX action in the local missions creation, edit, duplicate and details pages
 *
 * @param {LocalMissionUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user performs an UX action in the local missions creation, edit, duplicate and details pages',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'screen_section', 'button_name', 'button_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Mission UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Local Mission UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Mission UX Button Clicked',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on 'save' after change mission.
 *
 * @param {LocalMissionsChangesSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsChangesSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "User clicks on 'save' after change mission.",
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'products',
          'missions',
          'local_segmentation',
          'end_date',
          'partner',
          'digitalization_level',
          'mission_type',
          'screen_name',
          'local_mission_id',
          'tab_label',
          'start_date',
          'consider_block_list',
          'consider_delivery_window',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions Changes Saved',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Changes Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Missions Changes Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the Local mission’s filter component
 *
 * @param {LocalMissionsFilterClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsFilterClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the Local mission’s filter component ',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          category: {
            description: 'All categories selected (Digital education, Value Creation)',
            enum: ['Digital education', 'Value Creation', null],
            id: '/properties/properties/properties/category',
            type: ['string', 'null'],
          },
          period: {
            description: 'Period',
            id: '/properties/properties/properties/period',
            items: {
              description: '',
              id: '/properties/properties/properties/period/items',
              properties: {
                End: {
                  description: 'end date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/End',
                  type: ['string', 'null'],
                },
                Start: {
                  description: 'start date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/Start',
                  type: ['string', 'null'],
                },
              },
              required: ['Start', 'End'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          sponsored_only: {
            description: 'If the filter sponsored only was selected: (true, false)',
            id: '/properties/properties/properties/sponsored_only',
            type: ['boolean', 'null'],
          },
          status: {
            description: 'all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)',
            enum: ['Concluded', 'Expired', 'Ongoing', 'Paused', 'Scheduled', null],
            id: '/properties/properties/properties/status',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
          type: {
            description: 'all types selected (Coverage, Simple)',
            enum: ['Coverage', 'Simple', null],
            id: '/properties/properties/properties/type',
            type: ['string', 'null'],
          },
        },
        required: [
          'button_label',
          'tab_label',
          'period',
          'status',
          'type',
          'category',
          'sponsored_only',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions Filter Clicked',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Filter Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Missions Filter Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Search and/or Filters used showed “No results” in the Local missions page
 *
 * @param {LocalMissionsNoResultsFound} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsNoResultsFound(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Search and/or Filters used showed “No results” in the Local missions page',
    properties: {
      context: {},
      properties: {
        properties: {
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: ['tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions No Results Found',
    type: 'object',
  };
  const message = {
    event: 'Local Missions No Results Found',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Missions No Results Found',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User changes the priority of the sponsored/local missions through the drag and drop or position number edition, inside “Active” sub-page of the Sponsored missions/Local missions page
 *
 * @param {LocalMissionsPrioritySaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsPrioritySaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User changes the priority of the sponsored/local missions through the drag and drop or position number edition, inside “Active” sub-page of the Sponsored missions/Local missions page ',
    properties: {
      context: {},
      properties: {
        properties: {
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: 'string',
          },
          new_mission_position: {
            description: 'new order of the mission moved',
            id: '/properties/properties/properties/new_mission_position',
            type: ['integer', 'null'],
          },
          previous_mission_position: {
            description: 'previous order of the mission moved',
            id: '/properties/properties/properties/previous_mission_position',
            type: ['integer', 'null'],
          },
          priority_change_method: {
            description: 'method the priority change was saved (drag and drop; position edition)',
            id: '/properties/properties/properties/priority_change_method',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'new_mission_position',
          'mission_type',
          'mission',
          'start_date',
          'end_date',
          'local_mission_id',
          'priority_change_method',
          'previous_mission_position',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Local Missions Priority Saved',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Priority Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Missions Priority Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User search a value in the search bar of local missions page
 *
 * @param {LocalMissionsSearchInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User search a value in the search bar of local missions page',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            id: '/properties/properties/properties/search_query',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'search_query', 'tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Missions Search Interaction',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the toggle inside the cards and the mission is activated/deactivated, either in the “Active” or “All missions” sub-page.
 *
 * @param {LocalMissionsStatusChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsStatusChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the toggle inside the cards and the mission is activated/deactivated, either in the “Active” or “All missions” sub-page.',
    properties: {
      context: {},
      properties: {
        properties: {
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: ['boolean', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: [
          'mission_type',
          'local_mission_id',
          'screen_name',
          'tab_label',
          'is_active',
          'mission',
          'start_date',
          'end_date',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions Status Changed',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Status Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Missions Status Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between Active and All missions inside Sponsored missions/Local missions page.
 *
 * @param {LocalMissionsTabsInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsTabsInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User toggles between Active and All missions inside Sponsored missions/Local missions page.',
    properties: {
      context: {},
      properties: {
        properties: {
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: ['tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Missions Tabs Interaction',
    type: 'object',
  };
  const message = {
    event: 'Local Missions Tabs Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Missions Tabs Interaction',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart.
 *
 * @param {LocalProductsAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Add" button in either promo and combo sections and the item is added to the order cart.',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'combo ID (DT01290007980)',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: 'if the product is out of stock (T)',
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: 'if the product is a promotion/deal (T)',
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          minimum_quantity: {
            description:
              'number of the minimum quantity suggested for that product/combo or mission.',
            id: '/properties/properties/properties/minimum_quantity',
            type: ['string', 'null'],
          },
          name: {
            description: 'name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Quantity of invalid POCs listed in a single campaign',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'product SKU (ABIC001431)',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
        },
        required: [
          'sku',
          'is_out_of_stock',
          'minimum_quantity',
          'local_mission_title',
          'sponsor_partner',
          'effectiveness_criteria',
          'name',
          'combo_id',
          'is_promotion',
          'local_mission_id',
          'call_id',
          'poc_id',
          'quantity',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Products Added',
    type: 'object',
  };
  const message = {
    event: 'Local Products Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Products Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default
 *
 * @param {LocalProductsLoaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
          sponsored_tag: {
            description: 'If this local mission is a sponsored (T)',
            id: '/properties/properties/properties/sponsored_tag',
            type: ['boolean', 'null'],
          },
        },
        required: [
          'local_mission_id',
          'sponsor_partner',
          'effectiveness_criteria',
          'call_id',
          'poc_id',
          'sponsored_tag',
          'products',
          'local_mission_title',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Products Loaded',
    type: 'object',
  };
  const message = {
    event: 'Local Products Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Products Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters the quantity of a product in the Local mission tab by using the arrows or typing the quantity.
 *
 * @param {LocalProductsQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user alters the quantity of a product in the Local mission tab by using the arrows or typing the quantity.',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
        },
        required: [
          'local_mission_title',
          'local_mission_id',
          'sponsor_partner',
          'effectiveness_criteria',
          'call_id',
          'poc_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Products Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Local Products Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Products Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on "apply changes" inside the missions management page and the changes are saved
 *
 * @param {MissionsSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "apply changes" inside the missions management page and the changes are saved',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_priority: {
            description: 'Prioritization of the mission. Samples: Primary, Secondary',
            id: '/properties/properties/properties/mission_priority',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
        },
        required: ['mission_priority', 'missions'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Missions Saved',
    type: 'object',
  };
  const message = {
    event: 'Missions Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Missions Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between Primary Missions and Secondary Missions inside missions page
 *
 * @param {MissionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User toggles between Primary Missions and Secondary Missions inside missions page',
    properties: {
      context: {},
      properties: {
        properties: {
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
        },
        required: ['tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Missions Tab Interaction',
    type: 'object',
  };
  const message = {
    event: 'Missions Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Missions Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "edit" inside any mission card in Missions Management
 *
 * @param {MissionsUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "edit" inside any mission card in Missions Management',
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'Direct distribution center. Sample: 0575',
            id: '/properties/properties/properties/DDC',
            type: ['string', 'null'],
          },
          agent_id: {
            description: 'Unique user identification (email)',
            id: '/properties/properties/properties/agent_id',
            type: ['string', 'null'],
          },
          agent_segmentation: {
            description: 'Segmentation of the agent. Sample: C2B',
            id: '/properties/properties/properties/agent_segmentation',
            type: ['array', 'null'],
          },
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            id: '/properties/properties/properties/block_list',
            type: ['string', 'null'],
          },
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: English, spanish',
            id: '/properties/properties/properties/button_selection',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            id: '/properties/properties/properties/campaign_description',
            type: ['string', 'null'],
          },
          campaign_error_type: {
            description:
              'The error type(s) that occured in the campaign registration. Sample: invalid CSV format',
            id: '/properties/properties/properties/campaign_error_type',
            type: ['array', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            id: '/properties/properties/properties/campaign_name',
            type: ['string', 'null'],
          },
          campaign_priority: {
            description: 'The prioritization rank of the campaign. 1 if primary and 2 if secondary',
            id: '/properties/properties/properties/campaign_priority',
            type: ['integer', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            id: '/properties/properties/properties/campaign_script',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            id: '/properties/properties/properties/campaign_segmentation',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            id: '/properties/properties/properties/campaign_type',
            type: ['string', 'null'],
          },
          category: {
            description: 'All categories selected (Digital education, Value Creation)',
            enum: ['Digital education', 'Value Creation', null],
            id: '/properties/properties/properties/category',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'combo ID (DT01290007980)',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          country: {
            description: 'country being configured',
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          description: {
            description:
              'description of the item. Sample: Will enable paying bills with rewards points.',
            id: '/properties/properties/properties/description',
            type: ['string', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed in the UI that describes the error. Sample: Field required',
            id: '/properties/properties/properties/error_message',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Error that occured. Sample: no_connection\n',
            id: '/properties/properties/properties/error_type',
            type: ['string', 'null'],
          },
          filter_option: {
            description:
              'Filter selection. Samples: gap only, premium, core. Null if not applicable',
            id: '/properties/properties/properties/filter_option',
            type: ['string', 'null'],
          },
          hierarchy: {
            description: 'Hierarchy level. Samples: Supervisor, Agent',
            id: '/properties/properties/properties/hierarchy',
            type: ['string', 'null'],
          },
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: 'if the product is out of stock (T)',
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: 'if the product is a promotion/deal (T)',
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          language_selected: {
            description: 'UI language selected. Samples: EN, ES',
            id: '/properties/properties/properties/language_selected',
            type: ['string', 'null'],
          },
          local_mission: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          minimum_quantity: {
            description:
              'number of the minimum quantity suggested for that product/combo or mission.',
            id: '/properties/properties/properties/minimum_quantity',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_category: {
            description: 'category of the local mission (digital education)',
            id: '/properties/properties/properties/mission_category',
            type: ['string', 'null'],
          },
          mission_priority: {
            description: 'Prioritization of the mission. Samples: Primary, Secondary',
            id: '/properties/properties/properties/mission_priority',
            type: ['string', 'null'],
          },
          mission_priority_1: {
            description: 'The first mission priority of the agent. Sample: GAP',
            id: '/properties/properties/properties/mission_priority_1',
            type: ['string', 'null'],
          },
          mission_priority_2: {
            description: 'The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION',
            id: '/properties/properties/properties/mission_priority_2',
            type: ['string', 'null'],
          },
          mission_priority_3: {
            description: 'The third mission priority of the agent. Sample: REWARDS_CHALLENGES',
            id: '/properties/properties/properties/mission_priority_3',
            type: ['string', 'null'],
          },
          mission_priority_4: {
            description: 'The fourth mission priority of the agent. Sample: SPONSORED',
            id: '/properties/properties/properties/mission_priority_4',
            type: ['string', 'null'],
          },
          mission_priority_5: {
            description: 'The fifth mission priority of the agent. Sample: SERVICES',
            id: '/properties/properties/properties/mission_priority_5',
            type: ['string', 'null'],
          },
          mission_tags: {
            description: 'The list of included tags',
            id: '/properties/properties/properties/mission_tags',
            type: ['array', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          name: {
            description: 'name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          new_mission_position: {
            description: 'new order of the mission moved',
            id: '/properties/properties/properties/new_mission_position',
            type: ['integer', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          path: {
            description: 'Path of the screen. Samples: /call',
            id: '/properties/properties/properties/path',
            type: 'object',
          },
          period: {
            description: 'Period',
            id: '/properties/properties/properties/period',
            items: {
              description: '',
              id: '/properties/properties/properties/period/items',
              properties: {
                End: {
                  description: 'end date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/End',
                  type: ['string', 'null'],
                },
                Start: {
                  description: 'start date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/Start',
                  type: ['string', 'null'],
                },
              },
              required: ['Start', 'End'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          preset_value: {
            description: 'value in rewards points when pricing method is preset. Sample: 800',
            id: '/properties/properties/properties/preset_value',
            type: ['number', 'null'],
          },
          previous_mission_position: {
            description: 'previous order of the mission moved',
            id: '/properties/properties/properties/previous_mission_position',
            type: ['integer', 'null'],
          },
          pricing_method: {
            description: 'pricing method. Samples: Preset, Variable',
            id: '/properties/properties/properties/pricing_method',
            type: ['string', 'null'],
          },
          priority_change_method: {
            description: 'method the priority change was saved (drag and drop; position edition)',
            id: '/properties/properties/properties/priority_change_method',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          quantity: {
            description: 'Quantity of invalid POCs listed in a single campaign',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          referrer: {
            description:
              'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
            id: '/properties/properties/properties/referrer',
            type: 'object',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
          script: {
            description:
              'script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...',
            id: '/properties/properties/properties/script',
            type: ['string', 'null'],
          },
          search: {
            description: 'The term used in search modules. Samples: Invoices',
            id: '/properties/properties/properties/search',
            type: 'object',
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            id: '/properties/properties/properties/search_query',
            type: ['string', 'null'],
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            id: '/properties/properties/properties/segment',
            type: ['string', 'null'],
          },
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: ['string', 'null'],
          },
          services: {
            description: 'array of services objects',
            id: '/properties/properties/properties/services',
            items: {
              description: 'a single service setup',
              id: '/properties/properties/properties/services/items',
              properties: {
                description: {
                  description:
                    'description of the service (Will enable paying bills with rewards points.)',
                  id: '/properties/properties/properties/services/items/properties/description',
                  type: ['string', 'null'],
                },
                is_available: {
                  description: 'if the service is available (True if available False if not)',
                  id: '/properties/properties/properties/services/items/properties/is_available',
                  type: ['boolean', 'null'],
                },
                name: {
                  description: 'title of the service (Bill payment)',
                  id: '/properties/properties/properties/services/items/properties/name',
                  type: ['string', 'null'],
                },
                preset_value: {
                  description: 'value in reward points when pricing method is preset (800)',
                  id: '/properties/properties/properties/services/items/properties/preset_value',
                  type: ['integer', 'null'],
                },
                pricing_method: {
                  description: 'pricing method (Preset, Variable)',
                  id: '/properties/properties/properties/services/items/properties/pricing_method',
                  type: ['string', 'null'],
                },
                variable_pricing: {
                  description: 'array of the variable pricing objects',
                  id: '/properties/properties/properties/services/items/properties/variable_pricing',
                  items: {
                    description: 'a single variable pricing setup',
                    id: '/properties/properties/properties/services/items/properties/variable_pricing/items',
                    properties: {
                      attribute_value: {
                        description: 'value of the attribute (Grocery stores AB01)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/attribute_value',
                        type: 'string',
                      },
                      poc_attribute: {
                        description: 'attribute of the POC (Segment)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/poc_attribute',
                        type: ['string', 'null'],
                      },
                      value: {
                        description: 'cost value (500)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/value',
                        type: ['integer', 'null'],
                      },
                    },
                    required: ['poc_attribute', 'attribute_value', 'value'],
                    type: 'object',
                  },
                  type: 'array',
                },
              },
              required: [
                'name',
                'is_available',
                'pricing_method',
                'preset_value',
                'description',
                'variable_pricing',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          services_script: {
            description:
              'script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)',
            id: '/properties/properties/properties/services_script',
            type: ['string', 'null'],
          },
          sku: {
            description: 'product SKU (ABIC001431)',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
          sponsor: {
            description: 'sponsor of the mission (Diageo)',
            id: '/properties/properties/properties/sponsor',
            type: ['boolean', 'null'],
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_only: {
            description: 'If the filter sponsored only was selected: (true, false)',
            id: '/properties/properties/properties/sponsored_only',
            type: ['boolean', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          sponsored_tag: {
            description: 'If this local mission is a sponsored (T)',
            id: '/properties/properties/properties/sponsored_tag',
            type: ['boolean', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          status: {
            description: 'all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)',
            enum: ['Concluded', 'Expired', 'Ongoing', 'Paused', 'Scheduled', null],
            id: '/properties/properties/properties/status',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            id: '/properties/properties/properties/tag_name',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occured (drill down to hour only). Samples: 15, 09',
            id: '/properties/properties/properties/time_of_day',
            type: ['integer', 'null'],
          },
          title: {
            description: 'Title of the page displayed to the use. Sample: Gap & Status',
            id: '/properties/properties/properties/title',
            type: 'object',
          },
          type: {
            description: 'all types selected (Coverage, Simple)',
            enum: ['Coverage', 'Simple', null],
            id: '/properties/properties/properties/type',
            type: ['string', 'null'],
          },
          url: {
            description:
              'URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call',
            id: '/properties/properties/properties/url',
            type: 'object',
          },
        },
        required: [
          'campaign_segmentation',
          'sponsored_tag',
          'mission_priority_4',
          'search_query',
          'agent_id',
          'campaign_name',
          'block_list',
          'combo_id',
          'is_promotion',
          'agent_segmentation',
          'segment',
          'quantity',
          'mission_tags',
          'end_date',
          'pricing_method',
          'mission_category',
          'consider_delivery_window',
          'start_date',
          'digitalization_level',
          'type',
          'mission_priority_2',
          'local_mission_id',
          'priority_change_method',
          'language_selected',
          'campaign_script',
          'local_mission_title',
          'new_mission_position',
          'sponsor_partner',
          'campaign_type',
          'preset_value',
          'sku',
          'local_mission',
          'campaign_error_type',
          'button_selection',
          'button_name',
          'services',
          'time_of_day',
          'script',
          'poc_id',
          'tag_name',
          'missions',
          'mission_priority',
          'effectiveness_criteria',
          'partner',
          'period',
          'minimum_quantity',
          'campaign_priority',
          'status',
          'services_script',
          'hierarchy',
          'description',
          'error_message',
          'products',
          'mission_priority_1',
          'mission_priority_5',
          'mission_type',
          'button_label',
          'screen_name',
          'tab_label',
          'call_id',
          'mission_priority_3',
          'is_out_of_stock',
          'sponsor',
          'filter_option',
          'local_segmentation',
          'service_name',
          'is_active',
          'country',
          'previous_mission_position',
          'screen_section',
          'sponsored_only',
          'error_type',
          'consider_block_list',
          'sponsored_mission',
          'category',
          'campaign_description',
          'DDC',
          'mission',
          'name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Missions UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Missions UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Missions UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Create Mission" inside Local Missions page and goes to step 1
 *
 * @param {NewLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newLocalMissionStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "Create Mission" inside Local Missions page and goes to step 1',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_category: {
            description: 'category of the local mission (digital education)',
            id: '/properties/properties/properties/mission_category',
            type: ['string', 'null'],
          },
          mission_tags: {
            description: 'The list of included tags',
            id: '/properties/properties/properties/mission_tags',
            type: ['array', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
        },
        required: ['mission_category', 'mission_tags', 'mission_type', 'sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'New Local Mission Started',
    type: 'object',
  };
  const message = {
    event: 'New Local Mission Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('New Local Mission Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "New sponsored mission" inside Sponsored Missions page and goes to step 1
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newSponsoredMissionStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "New sponsored mission" inside Sponsored Missions page and goes to step 1',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'New Sponsored Mission Started',
    type: 'object',
  };
  const message = {
    event: 'New Sponsored Mission Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('New Sponsored Mission Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * When the user accesses another page in the application
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user accesses another page in the application',
    properties: {
      context: {},
      properties: {
        properties: {
          '': {
            description: '',
            type: 'object',
          },
          path: {
            description: 'Path of the screen. Samples: /call',
            type: 'object',
          },
          referrer: {
            description:
              'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
            type: 'object',
          },
          search: {
            description: 'The term used in search modules. Samples: Invoices',
            type: 'object',
          },
          title: {
            description: 'Title of the page displayed to the use. Sample: Gap & Status',
            type: 'object',
          },
          url: {
            description:
              'URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call',
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Page Viewed',
    type: 'object',
  };
  const message = {
    event: 'Page Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Page Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
 *
 * @param {RetryButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function retryButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            type: ['string', 'null'],
          },
        },
        required: ['screen_section', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Retry Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Retry Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Retry Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Add service" inside service creation modal and a new service is added
 *
 * @param {ServiceAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Add service" inside service creation modal and a new service is added',
    properties: {
      context: {},
      properties: {
        properties: {
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Service Added',
    type: 'object',
  };
  const message = {
    event: 'Service Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Service Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Add service" or "Save changes" inside Services page and the mission is saved
 *
 * @param {ServiceSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Add service" or "Save changes" inside Services page and the mission is saved',
    properties: {
      context: {},
      properties: {
        properties: {
          country: {
            description: 'country being configured',
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          description: {
            description:
              'description of the item. Sample: Will enable paying bills with rewards points.',
            id: '/properties/properties/properties/description',
            type: ['string', 'null'],
          },
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: ['boolean', 'null'],
          },
          preset_value: {
            description: 'value in rewards points when pricing method is preset. Sample: 800',
            id: '/properties/properties/properties/preset_value',
            type: ['number', 'null'],
          },
          pricing_method: {
            description: 'pricing method. Samples: Preset, Variable',
            id: '/properties/properties/properties/pricing_method',
            type: ['string', 'null'],
          },
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: ['string', 'null'],
          },
        },
        required: [
          'country',
          'service_name',
          'is_active',
          'pricing_method',
          'preset_value',
          'description',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Service Saved',
    type: 'object',
  };
  const message = {
    event: 'Service Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Service Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user switches any toggle inside service's listing and the availability is changed
 *
 * @param {ServiceStatusChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceStatusChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "user switches any toggle inside service's listing and the availability is changed",
    properties: {
      context: {},
      properties: {
        properties: {
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: 'object',
          },
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Service Status Changed',
    type: 'object',
  };
  const message = {
    event: 'Service Status Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Service Status Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user saves changes after editing the services' script and parameters
 *
 * @param {ServicesChangesSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesChangesSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "user saves changes after editing the services' script and parameters",
    properties: {
      context: {},
      properties: {
        properties: {
          script: {
            description:
              'script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...',
            id: '/properties/properties/properties/script',
            type: ['string', 'null'],
          },
        },
        required: ['script'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Services Changes Saved',
    type: 'object',
  };
  const message = {
    event: 'Services Changes Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Services Changes Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Save changes" inside Services page and the mission is saved
 *
 * @param {ServicesMissionSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesMissionSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user clicks on "Save changes" inside Services page and the mission is saved',
    properties: {
      context: {},
      properties: {
        properties: {
          country: {
            description: 'country being configured',
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          services: {
            description: 'array of services objects',
            id: '/properties/properties/properties/services',
            items: {
              description: 'a single service setup',
              id: '/properties/properties/properties/services/items',
              properties: {
                description: {
                  description:
                    'description of the service (Will enable paying bills with rewards points.)',
                  id: '/properties/properties/properties/services/items/properties/description',
                  type: ['string', 'null'],
                },
                is_available: {
                  description: 'if the service is available (True if available False if not)',
                  id: '/properties/properties/properties/services/items/properties/is_available',
                  type: ['boolean', 'null'],
                },
                name: {
                  description: 'title of the service (Bill payment)',
                  id: '/properties/properties/properties/services/items/properties/name',
                  type: ['string', 'null'],
                },
                preset_value: {
                  description: 'value in reward points when pricing method is preset (800)',
                  id: '/properties/properties/properties/services/items/properties/preset_value',
                  type: ['integer', 'null'],
                },
                pricing_method: {
                  description: 'pricing method (Preset, Variable)',
                  id: '/properties/properties/properties/services/items/properties/pricing_method',
                  type: ['string', 'null'],
                },
              },
              required: ['name', 'is_available', 'pricing_method', 'preset_value', 'description'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          services_script: {
            description:
              'script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)',
            id: '/properties/properties/properties/services_script',
            type: ['string', 'null'],
          },
        },
        required: ['services_script', 'services', 'country'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Services Mission Saved',
    type: 'object',
  };
  const message = {
    event: 'Services Mission Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Services Mission Saved', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user performs an UX action in the Services page
 *
 * @param {ServicesUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user performs an UX action in the Services page',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
        },
        required: ['screen_section', 'button_label', 'button_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Services UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Services UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Services UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on any button inside sidebar section
 *
 * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sidebarInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on any button inside sidebar section',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: English, spanish',
            type: ['string', 'null'],
          },
        },
        required: ['button_name', 'button_label', 'button_selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sidebar Interaction',
    type: 'object',
  };
  const message = {
    event: 'Sidebar Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sidebar Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "next" after setting the step 3 of the Sponsored Missions and advances to step 4
 *
 * @param {SponsoredAgentSetupCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredAgentSetupCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "next" after setting the step 3 of the Sponsored Missions and advances to step 4',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_type: {
            description: 'Type of the sponsored mission. Sample: Thrive',
            id: '/properties/properties/properties/mission_type',
            type: 'object',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Agent Setup Completed',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Agent Setup Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Agent Setup Completed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on "next" after setting the step 2 of the Sponsored Missions and advances to step 3
 *
 * @param {SponsoredContentCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredContentCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "next" after setting the step 2 of the Sponsored Missions and advances to step 3',
    properties: {
      context: {},
      properties: {
        properties: {
          mission_type: {
            description: 'Type of the sponsored mission. Sample: Thrive',
            id: '/properties/properties/properties/mission_type',
            type: 'object',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Content Completed',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Content Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Content Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "next" after setting the step 1 of the Sponsored Missions and advances to step 2
 *
 * @param {SponsoredMainDetailsCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMainDetailsCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "next" after setting the step 1 of the Sponsored Missions and advances to step 2',
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'Direct distribution center. Sample: 0575',
            id: '/properties/properties/properties/DDC',
            type: ['string', 'null'],
          },
          agent_id: {
            description: 'Unique user identification (email)',
            id: '/properties/properties/properties/agent_id',
            type: ['string', 'null'],
          },
          agent_segmentation: {
            description: 'Segmentation of the agent. Sample: C2B',
            id: '/properties/properties/properties/agent_segmentation',
            type: ['array', 'null'],
          },
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            id: '/properties/properties/properties/block_list',
            type: ['string', 'null'],
          },
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: English, spanish',
            id: '/properties/properties/properties/button_selection',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            id: '/properties/properties/properties/campaign_description',
            type: ['string', 'null'],
          },
          campaign_error_type: {
            description:
              'The error type(s) that occured in the campaign registration. Sample: invalid CSV format',
            id: '/properties/properties/properties/campaign_error_type',
            type: ['array', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            id: '/properties/properties/properties/campaign_name',
            type: ['string', 'null'],
          },
          campaign_priority: {
            description: 'The prioritization rank of the campaign. 1 if primary and 2 if secondary',
            id: '/properties/properties/properties/campaign_priority',
            type: ['integer', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            id: '/properties/properties/properties/campaign_script',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            id: '/properties/properties/properties/campaign_segmentation',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            id: '/properties/properties/properties/campaign_type',
            type: ['string', 'null'],
          },
          category: {
            description: 'All categories selected (Digital education, Value Creation)',
            enum: ['Digital education', 'Value Creation', null],
            id: '/properties/properties/properties/category',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'combo ID (DT01290007980)',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          country: {
            description: 'country being configured',
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          description: {
            description:
              'description of the item. Sample: Will enable paying bills with rewards points.',
            id: '/properties/properties/properties/description',
            type: ['string', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed in the UI that describes the error. Sample: Field required',
            id: '/properties/properties/properties/error_message',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Error that occured. Sample: no_connection\n',
            id: '/properties/properties/properties/error_type',
            type: ['string', 'null'],
          },
          filter_option: {
            description:
              'Filter selection. Samples: gap only, premium, core. Null if not applicable',
            id: '/properties/properties/properties/filter_option',
            type: ['string', 'null'],
          },
          hierarchy: {
            description: 'Hierarchy level. Samples: Supervisor, Agent',
            id: '/properties/properties/properties/hierarchy',
            type: ['string', 'null'],
          },
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: 'if the product is out of stock (T)',
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: 'if the product is a promotion/deal (T)',
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          language_selected: {
            description: 'UI language selected. Samples: EN, ES',
            id: '/properties/properties/properties/language_selected',
            type: ['string', 'null'],
          },
          local_mission: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          minimum_quantity: {
            description:
              'number of the minimum quantity suggested for that product/combo or mission.',
            id: '/properties/properties/properties/minimum_quantity',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_category: {
            description: 'category of the local mission (digital education)',
            id: '/properties/properties/properties/mission_category',
            type: ['string', 'null'],
          },
          mission_priority: {
            description: 'Prioritization of the mission. Samples: Primary, Secondary',
            id: '/properties/properties/properties/mission_priority',
            type: ['string', 'null'],
          },
          mission_priority_1: {
            description: 'The first mission priority of the agent. Sample: GAP',
            id: '/properties/properties/properties/mission_priority_1',
            type: ['string', 'null'],
          },
          mission_priority_2: {
            description: 'The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION',
            id: '/properties/properties/properties/mission_priority_2',
            type: ['string', 'null'],
          },
          mission_priority_3: {
            description: 'The third mission priority of the agent. Sample: REWARDS_CHALLENGES',
            id: '/properties/properties/properties/mission_priority_3',
            type: ['string', 'null'],
          },
          mission_priority_4: {
            description: 'The fourth mission priority of the agent. Sample: SPONSORED',
            id: '/properties/properties/properties/mission_priority_4',
            type: ['string', 'null'],
          },
          mission_priority_5: {
            description: 'The fifth mission priority of the agent. Sample: SERVICES',
            id: '/properties/properties/properties/mission_priority_5',
            type: ['string', 'null'],
          },
          mission_tags: {
            description: 'The list of included tags',
            id: '/properties/properties/properties/mission_tags',
            type: ['array', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          name: {
            description: 'name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          new_mission_position: {
            description: 'new order of the mission moved',
            id: '/properties/properties/properties/new_mission_position',
            type: ['integer', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          path: {
            description: 'Path of the screen. Samples: /call',
            id: '/properties/properties/properties/path',
            type: 'object',
          },
          period: {
            description: 'Period',
            id: '/properties/properties/properties/period',
            items: {
              description: '',
              id: '/properties/properties/properties/period/items',
              properties: {
                End: {
                  description: 'end date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/End',
                  type: ['string', 'null'],
                },
                Start: {
                  description: 'start date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/Start',
                  type: ['string', 'null'],
                },
              },
              required: ['Start', 'End'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          preset_value: {
            description: 'value in rewards points when pricing method is preset. Sample: 800',
            id: '/properties/properties/properties/preset_value',
            type: ['number', 'null'],
          },
          previous_mission_position: {
            description: 'previous order of the mission moved',
            id: '/properties/properties/properties/previous_mission_position',
            type: ['integer', 'null'],
          },
          pricing_method: {
            description: 'pricing method. Samples: Preset, Variable',
            id: '/properties/properties/properties/pricing_method',
            type: ['string', 'null'],
          },
          priority_change_method: {
            description: 'method the priority change was saved (drag and drop; position edition)',
            id: '/properties/properties/properties/priority_change_method',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          quantity: {
            description: 'Quantity of invalid POCs listed in a single campaign',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          referrer: {
            description:
              'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
            id: '/properties/properties/properties/referrer',
            type: 'object',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
          script: {
            description:
              'script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...',
            id: '/properties/properties/properties/script',
            type: ['string', 'null'],
          },
          search: {
            description: 'The term used in search modules. Samples: Invoices',
            id: '/properties/properties/properties/search',
            type: 'object',
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            id: '/properties/properties/properties/search_query',
            type: ['string', 'null'],
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            id: '/properties/properties/properties/segment',
            type: ['string', 'null'],
          },
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: ['string', 'null'],
          },
          services: {
            description: 'array of services objects',
            id: '/properties/properties/properties/services',
            items: {
              description: 'a single service setup',
              id: '/properties/properties/properties/services/items',
              properties: {
                description: {
                  description:
                    'description of the service (Will enable paying bills with rewards points.)',
                  id: '/properties/properties/properties/services/items/properties/description',
                  type: ['string', 'null'],
                },
                is_available: {
                  description: 'if the service is available (True if available False if not)',
                  id: '/properties/properties/properties/services/items/properties/is_available',
                  type: ['boolean', 'null'],
                },
                name: {
                  description: 'title of the service (Bill payment)',
                  id: '/properties/properties/properties/services/items/properties/name',
                  type: ['string', 'null'],
                },
                preset_value: {
                  description: 'value in reward points when pricing method is preset (800)',
                  id: '/properties/properties/properties/services/items/properties/preset_value',
                  type: ['integer', 'null'],
                },
                pricing_method: {
                  description: 'pricing method (Preset, Variable)',
                  id: '/properties/properties/properties/services/items/properties/pricing_method',
                  type: ['string', 'null'],
                },
                variable_pricing: {
                  description: 'array of the variable pricing objects',
                  id: '/properties/properties/properties/services/items/properties/variable_pricing',
                  items: {
                    description: 'a single variable pricing setup',
                    id: '/properties/properties/properties/services/items/properties/variable_pricing/items',
                    properties: {
                      attribute_value: {
                        description: 'value of the attribute (Grocery stores AB01)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/attribute_value',
                        type: 'string',
                      },
                      poc_attribute: {
                        description: 'attribute of the POC (Segment)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/poc_attribute',
                        type: ['string', 'null'],
                      },
                      value: {
                        description: 'cost value (500)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/value',
                        type: ['integer', 'null'],
                      },
                    },
                    required: ['poc_attribute', 'attribute_value', 'value'],
                    type: 'object',
                  },
                  type: 'array',
                },
              },
              required: [
                'name',
                'is_available',
                'pricing_method',
                'preset_value',
                'description',
                'variable_pricing',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          services_script: {
            description:
              'script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)',
            id: '/properties/properties/properties/services_script',
            type: ['string', 'null'],
          },
          sku: {
            description: 'product SKU (ABIC001431)',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
          sponsor: {
            description: 'sponsor of the mission (Diageo)',
            id: '/properties/properties/properties/sponsor',
            type: ['boolean', 'null'],
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_only: {
            description: 'If the filter sponsored only was selected: (true, false)',
            id: '/properties/properties/properties/sponsored_only',
            type: ['boolean', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          sponsored_tag: {
            description: 'If this local mission is a sponsored (T)',
            id: '/properties/properties/properties/sponsored_tag',
            type: ['boolean', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          status: {
            description: 'all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)',
            enum: ['Concluded', 'Expired', 'Ongoing', 'Paused', 'Scheduled', null],
            id: '/properties/properties/properties/status',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            id: '/properties/properties/properties/tag_name',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occured (drill down to hour only). Samples: 15, 09',
            id: '/properties/properties/properties/time_of_day',
            type: ['integer', 'null'],
          },
          title: {
            description: 'Title of the page displayed to the use. Sample: Gap & Status',
            id: '/properties/properties/properties/title',
            type: 'object',
          },
          type: {
            description: 'all types selected (Coverage, Simple)',
            enum: ['Coverage', 'Simple', null],
            id: '/properties/properties/properties/type',
            type: ['string', 'null'],
          },
          url: {
            description:
              'URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call',
            id: '/properties/properties/properties/url',
            type: 'object',
          },
        },
        required: [
          'mission_tags',
          'screen_name',
          'products',
          'sponsored_only',
          'consider_delivery_window',
          'digitalization_level',
          'mission_priority_3',
          'services',
          'button_name',
          'status',
          'missions',
          'agent_segmentation',
          'campaign_name',
          'sponsored_tag',
          'minimum_quantity',
          'previous_mission_position',
          'category',
          'segment',
          'priority_change_method',
          'campaign_priority',
          'sku',
          'button_label',
          'pricing_method',
          'start_date',
          'campaign_type',
          'mission_priority_1',
          'error_message',
          'call_id',
          'local_mission_id',
          'sponsor_partner',
          'script',
          'sponsor',
          'local_segmentation',
          'agent_id',
          'hierarchy',
          'mission_category',
          'button_selection',
          'is_active',
          'block_list',
          'combo_id',
          'campaign_description',
          'error_type',
          'preset_value',
          'service_name',
          'period',
          'campaign_error_type',
          'filter_option',
          'consider_block_list',
          'campaign_script',
          'mission_type',
          'effectiveness_criteria',
          'type',
          'services_script',
          'quantity',
          'is_promotion',
          'mission_priority_5',
          'search_query',
          'local_mission_title',
          'poc_id',
          'sponsored_mission',
          'time_of_day',
          'mission_priority',
          'tab_label',
          'DDC',
          'tag_name',
          'is_out_of_stock',
          'end_date',
          'screen_section',
          'local_mission',
          'description',
          'name',
          'country',
          'language_selected',
          'campaign_segmentation',
          'mission_priority_2',
          'partner',
          'mission',
          'new_mission_position',
          'mission_priority_4',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Main Details Completed',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Main Details Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Main Details Completed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * user saves changes after editing a sponsored mission
 *
 * @param {SponsoredMissionChangesSaved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionChangesSaved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user saves changes after editing a sponsored mission',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: 'string',
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'consider_block_list',
          'consider_delivery_window',
          'digitalization_level',
          'end_date',
          'mission_type',
          'partner',
          'products',
          'sponsored_mission',
          'sponsored_segmentation',
          'start_date',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Mission Changes Saved',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Mission Changes Saved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Mission Changes Saved',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * user completes the sponsored creation process and a new mission is created successfully
 *
 * @param {SponsoredMissionCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionCreated(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user completes the sponsored creation process and a new mission is created successfully',
    properties: {
      context: {},
      properties: {
        properties: {
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          mission_type: {
            description: 'Type of the sponsored mission. Sample: Thrive',
            id: '/properties/properties/properties/mission_type',
            type: 'object',
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: ['sku', 'suggested_quantity', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
        },
        required: [
          'end_date',
          'consider_block_list',
          'consider_delivery_window',
          'digitalization_level',
          'products',
          'sponsored_segmentation',
          'start_date',
          'partner',
          'sponsored_mission',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Mission Created',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Mission Created',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Mission Created', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on the toggle inside the cards and the mission is activated/deactivated
 *
 * @param {SponsoredMissionStatusChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionStatusChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on the toggle inside the cards and the mission is activated/deactivated',
    properties: {
      context: {},
      properties: {
        properties: {
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: 'object',
          },
          mission_type: {
            description: 'Type of the sponsored mission. Sample: Thrive',
            id: '/properties/properties/properties/mission_type',
            type: 'object',
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Mission Status Changed',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Mission Status Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Mission Status Changed',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * a file is uploaded with success after clicking on "Upload a CSV file" inside creation step 4
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredPocListUploaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'a file is uploaded with success after clicking on "Upload a CSV file" inside creation step 4',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored POC List Uploaded',
    type: 'object',
  };
  const message = {
    event: 'Sponsored POC List Uploaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored POC List Uploaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters a sponsored mission's product quantity
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "user alters a sponsored mission's product quantity",
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Product Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * user removes a product from the sponsored mission
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductRemoved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user removes a product from the sponsored mission',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Product Removed',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Product Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Product Removed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the search field/button in the Content step
 *
 * @param {SponsoredSearchInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the Content step',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            id: '/properties/properties/properties/search_query',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'search_query'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Search Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user performs an UX action in the sponsored missions page
 *
 * @param {SponsoredUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user performs an UX action in the sponsored missions page',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
        },
        required: ['button_name', 'screen_name', 'button_label', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Sponsored UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Import" after a Team Structure file is uploaded
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureImported(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "Import" after a Team Structure file is uploaded',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Team Structure Imported',
    type: 'object',
  };
  const message = {
    event: 'Team Structure Imported',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Team Structure Imported', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on UX buttons across the Team Structure page
 *
 * @param {TeamStructureUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user clicks on UX buttons across the Team Structure page',
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'Direct distribution center. Sample: 0575',
            id: '/properties/properties/properties/DDC',
            type: ['string', 'null'],
          },
          agent_id: {
            description: 'Unique user identification (email)',
            id: '/properties/properties/properties/agent_id',
            type: ['string', 'null'],
          },
          agent_segmentation: {
            description: 'Segmentation of the agent. Sample: C2B',
            id: '/properties/properties/properties/agent_segmentation',
            type: ['array', 'null'],
          },
          block_list: {
            description: 'Name of the block list. Sample: block_list_january',
            id: '/properties/properties/properties/block_list',
            type: ['string', 'null'],
          },
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card',
            id: '/properties/properties/properties/button_label',
            type: ['string', 'null'],
          },
          button_name: {
            description:
              'Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only',
            id: '/properties/properties/properties/button_name',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: English, spanish',
            id: '/properties/properties/properties/button_selection',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'Id of the call (12123)',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          campaign_description: {
            description:
              'Description of the campaign. Sample: campaign to offer our Special Colorado Beers',
            id: '/properties/properties/properties/campaign_description',
            type: ['string', 'null'],
          },
          campaign_error_type: {
            description:
              'The error type(s) that occured in the campaign registration. Sample: invalid CSV format',
            id: '/properties/properties/properties/campaign_error_type',
            type: ['array', 'null'],
          },
          campaign_name: {
            description: 'Name of the campaign. Sample: Summer Favourites\n',
            id: '/properties/properties/properties/campaign_name',
            type: ['string', 'null'],
          },
          campaign_priority: {
            description: 'The prioritization rank of the campaign. 1 if primary and 2 if secondary',
            id: '/properties/properties/properties/campaign_priority',
            type: ['integer', 'null'],
          },
          campaign_script: {
            description:
              'Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood',
            id: '/properties/properties/properties/campaign_script',
            type: ['string', 'null'],
          },
          campaign_segmentation: {
            description: 'Segmentation of the campaign. Sample: C2B',
            id: '/properties/properties/properties/campaign_segmentation',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: Focus SKU, B2O',
            id: '/properties/properties/properties/campaign_type',
            type: ['string', 'null'],
          },
          category: {
            description: 'All categories selected (Digital education, Value Creation)',
            enum: ['Digital education', 'Value Creation', null],
            id: '/properties/properties/properties/category',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'combo ID (DT01290007980)',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          consider_block_list: {
            description: 'if the POC filter will consider the block list (T)',
            id: '/properties/properties/properties/consider_block_list',
            type: ['boolean', 'null'],
          },
          consider_delivery_window: {
            description: 'if the POC filter will consider the delivery window (T)',
            id: '/properties/properties/properties/consider_delivery_window',
            type: ['boolean', 'null'],
          },
          country: {
            description: 'country being configured',
            id: '/properties/properties/properties/country',
            type: ['string', 'null'],
          },
          description: {
            description:
              'description of the item. Sample: Will enable paying bills with rewards points.',
            id: '/properties/properties/properties/description',
            type: ['string', 'null'],
          },
          digitalization_level: {
            description: 'list of included digitalization levels (["Adoption", "Full digital"])',
            id: '/properties/properties/properties/digitalization_level',
            type: ['array', 'null'],
          },
          effectiveness_criteria: {
            description: 'type of the effectiveness criteria (at least one)',
            id: '/properties/properties/properties/effectiveness_criteria',
            type: ['string', 'null'],
          },
          end_date: {
            description: 'End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/end_date',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed in the UI that describes the error. Sample: Field required',
            id: '/properties/properties/properties/error_message',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Error that occured. Sample: no_connection\n',
            id: '/properties/properties/properties/error_type',
            type: ['string', 'null'],
          },
          filter_option: {
            description:
              'Filter selection. Samples: gap only, premium, core. Null if not applicable',
            id: '/properties/properties/properties/filter_option',
            type: ['string', 'null'],
          },
          hierarchy: {
            description: 'Hierarchy level. Samples: Supervisor, Agent',
            id: '/properties/properties/properties/hierarchy',
            type: ['string', 'null'],
          },
          is_active: {
            description: 'if the service is active or not (T)',
            id: '/properties/properties/properties/is_active',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: 'if the product is out of stock (T)',
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: 'if the product is a promotion/deal (T)',
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          language_selected: {
            description: 'UI language selected. Samples: EN, ES',
            id: '/properties/properties/properties/language_selected',
            type: ['string', 'null'],
          },
          local_mission: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission',
            type: ['string', 'null'],
          },
          local_mission_id: {
            description: 'Id of the local mission',
            id: '/properties/properties/properties/local_mission_id',
            type: ['string', 'null'],
          },
          local_mission_title: {
            description: 'Title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission_title',
            type: ['string', 'null'],
          },
          local_segmentation: {
            description: 'The name of the segmentation of the local mission',
            id: '/properties/properties/properties/local_segmentation',
            type: ['string', 'null'],
          },
          minimum_quantity: {
            description:
              'number of the minimum quantity suggested for that product/combo or mission.',
            id: '/properties/properties/properties/minimum_quantity',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Title of the sponsored/local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          mission_category: {
            description: 'category of the local mission (digital education)',
            id: '/properties/properties/properties/mission_category',
            type: ['string', 'null'],
          },
          mission_priority: {
            description: 'Prioritization of the mission. Samples: Primary, Secondary',
            id: '/properties/properties/properties/mission_priority',
            type: ['string', 'null'],
          },
          mission_priority_1: {
            description: 'The first mission priority of the agent. Sample: GAP',
            id: '/properties/properties/properties/mission_priority_1',
            type: ['string', 'null'],
          },
          mission_priority_2: {
            description: 'The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION',
            id: '/properties/properties/properties/mission_priority_2',
            type: ['string', 'null'],
          },
          mission_priority_3: {
            description: 'The third mission priority of the agent. Sample: REWARDS_CHALLENGES',
            id: '/properties/properties/properties/mission_priority_3',
            type: ['string', 'null'],
          },
          mission_priority_4: {
            description: 'The fourth mission priority of the agent. Sample: SPONSORED',
            id: '/properties/properties/properties/mission_priority_4',
            type: ['string', 'null'],
          },
          mission_priority_5: {
            description: 'The fifth mission priority of the agent. Sample: SERVICES',
            id: '/properties/properties/properties/mission_priority_5',
            type: ['string', 'null'],
          },
          mission_tags: {
            description: 'The list of included tags',
            id: '/properties/properties/properties/mission_tags',
            type: ['array', 'null'],
          },
          mission_type: {
            description:
              'Triggered when sending information about the selected mission type in a new sponsored mission.',
            id: '/properties/properties/properties/mission_type',
            type: ['string', 'null'],
          },
          missions: {
            description: 'The array of missions objects',
            id: '/properties/properties/properties/missions',
            items: {
              description: 'A single mission available to the zone',
              id: '/properties/properties/properties/missions/items',
              properties: {
                is_active: {
                  description: "If the mission is set as active. It's a boolean",
                  id: '/properties/properties/properties/missions/items/properties/is_active',
                  type: ['boolean', 'null'],
                },
                order: {
                  description:
                    "Order of the mission (only if it's a primary, otherwise must be null). Sample: 2",
                  id: '/properties/properties/properties/missions/items/properties/order',
                  type: ['integer', 'null'],
                },
                type: {
                  description: 'Type of the mission. Samples: Gap, Rewards',
                  id: '/properties/properties/properties/missions/items/properties/type',
                  type: ['string', 'null'],
                },
              },
              required: ['type', 'order', 'is_active'],
              type: 'object',
            },
            type: 'array',
          },
          name: {
            description: 'name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          new_mission_position: {
            description: 'new order of the mission moved',
            id: '/properties/properties/properties/new_mission_position',
            type: ['integer', 'null'],
          },
          partner: {
            description: 'partner of the mission (Diageo)\n',
            id: '/properties/properties/properties/partner',
            type: ['string', 'null'],
          },
          path: {
            description: 'Path of the screen. Samples: /call',
            id: '/properties/properties/properties/path',
            type: 'object',
          },
          period: {
            description: 'Period',
            id: '/properties/properties/properties/period',
            items: {
              description: '',
              id: '/properties/properties/properties/period/items',
              properties: {
                End: {
                  description: 'end date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/End',
                  type: ['string', 'null'],
                },
                Start: {
                  description: 'start date (timestamp format) (2021-07-05T00:00:00.000Z)',
                  id: '/properties/properties/properties/period/items/properties/Start',
                  type: ['string', 'null'],
                },
              },
              required: ['Start', 'End'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'Id of the POC (446109)',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          preset_value: {
            description: 'value in rewards points when pricing method is preset. Sample: 800',
            id: '/properties/properties/properties/preset_value',
            type: ['number', 'null'],
          },
          previous_mission_position: {
            description: 'previous order of the mission moved',
            id: '/properties/properties/properties/previous_mission_position',
            type: ['integer', 'null'],
          },
          pricing_method: {
            description: 'pricing method. Samples: Preset, Variable',
            id: '/properties/properties/properties/pricing_method',
            type: ['string', 'null'],
          },
          priority_change_method: {
            description: 'method the priority change was saved (drag and drop; position edition)',
            id: '/properties/properties/properties/priority_change_method',
            type: ['string', 'null'],
          },
          products: {
            description: 'the array of all product objects in the list',
            id: '/properties/properties/properties/products',
            items: {
              description: 'an individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: 'if the product is out of stock (T)',
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: 'if the product is a promotion/deal (T), if applicable.',
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
                suggested_quantity: {
                  description: 'suggested quantity of the item. Sample: 4',
                  id: '/properties/properties/properties/products/items/properties/suggested_quantity',
                  type: ['integer', 'null'],
                },
              },
              required: [
                'sku',
                'suggested_quantity',
                'combo_id',
                'is_out_of_stock',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
          quantity: {
            description: 'Quantity of invalid POCs listed in a single campaign',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          referrer: {
            description:
              'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
            id: '/properties/properties/properties/referrer',
            type: 'object',
          },
          screen_name: {
            description: "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Location of the screen. Samples: suggested order, last orders',
            id: '/properties/properties/properties/screen_section',
            type: ['string', 'null'],
          },
          script: {
            description:
              'script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...',
            id: '/properties/properties/properties/script',
            type: ['string', 'null'],
          },
          search: {
            description: 'The term used in search modules. Samples: Invoices',
            id: '/properties/properties/properties/search',
            type: 'object',
          },
          search_query: {
            description: 'Text typed in the product search field. Sample: Brahma Pilsen',
            id: '/properties/properties/properties/search_query',
            type: ['string', 'null'],
          },
          segment: {
            description: 'Segment of the campaign. Sample: 04',
            id: '/properties/properties/properties/segment',
            type: ['string', 'null'],
          },
          service_name: {
            description: 'title of the service (Bill payment)',
            id: '/properties/properties/properties/service_name',
            type: ['string', 'null'],
          },
          services: {
            description: 'array of services objects',
            id: '/properties/properties/properties/services',
            items: {
              description: 'a single service setup',
              id: '/properties/properties/properties/services/items',
              properties: {
                description: {
                  description:
                    'description of the service (Will enable paying bills with rewards points.)',
                  id: '/properties/properties/properties/services/items/properties/description',
                  type: ['string', 'null'],
                },
                is_available: {
                  description: 'if the service is available (True if available False if not)',
                  id: '/properties/properties/properties/services/items/properties/is_available',
                  type: ['boolean', 'null'],
                },
                name: {
                  description: 'title of the service (Bill payment)',
                  id: '/properties/properties/properties/services/items/properties/name',
                  type: ['string', 'null'],
                },
                preset_value: {
                  description: 'value in reward points when pricing method is preset (800)',
                  id: '/properties/properties/properties/services/items/properties/preset_value',
                  type: ['integer', 'null'],
                },
                pricing_method: {
                  description: 'pricing method (Preset, Variable)',
                  id: '/properties/properties/properties/services/items/properties/pricing_method',
                  type: ['string', 'null'],
                },
                variable_pricing: {
                  description: 'array of the variable pricing objects',
                  id: '/properties/properties/properties/services/items/properties/variable_pricing',
                  items: {
                    description: 'a single variable pricing setup',
                    id: '/properties/properties/properties/services/items/properties/variable_pricing/items',
                    properties: {
                      attribute_value: {
                        description: 'value of the attribute (Grocery stores AB01)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/attribute_value',
                        type: 'string',
                      },
                      poc_attribute: {
                        description: 'attribute of the POC (Segment)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/poc_attribute',
                        type: ['string', 'null'],
                      },
                      value: {
                        description: 'cost value (500)',
                        id: '/properties/properties/properties/services/items/properties/variable_pricing/items/properties/value',
                        type: ['integer', 'null'],
                      },
                    },
                    required: ['poc_attribute', 'attribute_value', 'value'],
                    type: 'object',
                  },
                  type: 'array',
                },
              },
              required: [
                'name',
                'is_available',
                'pricing_method',
                'preset_value',
                'description',
                'variable_pricing',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          services_script: {
            description:
              'script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)',
            id: '/properties/properties/properties/services_script',
            type: ['string', 'null'],
          },
          sku: {
            description: 'product SKU (ABIC001431)',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
          sponsor: {
            description: 'sponsor of the mission (Diageo)',
            id: '/properties/properties/properties/sponsor',
            type: ['boolean', 'null'],
          },
          sponsor_partner: {
            description: 'The name of the sponsor of this mission',
            id: '/properties/properties/properties/sponsor_partner',
            type: ['string', 'null'],
          },
          sponsored_mission: {
            description: 'Title of the sponsored mission. Sample: New Michelob Ultra Longneck\n',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
          sponsored_only: {
            description: 'If the filter sponsored only was selected: (true, false)',
            id: '/properties/properties/properties/sponsored_only',
            type: ['boolean', 'null'],
          },
          sponsored_segmentation: {
            description: 'the name of the segmentation of the sponsored mission',
            id: '/properties/properties/properties/sponsored_segmentation',
            type: ['string', 'null'],
          },
          sponsored_tag: {
            description: 'If this local mission is a sponsored (T)',
            id: '/properties/properties/properties/sponsored_tag',
            type: ['boolean', 'null'],
          },
          start_date: {
            description: 'Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z',
            id: '/properties/properties/properties/start_date',
            type: ['string', 'null'],
          },
          status: {
            description: 'all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)',
            enum: ['Concluded', 'Expired', 'Ongoing', 'Paused', 'Scheduled', null],
            id: '/properties/properties/properties/status',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Label of the tab clicked. Samples: Primary Missions, Secondary Missions',
            id: '/properties/properties/properties/tab_label',
            type: ['string', 'null'],
          },
          tag_name: {
            description: 'Tag name of the campaign. Sample: #Summer2021\n',
            id: '/properties/properties/properties/tag_name',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occured (drill down to hour only). Samples: 15, 09',
            id: '/properties/properties/properties/time_of_day',
            type: ['integer', 'null'],
          },
          title: {
            description: 'Title of the page displayed to the use. Sample: Gap & Status',
            id: '/properties/properties/properties/title',
            type: 'object',
          },
          type: {
            description: 'all types selected (Coverage, Simple)',
            enum: ['Coverage', 'Simple', null],
            id: '/properties/properties/properties/type',
            type: ['string', 'null'],
          },
          url: {
            description:
              'URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call',
            id: '/properties/properties/properties/url',
            type: 'object',
          },
        },
        required: [
          'error_message',
          'mission_priority_2',
          'campaign_type',
          'language_selected',
          'screen_name',
          'local_mission',
          'error_type',
          'mission',
          'description',
          'sponsored_only',
          'digitalization_level',
          'sku',
          'local_mission_id',
          'mission_priority',
          'country',
          'end_date',
          'is_out_of_stock',
          'is_active',
          'poc_id',
          'tab_label',
          'sponsor',
          'campaign_segmentation',
          'filter_option',
          'service_name',
          'services_script',
          'priority_change_method',
          'missions',
          'agent_segmentation',
          'mission_priority_5',
          'button_selection',
          'services',
          'search_query',
          'campaign_error_type',
          'DDC',
          'campaign_description',
          'mission_priority_4',
          'tag_name',
          'consider_delivery_window',
          'mission_priority_3',
          'agent_id',
          'previous_mission_position',
          'effectiveness_criteria',
          'new_mission_position',
          'mission_category',
          'call_id',
          'campaign_priority',
          'period',
          'mission_priority_1',
          'campaign_name',
          'category',
          'local_mission_title',
          'partner',
          'combo_id',
          'is_promotion',
          'button_label',
          'time_of_day',
          'block_list',
          'mission_tags',
          'status',
          'type',
          'sponsor_partner',
          'quantity',
          'campaign_script',
          'screen_section',
          'minimum_quantity',
          'sponsored_mission',
          'sponsored_tag',
          'hierarchy',
          'local_segmentation',
          'preset_value',
          'script',
          'name',
          'segment',
          'pricing_method',
          'start_date',
          'button_name',
          'mission_type',
          'products',
          'consider_block_list',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Team Structure UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Team Structure UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Team Structure UX Button Clicked',
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * A file is uploaded with success after user clicks on "Upload a CSV file" inside Team Structure page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureUploaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A file is uploaded with success after user clicks on "Upload a CSV file" inside Team Structure page',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Team Structure Uploaded',
    type: 'object',
  };
  const message = {
    event: 'Team Structure Uploaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Team Structure Uploaded', props || {}, withTypewriterContext(options), callback);
  }
}
const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Fires a '' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  emptyIdentifier,
  /**
   * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
   *
   * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  agentSegmentationAssigned,
  /**
   * User clicks on confirm button inside block list deletion modal and the block list is deleted
   *
   * @param {BlockListDeleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListDeleted,
  /**
   * User clicks on the block list download button and the list is downloaded
   *
   * @param {BlockListDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListDownloaded,
  /**
   * User clicks on confirm button inside confirmation modal and a new block list is submitted
   *
   * @param {BlockListUploaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListUploaded,
  /**
   * User clicks on the "create" button in the creation modal and the campaign is created with success
   *
   * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignCreated,
  /**
   * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
   *
   * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignDeleted,
  /**
   * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
   *
   * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignEdited,
  /**
   * User tries to create/edit a campaign, but the action returns one or more errors
   *
   * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignRegistrationFailed,
  /**
   * User interacts with the search field/button in the Focus SKU page
   *
   * @param {CampaignSearchInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignSearchInteraction,
  /**
   * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
   *
   * @param {CampaignStatusViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignStatusViewed,
  /**
     * > User clicks on ""Create new"" to create a new campaign |
    > User clicks on ""expand"" or ""collapse"" campaign details
     *
     * @param {CampaignUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  campaignUxButtonClicked,
  /**
   * User clicks on the Save button.
   *
   * @param {DuplicateLocalMissionSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  duplicateLocalMissionSaved,
  /**
   * User clicks on the Duplicate button.
   *
   * @param {DuplicateLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  duplicateLocalMissionStarted,
  /**
   * User click on th 'edit' button local of Mission Started
   *
   * @param {EditLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  editLocalMissionStarted,
  /**
   * User clicks on the "Edit" button of a Sponsored Mission and goes to the edition page
   *
   * @param {EditSponsoredMissionStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  editSponsoredMissionStarted,
  /**
   * An error is displayed to the user or an error screen is loaded
   *
   * @param {ErrorMessageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorMessageViewed,
  /**
   * User selects a language through header dropdown menu
   *
   * @param {LanguageChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  languageChanged,
  /**
   * User clicks on "next" after setting the step 3 of the Local Missions and advances to step 4
   *
   * @param {LocalMissionAudienceCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionAudienceCompleted,
  /**
   * User clicks on "next" after setting the step 2 of the Local Missions and advances to step 3
   *
   * @param {LocalMissionContentCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionContentCompleted,
  /**
   * User clicks in the “finish” button in the step 4 of the creation process, indicating that a new mission is created and saved.
   *
   * @param {LocalMissionCreated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionCreated,
  /**
   * User clicks in the local mission title/arrow to access the Details page.
   *
   * @param {LocalMissionDetailsPageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionDetailsPageViewed,
  /**
   * User clicks on "next" after setting the step 1 of the Local Missions and advances to step 2
   *
   * @param {LocalMissionMainCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionMainCompleted,
  /**
   * User clicks to export the report in the Local mission Details Page
   *
   * @param {LocalMissionReportExported} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionReportExported,
  /**
   * user performs an UX action in the local missions creation, edit, duplicate and details pages
   *
   * @param {LocalMissionUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionUxButtonClicked,
  /**
   * User clicks on 'save' after change mission.
   *
   * @param {LocalMissionsChangesSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsChangesSaved,
  /**
   * User interacts with the Local mission’s filter component
   *
   * @param {LocalMissionsFilterClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsFilterClicked,
  /**
   * Search and/or Filters used showed “No results” in the Local missions page
   *
   * @param {LocalMissionsNoResultsFound} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsNoResultsFound,
  /**
   * User changes the priority of the sponsored/local missions through the drag and drop or position number edition, inside “Active” sub-page of the Sponsored missions/Local missions page
   *
   * @param {LocalMissionsPrioritySaved} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsPrioritySaved,
  /**
   * User search a value in the search bar of local missions page
   *
   * @param {LocalMissionsSearchInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsSearchInteraction,
  /**
   * User clicks on the toggle inside the cards and the mission is activated/deactivated, either in the “Active” or “All missions” sub-page.
   *
   * @param {LocalMissionsStatusChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsStatusChanged,
  /**
   * User toggles between Active and All missions inside Sponsored missions/Local missions page.
   *
   * @param {LocalMissionsTabsInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localMissionsTabsInteraction,
  /**
   * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart.
   *
   * @param {LocalProductsAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductsAdded,
  /**
   * The products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default
   *
   * @param {LocalProductsLoaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductsLoaded,
  /**
   * user alters the quantity of a product in the Local mission tab by using the arrows or typing the quantity.
   *
   * @param {LocalProductsQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductsQuantityEdited,
  /**
   * User clicks on "apply changes" inside the missions management page and the changes are saved
   *
   * @param {MissionsSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  missionsSaved,
  /**
   * User toggles between Primary Missions and Secondary Missions inside missions page
   *
   * @param {MissionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  missionsTabInteraction,
  /**
   * User clicks on "edit" inside any mission card in Missions Management
   *
   * @param {MissionsUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  missionsUxButtonClicked,
  /**
   * User clicks on "Create Mission" inside Local Missions page and goes to step 1
   *
   * @param {NewLocalMissionStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newLocalMissionStarted,
  /**
   * User clicks on "New sponsored mission" inside Sponsored Missions page and goes to step 1
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newSponsoredMissionStarted,
  /**
   * When the user accesses another page in the application
   *
   * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageViewed,
  /**
   * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
   *
   * @param {RetryButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  retryButtonClicked,
  /**
   * user clicks on "Add service" inside service creation modal and a new service is added
   *
   * @param {ServiceAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  serviceAdded,
  /**
   * user clicks on "Add service" or "Save changes" inside Services page and the mission is saved
   *
   * @param {ServiceSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  serviceSaved,
  /**
   * user switches any toggle inside service's listing and the availability is changed
   *
   * @param {ServiceStatusChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  serviceStatusChanged,
  /**
   * user saves changes after editing the services' script and parameters
   *
   * @param {ServicesChangesSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  servicesChangesSaved,
  /**
   * user clicks on "Save changes" inside Services page and the mission is saved
   *
   * @param {ServicesMissionSaved} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  servicesMissionSaved,
  /**
   * user performs an UX action in the Services page
   *
   * @param {ServicesUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  servicesUxButtonClicked,
  /**
   * User clicks on any button inside sidebar section
   *
   * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sidebarInteraction,
  /**
   * user clicks on "next" after setting the step 3 of the Sponsored Missions and advances to step 4
   *
   * @param {SponsoredAgentSetupCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredAgentSetupCompleted,
  /**
   * User clicks on "next" after setting the step 2 of the Sponsored Missions and advances to step 3
   *
   * @param {SponsoredContentCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredContentCompleted,
  /**
   * User clicks on "next" after setting the step 1 of the Sponsored Missions and advances to step 2
   *
   * @param {SponsoredMainDetailsCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredMainDetailsCompleted,
  /**
   * user saves changes after editing a sponsored mission
   *
   * @param {SponsoredMissionChangesSaved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredMissionChangesSaved,
  /**
   * user completes the sponsored creation process and a new mission is created successfully
   *
   * @param {SponsoredMissionCreated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredMissionCreated,
  /**
   * user clicks on the toggle inside the cards and the mission is activated/deactivated
   *
   * @param {SponsoredMissionStatusChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredMissionStatusChanged,
  /**
   * a file is uploaded with success after clicking on "Upload a CSV file" inside creation step 4
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredPocListUploaded,
  /**
   * user alters a sponsored mission's product quantity
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredProductQuantityEdited,
  /**
   * user removes a product from the sponsored mission
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredProductRemoved,
  /**
   * User interacts with the search field/button in the Content step
   *
   * @param {SponsoredSearchInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredSearchInteraction,
  /**
   * user performs an UX action in the sponsored missions page
   *
   * @param {SponsoredUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredUxButtonClicked,
  /**
   * User clicks on "Import" after a Team Structure file is uploaded
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  teamStructureImported,
  /**
   * user clicks on UX buttons across the Team Structure page
   *
   * @param {TeamStructureUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  teamStructureUxButtonClicked,
  /**
   * A file is uploaded with success after user clicks on "Upload a CSV file" inside Team Structure page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  teamStructureUploaded,
};
export default new Proxy(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method];
    }
    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1sLaHQgU12daYzLv3kSiK4HPkrn`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
