import React from 'react';
import { IconOptions } from '../../IconSelectInput';
import Styles from './IconButton.styles';

const IconButton = ({
  icon,
  name,
  active,
  onClick,
}: {
  icon: React.ReactNode;
  name:
    | 'package'
    | 'calendar'
    | 'gift'
    | 'currency'
    | 'shield'
    | 'zap'
    | 'store'
    | 'globe'
    | 'barcode'
    | 'starUnfilled';
  active: boolean;
  onClick(val: keyof IconOptions): void;
}): JSX.Element => {
  const classes = Styles({ active });
  return (
    <button
      type="button"
      onClick={() => {
        onClick(name);
      }}
      className={classes.iconContainer}
    >
      {icon}
    </button>
  );
};

export default IconButton;
