import { createStore } from 'effector';

import {
  updateServicesScriptEffect,
  getServicesScriptEffect,
  servicesScriptError400,
  setScript,
} from './ServicesScriptEvents';
import { ServicesScriptState } from './ServicesScriptState';

const initialState: ServicesScriptState = {
  loading: false,
  done: false,
  error: false,
  script: '',
};

const ServicesScriptStore = createStore(initialState)
  .on(servicesScriptError400, (state: ServicesScriptState) => {
    return { ...state, loading: false, done: true, error: false };
  })
  .on(updateServicesScriptEffect.done, (state: ServicesScriptState, { result }) => {
    return {
      ...state,
      ...result,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(updateServicesScriptEffect.fail, (state: ServicesScriptState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(updateServicesScriptEffect, (state: ServicesScriptState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(getServicesScriptEffect.done, (state: ServicesScriptState, { result }) => {
    return {
      ...state,
      ...result,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(getServicesScriptEffect.fail, (state: ServicesScriptState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(setScript, (state: ServicesScriptState, script: string) => {
    return {
      ...state,
      script,
    };
  })
  .on(getServicesScriptEffect, (state: ServicesScriptState) => {
    return { ...state, loading: true, done: false, error: false };
  });

export default ServicesScriptStore;
