import { deleteServicesCatalog } from '../../../services/servicesMission/deleteServicesCatalog/deleteServicesCatalog';
import { servicesCatalogError400 } from './ServicesCatalogEvents';

export function execute(id: string, vendorId?: string): Promise<any> {
  return deleteServicesCatalog(id, vendorId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;

      if (code === 400) {
        servicesCatalogError400();
      }

      throw error;
    });
}
