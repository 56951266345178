import React from 'react';

function ToplineMissionsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <defs>
        <linearGradient id="3b3xt4onvc" x1="50%" x2="50%" y1="0%" y2="174.916%">
          <stop offset="0%" stopColor="#9CFFFD" />
          <stop offset="100%" stopColor="#7CE5E3" />
        </linearGradient>
        <circle id="pdd4qye8wa" cx="21.103" cy="21.103" r="21.103" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.406 1.406)">
          <mask id="5jh4guxrlb" fill="#fff">
            <use xlinkHref="#pdd4qye8wa" />
          </mask>
          <g fill="url(#3b3xt4onvc)" mask="url(#5jh4guxrlb)">
            <path d="M0 0h42.207v42.207H0z" />
          </g>
        </g>
        <g fillRule="nonzero">
          <path
            fill="#FFEBD0"
            d="M34.723 8.133V28.47l-8.23 15.432H9.716a1.187 1.187 0 01-1.187-1.187V14.068a2.554 2.554 0 01-1.187-2.157V8.133a2.374 2.374 0 00-2.375-2.374h-.033a1.188 1.188 0 01-1.186-1.245c.03-.638.58-1.13 1.22-1.13h25.007a4.748 4.748 0 014.748 4.749z"
          />
          <path
            fill="#FFD7A0"
            d="M34.723 8.133V28.47l-8.23 15.432h-4.828V3.385h8.31a4.748 4.748 0 014.748 4.748z"
          />
          <path
            fill="#FFCA80"
            d="M8.529 15.255H2.593a1.187 1.187 0 01-1.187-1.187V6.946a3.561 3.561 0 117.123 0v8.31z"
          />
          <path
            fill="#34C2BF"
            d="M25.227 18.975a3.567 3.567 0 01-2.374 3.357v1.391a1.187 1.187 0 01-2.375 0V22.33a3.577 3.577 0 01-1.897-1.574 1.186 1.186 0 112.056-1.188 1.189 1.189 0 002.216-.593c0-.655-.533-1.187-1.188-1.187-.13-.004-.27.013-.45-.029a3.567 3.567 0 01-3.11-3.532c0-1.548.992-2.868 2.373-3.357V9.478a1.187 1.187 0 012.375 0v1.393c.787.28 1.467.832 1.896 1.574a1.186 1.186 0 11-2.055 1.189 1.189 1.189 0 00-2.216.593c0 .654.533 1.187 1.187 1.187h.258c.12 0 .236.018.345.051a3.566 3.566 0 012.959 3.51z"
          />
          <path
            fill="#007A78"
            d="M25.227 18.975a3.567 3.567 0 01-2.374 3.357v1.391c0 .655-.532 1.187-1.188 1.187v-4.748a1.19 1.19 0 001.188-1.187c0-.655-.533-1.187-1.188-1.187v-2.374h.258c.12 0 .236.018.345.051a3.566 3.566 0 012.959 3.51zm-3.562-5.935V8.29c.656 0 1.188.532 1.188 1.187v1.393c.787.28 1.467.832 1.896 1.574a1.186 1.186 0 11-2.055 1.189 1.193 1.193 0 00-1.029-.595z"
          />
          <path
            fill="#34C2BF"
            d="M20.478 29.658h-7.122a1.187 1.187 0 010-2.374h7.122a1.187 1.187 0 010 2.374zm0 4.749h-7.122a1.187 1.187 0 010-2.375h7.122a1.187 1.187 0 010 2.375zm0 4.748h-7.122a1.187 1.187 0 010-2.374h7.122a1.187 1.187 0 010 2.374z"
          />
          <path
            fill="#FFB64C"
            d="M40.738 27.284h-3.562c-.655 0-1.187.532-1.187 1.187v4.749h-4.748v-4.75c0-.655-.532-1.187-1.187-1.187h-3.561c-.655 0-1.187.532-1.187 1.187v14.245c0 .655.532 1.187 1.187 1.187h14.245c.655 0 1.187-.532 1.187-1.187V28.471c0-.655-.532-1.187-1.187-1.187z"
          />
          <path
            fill="#FF9100"
            d="M41.925 28.471v14.245c0 .655-.532 1.187-1.187 1.187h-7.123V33.22h2.374v-4.75c0-.655.532-1.187 1.187-1.187h3.562c.655 0 1.187.532 1.187 1.187z"
          />
          <path
            fill="#FF0"
            d="M37.176 27.284v5.936c0 .655-.531 1.187-1.187 1.187h-4.748a1.187 1.187 0 01-1.187-1.187v-5.936h7.122z"
          />
          <path
            fill="#D8D81E"
            d="M37.176 27.284v5.936c0 .655-.531 1.187-1.187 1.187h-2.374v-7.123h3.561z"
          />
        </g>
      </g>
    </svg>
  );
}

export default ToplineMissionsIcon;
