import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@material-ui/core/ListItem';
import { isFeatureEnabled } from 'grow-shared-services';
import CatalogItemComponent from '../../CatalogItem/CatalogItem';
import DraggableListItemStyles from './DraggableListItem.styles';
import { CatalogItem } from '../../../../../stores/servicesMission/ServiceScript/ServicesCatalogState';
import {
  setItemAvailability,
  updateServicesCatalogEffect,
} from '../../../../../stores/servicesMission/ServiceScript/ServicesCatalogEvents';
import { useAnalytics } from '../../../../../Analytics/useAnalytics';
import { serviceStatusChanged } from '../../../../../config/typewriter';
import CustomToast, { TOAST_TYPES } from '../../../../../components/CustomToast/CustomToast';
import { formatMessage } from '../../../../../i18n/formatters';
import { TLP_SERVICE_MISSIONS_DRAGGABLE } from '../../../../../config/featureToggles';
import { getUserInfos } from '../../../../../config/utils/functions';

interface DraggableListItemProps {
  catalogItem: CatalogItem;
  index: number;
  handleEdit: ({ id }: CatalogItem) => void;
  handleDelete: (item: CatalogItem) => void;
}

const DraggableListItem = ({
  catalogItem,
  index,
  handleDelete,
  handleEdit,
}: DraggableListItemProps): JSX.Element => {
  const classes = DraggableListItemStyles();
  const { dispatchGenericEvent } = useAnalytics();

  const isMissionDraggableEnabled = isFeatureEnabled(TLP_SERVICE_MISSIONS_DRAGGABLE);

  const { vendorId } = getUserInfos();

  const isDraggableDisabled = !isMissionDraggableEnabled ? true : !catalogItem.availability;

  const handleActive = (item: CatalogItem) => {
    const catalogIem = { ...item, availability: !item.availability };
    setItemAvailability(catalogIem);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: omit, ...res } = catalogIem;
    const request = {
      id: catalogIem.id,
      body: res,
      vendorId,
    };

    updateServicesCatalogEffect(request);
    dispatchGenericEvent(serviceStatusChanged, {
      service_name: catalogIem.title,
      is_active: catalogIem.availability,
    });

    const unWatchupdateServicesScriptDone = updateServicesCatalogEffect.done.watch(() => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_OK' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchupdateServicesScriptDone === 'function') {
        unWatchupdateServicesScriptDone();
      }
    });

    const unWatchupdateServicesScriptFail = updateServicesCatalogEffect.fail.watch(() => {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_ERROR' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchupdateServicesScriptFail === 'function') {
        unWatchupdateServicesScriptFail();
      }
    });
  };

  return (
    <Draggable
      draggableId={catalogItem.id}
      index={index}
      isDragDisabled={isDraggableDisabled}
      data-testid={isDraggableDisabled ? 'not-draggable-list-item' : 'draggable-list-item'}
    >
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : classes.listItem}
          data-testid="list-item"
        >
          <CatalogItemComponent
            {...catalogItem}
            data-testid="service-list-item"
            onEdit={() => handleEdit(catalogItem)}
            isDraggable={!isDraggableDisabled}
            setIsActive={() => {
              handleActive(catalogItem);
            }}
            onDelete={() => {
              handleDelete(catalogItem);
            }}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
