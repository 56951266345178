/* istanbul ignore file */
import { useAuthenticationService } from 'admin-portal-shared-services';
import { v4 as guid } from 'uuid';
import { UserConfig, useUserConfigService } from 'grow-shared-services';
import { LOCAL_STORAGE_PORTAL_CONFIG } from '../constants';
import Barcode from '../../assets/icons/services/Barcode';
import Calendar from '../../assets/icons/services/Calendar';
import Currency from '../../assets/icons/services/Currency';
import Gift from '../../assets/icons/services/Gift';
import Globe from '../../assets/icons/services/Globe';
import Package from '../../assets/icons/services/Package';
import Shield from '../../assets/icons/services/Shield';
import StarUnfilled from '../../assets/icons/services/StarUnfilled';
import Store from '../../assets/icons/services/Store';
import Zap from '../../assets/icons/services/Zap';
import { Mission, MissionsByStatus } from '../../domains/Missions';

export const getRequestTraceId = (): string => {
  return guid();
};

export const getTokenLocalStorage = (): string => {
  return useAuthenticationService().getAuthHeader();
};

type MissionsName = 'enabled' | 'disabled';

export const handleControlMissionsEnabled = (
  missions: MissionsByStatus,
  oldMissionList: MissionsName,
  newMissionsList: MissionsName,
  id: string,
): {
  [x: string]: Mission[];
} => {
  const oldList = [...missions[oldMissionList as keyof typeof missions]];

  const index = oldList.findIndex((each) => (each.missionId || each.id) === id);
  const mission = { ...oldList[index], enabled: !oldList[index].enabled };
  oldList.splice(index, 1);
  const newList = [...missions[newMissionsList as keyof typeof missions], mission];

  return {
    [oldMissionList]: oldList,
    [newMissionsList]: newList,
  };
};

export const countCharactersString = (value: string): number => {
  if (value) {
    return value.length;
  }

  return 0;
};

export const getIcons = (
  key: string,
  dimensions: { width: number; height: number },
): JSX.Element => {
  switch (key) {
    case 'barcode':
      return Barcode(dimensions);

    case 'package':
      return Package(dimensions);

    case 'calendar':
      return Calendar(dimensions);

    case 'gift':
      return Gift(dimensions);

    case 'currency':
      return Currency(dimensions);

    case 'shield':
      return Shield(dimensions);

    case 'zap':
      return Zap(dimensions);

    case 'store':
      return Store(dimensions);

    case 'globe':
      return Globe(dimensions);

    case 'starUnfilled':
      return StarUnfilled(dimensions);

    default:
      return undefined;
  }
};

export const removeWhiteSpaceAndLineBreaks = (text: string): string => {
  return text.replace(/[\s\r\n]+/gm, ' ');
};

export const getPortalConfig = (): Record<string, string> => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);

  if (!portalLocalStorage) return undefined;

  return JSON.parse(portalLocalStorage);
};

export const conditionalClass = (condition: boolean, class1: string, class2 = ''): string =>
  condition ? class1 : class2;

export const getEuropeClusterOrUrlParam = (url: string): string => {
  const { country } = getUserInfos();
  const europeCountries = localStorage.getItem('europeCountries');

  if (europeCountries?.includes(country)) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};

const removeUrlPrefix = (url: string) => {
  return url.replace('/api/v1', '');
};

export const getUserInfos = (): UserConfig => {
  const { data } = useUserConfigService().getStoreValue();

  return { ...data };
};
