import { colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const appColors = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    light: '#D8D8D8',
    main: '#C0C3C8',
    dark: '#707372',
    secondary: '#f8f8f8',
    opacity: 'rgba(0,0,0,0.55)',
  },
  green: '#1F8E4A',
};

const palette: PaletteOptions = {
  type: 'light',
  primary: {
    main: appColors.black,
    dark: '#505050',
  },
  secondary: {
    main: '#505050',
  },
  info: {
    main: '#4D6EDB',
    light: '#F0ECFC',
    dark: '#212121',
  },
  error: {
    dark: '#E02B27',
    main: '#d00505',
    light: '#FBE9E9',
  },
  text: {
    primary: appColors.black,
    secondary: '#505050',
    disabled: appColors.gray.dark,
  },
  background: {
    default: '#FAFAFA',
    paper: appColors.gray.secondary,
  },
  grey: {
    50: '#fbf9ff',
    100: '#eaeaea',
  },
  action: {
    active: appColors.black,
  },
  common: {
    white: appColors.white,
    black: appColors.black,
  },
  divider: appColors.gray.main,
  success: {
    dark: '#006400',
    main: colors.green[600],
    light: '#E6F2EB',
  },
};

export default palette;
