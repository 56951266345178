import { GROW_ORGANIZATION_ACTIVATION } from '@config/featureToggles';
import { isFeatureEnabled } from 'grow-shared-services';

import { CustomMissionById, CustomMissionByIdProps } from '../../../domains/CustomMission';
import { getSponsoredMissionById } from '../../../services/customMissions/sponsoredMission/getSponsoredMissionById/GetSponsoredMissionById';
import { getSponsoredMissionByIdV3 } from '../../../services/customMissions/sponsoredMission/getSponsoredMissionByIdV3/GetSponsoredMissionById';

export function execute({
  missionId,
  vendorId = null,
}: CustomMissionByIdProps): Promise<CustomMissionById> {
  const isGrowOrganizationActivationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isGrowOrganizationActivationEnabled) {
    return getSponsoredMissionByIdV3(missionId);
  }

  return getSponsoredMissionById(missionId, vendorId);
}
