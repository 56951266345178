import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Globe = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="globe-path-1"
          d="M26.4 0c14.58 0 26.4 11.82 26.4 26.4 0 14.58-11.82 26.4-26.4 26.4C11.82 52.8 0 40.98 0 26.4 0 11.82 11.82 0 26.4 0zM14.526 28.8H4.932c.991 8.968 7.473 16.282 16.01 18.505a39.194 39.194 0 01-6.416-18.506zm33.342 0H38.28a39.122 39.122 0 01-6.421 18.504c8.536-2.222 15.018-9.536 16.01-18.504zm-14.4 0h-14.13a34.321 34.321 0 006.805 17.543l.257.333.257-.333a34.287 34.287 0 006.812-17.544zM20.942 5.495l-.063.016C12.373 7.755 5.92 15.054 4.932 24h9.588a39.122 39.122 0 016.421-18.503zm5.459.627l-.257.334A34.287 34.287 0 0019.33 24h14.13a34.321 34.321 0 00-6.804-17.543l-.257-.334zm5.458-.628l.06.092A39.196 39.196 0 0138.273 24h9.595c-.991-8.969-7.474-16.282-16.01-18.505z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(5.6 5.6)">
            <mask id="globe-mask-2" fill="#fff">
              <use xlinkHref="#globe-path-1" />
            </mask>
            <g fill="#000" fillOpacity="0.9" mask="url(#globe-mask-2)">
              <path d="M0 0H64V64H0z" transform="translate(-5.6 -5.6)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Globe.defaultProps = {
  width: 24,
  height: 24,
};

export default Globe;
