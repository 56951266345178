import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const EditIcon = ({ width, height, color }: Props): JSX.Element => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="edit-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M41.9577088,9.56321733 C44.1683513,7.35257476 47.390433,6.48922054 50.4102269,7.29837185 C53.4300208,8.10752316 55.7887483,10.4662507 56.5978996,13.4860445 C57.4070509,16.5058384 56.5436967,19.7279201 54.3330541,21.9385626 L54.3330541,21.9385626 L23.4759113,52.7957054 C23.1946413,53.0769755 22.8448382,53.2799741 22.4610784,53.3846358 L22.4610784,53.3846358 L9.88964986,56.8132073 C8.18336662,57.2785572 6.61771423,55.7129048 7.0830642,54.0066216 L7.0830642,54.0066216 L10.5116356,41.435193 C10.6162974,41.0514333 10.819296,40.7016302 11.100566,40.4203602 L11.100566,40.4203602 Z M48.8648193,11.633973 C47.531705,11.4007787 46.1580006,11.8279018 45.190197,12.7957054 L45.190197,12.7957054 L14.7602386,43.2251757 L12.5430958,51.3508899 L20.6688101,49.1337471 L51.100566,18.7060744 C52.0683696,17.7382708 52.4954928,16.3645664 52.2622985,15.0314521 L52.2622985,15.0314521 L52.1822387,14.6692173 C51.7957943,13.2269872 50.6692843,12.1004772 49.2270542,11.7140328 L49.2270542,11.7140328 Z"
          id="Combined-Shape"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

EditIcon.defaultProps = {
  width: 16,
  height: 16,
  color: '#0363C4',
};

export default EditIcon;
