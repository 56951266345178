import { useLogService } from 'admin-portal-shared-services';
import { POC_FILE_TYPE } from '../../../../domains/PocDownloadCSVFile';
import { getApiHost } from '../../../host/HostService';
import axios from '../../../../config/axios/axiosInstance';

export async function getDownloadPocFile(
  missionId: string,
  type: POC_FILE_TYPE,
): Promise<ArrayBuffer> {
  const log = useLogService();

  const baseUrl = `${getApiHost()}/api/v1/tlp-account-service/v3/accounts-custom-mission/${missionId}/download/${type}`;

  return axios
    .get(`${baseUrl}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
