import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import EditServicesMissionRequest from '../../../stores/servicesMission/Services/domains/EditServicesMissionRequest';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import { Response } from '../domains/CreateServicesMission';

export async function editServiceMission(
  data: EditServicesMissionRequest,
  vendorId?: string,
): Promise<Response> {
  const isEditSponsoredV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const log = useLogService();
  const url = `${getApiHostGrowMissionsService()}/grow-missions/admin/`;

  let uri = '';

  if (isEditSponsoredV2Enabled) {
    uri = `v2/services/${data.id}?vendorId=${vendorId ?? ''}`;
  } else {
    uri = `services/${data.id}`;
  }

  const body = { ...data };
  delete body.id;

  return axios
    .put<Response>(`${url}${uri}`, body)
    .then((response) => {
      log.info(`${url} returned success`);
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
