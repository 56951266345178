import React from 'react';
import { TextareaAutosize, TextareaAutosizeProps, Typography } from '@material-ui/core';
import { AlertOctagon } from '@grow-shared-components/icons';
import { countCharactersString } from '../../config/utils/functions';
import TextareaStyles from './Textarea.styles';

interface Props extends TextareaAutosizeProps {
  value?: string;
  counter?: boolean;
  placeholder?: string;
  subTitle?: string;
  title?: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  textareaMaxLength?: number;
  error?: string;
}

const Textarea = ({
  value,
  counter,
  placeholder,
  subTitle,
  title,
  textareaMaxLength,
  handleChange,
  error,
  style,
  ...props
}: Props): JSX.Element => {
  const classes = TextareaStyles();

  const getPlaceholder = () => placeholder || '';

  return (
    <div className={classes.container}>
      <div className={classes.textareaContainer}>
        {title && (
          <Typography
            variant="h5"
            className={`${classes.title} ${error && classes.errorLabel}`}
            data-testid="services-textarea-title"
          >
            {title}
          </Typography>
        )}
        {error && (
          <Typography className={classes.errorMessage} data-testid="services-textarea-error">
            <AlertOctagon size="tiny" style={{ marginRight: '5px' }} /> {error}
          </Typography>
        )}
        {subTitle && (
          <Typography className={classes.subtitle} data-testid="services-textarea-subtitle">
            {subTitle}
          </Typography>
        )}
        <TextareaAutosize
          {...props}
          id="services-script-textarea"
          name="serviceScript"
          minRows={3}
          placeholder={getPlaceholder()}
          className={classes.textarea}
          value={value}
          onChange={handleChange}
          maxLength={textareaMaxLength}
          data-testid="customer-notes-textarea"
          style={{ ...style, border: error ? '1px solid red' : '' }}
        />
        <div className={classes.textareaInfos}>
          {counter && (
            <Typography
              component="span"
              data-testid="service-textarea-length"
              className={classes.counter}
            >
              {`${countCharactersString(value)}/${textareaMaxLength}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

Textarea.defaultProps = {
  counter: false,
  textareaMaxLength: 400,
};

export default Textarea;
