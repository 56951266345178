/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Input } from '@admin-portal-shared-components/input';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useStore } from 'effector-react';
import { getUserInfos } from '@config/utils/functions';
import { isFeatureEnabled } from 'grow-shared-services';
import Textarea from '../../../../components/Textarea/Textarea';
import ServicesMissionStore from '../../../../stores/servicesMission/Services/ServicesMissionStore';
import { useAnalytics } from '../../../../Analytics/useAnalytics';
import Button from '../../../../components/button/Button';
import CustomToast, { TOAST_TYPES } from '../../../../components/CustomToast/CustomToast';
import Label from '../../../../components/Label/Label';
import Switch from '../../../../components/Switch/Switch';
import {
  TLP_SERVICE_FORM_EXISTING_TITLE_CHECK,
  TLP_SERVICE_MISSIONS_PRICING_METHOD,
} from '../../../../config/featureToggles';
import { serviceAdded } from '../../../../config/typewriter';
import { formatMessage } from '../../../../i18n/formatters';
import CreateServicesMissionRequest from '../../../../stores/servicesMission/Services/domains/CreateServicesMissionRequest';
import EditServicesMissionRequest from '../../../../stores/servicesMission/Services/domains/EditServicesMissionRequest';
import {
  clearServicesMissionErrors,
  createServicesMissionEffect,
  editServicesMissionEffect,
  getServicesMissionEffect,
} from '../../../../stores/servicesMission/Services/ServicesMissionEvents';
import IconSelectInput from '../IconSelectInput/IconSelectInput';
import Styles from './ServiceForm.styles';

interface Props {
  onCancel?: () => void;
  onFinishSubmit?: () => void;
  serviceId?: string;
}

const formInitialValues: {
  icon: string;
  title?: string;
  availability?: boolean;
  description?: string;
  pricingMethod?: string;
  presetValue?: number;
} = {
  icon: 'package',
  title: '',
  availability: true,
  description: '',
  pricingMethod: 'PRESET',
  presetValue: null,
};

const ServiceForm = ({ onCancel, onFinishSubmit, serviceId }: Props): JSX.Element => {
  const { vendorId } = getUserInfos();
  const isEdit = !!serviceId;
  const { dispatchGenericEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState(!!serviceId);
  const servicesStore = useStore(ServicesMissionStore);

  const isPrincingMethodEnabled = isFeatureEnabled(TLP_SERVICE_MISSIONS_PRICING_METHOD);

  const isExistingTitleCheckEnabled = isFeatureEnabled(TLP_SERVICE_FORM_EXISTING_TITLE_CHECK);

  /* istanbul ignore next */
  const pricingMethodValidation = isPrincingMethodEnabled
    ? {
        pricingMethod: yup.string(),
        presetValue: yup
          .number()
          .nullable()
          .when('pricingMethod', {
            is: 'PRESET',
            then: yup
              .number()
              .transform((curr, orig) => (orig === '' ? null : curr))
              .nullable()
              .required(formatMessage({ id: 'SERVICES_MISSION_FORM.REQUIRED_FIELD_MESSAGE' }))
              .moreThan(
                0,
                formatMessage({ id: 'SERVICES_MISSION_FORM.GREATER_THAN_ZERO_FIELD_MESSAGE' }),
              ),
          }),
      }
    : {};

  const classes = Styles();
  const validationSchema = yup.object({
    title: yup
      .string()
      .required(formatMessage({ id: 'SERVICES_MISSION_FORM.REQUIRED_FIELD_MESSAGE' })),
    description: yup
      .string()
      .required(formatMessage({ id: 'SERVICES_MISSION_FORM.REQUIRED_FIELD_MESSAGE' })),
    ...pricingMethodValidation,
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formInitialValues,
  });

  const iconValue = watch('icon');
  const pricingMethod = watch('pricingMethod');

  const princingMethods = [
    {
      label: formatMessage({ id: 'SERVICES_MISSION_FORM.PRICING_METHOD_PRESET' }),
      value: 'PRESET',
    },
    {
      label: formatMessage({ id: 'SERVICES_MISSION_FORM.PRICING_METHOD_VARIABLE' }),
      value: 'VARIABLE',
    },
  ];
  const finishSubmitIfExists = () => {
    if (onFinishSubmit) onFinishSubmit();
  };
  const unsubscribe = (unWatchEffect: () => void) => {
    if (typeof unWatchEffect === 'function') {
      unWatchEffect();
    }
  };

  /* istanbul ignore next */
  const onSubmit = (data: CreateServicesMissionRequest | EditServicesMissionRequest) => {
    let successMessage = formatMessage({ id: 'SERVICES_MISSION_FORM.SUBMIT_SUCCESS' });
    let errorMessage = formatMessage({ id: 'SERVICES_MISSION_FORM.SUBMIT_ERROR' });

    if (isEdit) {
      successMessage = formatMessage({ id: 'SERVICES_MISSION_FORM.EDIT_SUBMIT_SUCCESS' });
      errorMessage = formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_ERROR' });
      editServicesMissionEffect({
        serviceMission: data as EditServicesMissionRequest,
        vendorId,
      });
      const unWatchcreateServicesMissionDone = editServicesMissionEffect.done.watch(() => {
        CustomToast({
          type: TOAST_TYPES.SUCCESS,
          message: successMessage,
        });

        dispatchGenericEvent(serviceAdded, {
          service_name: data.title,
        });

        unsubscribe(unWatchcreateServicesMissionDone);
        finishSubmitIfExists();
      });

      const unWatchcreateServicesMissionFail = editServicesMissionEffect.fail.watch(() => {
        unsubscribe(unWatchcreateServicesMissionFail);

        if (isExistingTitleCheckEnabled) {
          const titleError = ServicesMissionStore.getState().errors.find(
            (err) => err.message === "There's already a service with this title, try another one.",
          );
          if (!titleError) {
            CustomToast({
              type: TOAST_TYPES.ERROR,
              message: errorMessage,
            });
          }
        } else {
          finishSubmitIfExists();
          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: errorMessage,
          });
        }
      });
      return;
    }

    createServicesMissionEffect({
      ...data,
      position: -1,
    } as CreateServicesMissionRequest);
    const unWatchcreateServicesMissionDone = createServicesMissionEffect.done.watch(() => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: successMessage,
      });

      dispatchGenericEvent(serviceAdded, {
        service_name: data.title,
      });

      unsubscribe(unWatchcreateServicesMissionDone);
      finishSubmitIfExists();
    });

    const unWatchcreateServicesMissionFail = createServicesMissionEffect.fail.watch(() => {
      unsubscribe(unWatchcreateServicesMissionFail);
      if (isExistingTitleCheckEnabled) {
        const titleError = ServicesMissionStore.getState().errors.find(
          (err) => err.message === "There's already a service with this title, try another one.",
        );
        if (!titleError) {
          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: errorMessage,
          });
        }
      } else {
        finishSubmitIfExists();
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: errorMessage,
        });
      }
    });
  };

  React.useEffect(() => {
    /* istanbul ignore else */
    if (isExistingTitleCheckEnabled) {
      servicesStore.errors.forEach((err) => {
        if (err.message === "There's already a service with this title, try another one.") {
          setError('title', {
            message: formatMessage({ id: 'SERVICES_MISSION_FORM.EXISTING_TITLE_ERROR_MESSAGE' }),
          });
        }
      });
    }
  }, [servicesStore]);

  React.useEffect(() => {
    getServicesMissionEffect.done.watch((params) => {
      reset(params.result);
      setIsLoading(false);
      clearErrors();
    });
  }, []);

  React.useEffect(() => {
    if (serviceId) {
      getServicesMissionEffect(serviceId);
    }
  }, [serviceId]);

  if (isLoading)
    return (
      <div data-testid="loading-view" className={classes.formWrapper}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Box my={2}>
          <Skeleton variant="rect" height={200} />
        </Box>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Box my={2}>
          <Skeleton variant="rect" height={200} />
        </Box>
      </div>
    );

  return (
    <form
      className={classes.formWrapper}
      onSubmit={handleSubmit(
        /* istanbul ignore next */ (data) => {
          onSubmit(data as CreateServicesMissionRequest);
        },
      )}
      data-testid="services-form-body"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <IconSelectInput
            onChange={
              /* istanbul ignore next */ (val) => {
                setValue('icon', val);
              }
            }
            value={iconValue}
          />
        </Grid>
        <Grid item>
          <Label data-testid="input-missions-title-label" error={!!errors?.title}>
            {formatMessage({ id: 'SERVICES_MISSION_FORM.TITLE_LABEL' })}
          </Label>
          <Controller
            name="title"
            control={control}
            data-testid="controller-input-missions-title"
            render={
              /* istanbul ignore next */ ({ field: { onChange, value } }) => (
                <Input
                  placeholder={formatMessage({
                    id: 'SERVICES_MISSION_FORM.TITLE_PLACEHOLDER',
                  })}
                  optionalText=""
                  width="100%"
                  height="48px"
                  data-testid="input-missions-title"
                  maxLength={68}
                  hasError={errors.title !== undefined}
                  errorText={errors?.title?.message.toString()}
                  value={value}
                  onChange={onChange}
                />
              )
            }
          />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Box className={classes.availabilityLabelWrapper}>
            <Label
              data-testid="input-missions-availability-label"
              subLabel={formatMessage({
                id: 'SERVICES_MISSION_FORM.AVAILABILITY_PLACEHOLDER',
              })}
            >
              {formatMessage({ id: 'SERVICES_MISSION_FORM.AVAILABILITY_LABEL' })}
            </Label>
          </Box>
          <Controller
            data-testid="input-missions-availability"
            name="availability"
            control={control}
            render={
              /* istanbul ignore next */ ({ field }) => (
                <Switch onChange={field.onChange} checked={field.value} />
              )
            }
          />
        </Grid>

        {isPrincingMethodEnabled && (
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.princingMethodWrapper}
          >
            <Box className={classes.availabilityLabelWrapper}>
              <Label
                data-testid="label-radio-missions-type"
                subLabel={formatMessage({
                  id: 'SERVICES_MISSION_FORM.PRICING_METHOD_SUB_LABEL',
                })}
              >
                {formatMessage({ id: 'SERVICES_MISSION_FORM.PRICING_METHOD_LABEL' })}
              </Label>
              <Controller
                name="pricingMethod"
                control={control}
                data-testid="controller-radio-missions-type"
                render={
                  /* istanbul ignore next */ ({ field: { onChange, value } }) => (
                    <FormControl onChange={(type) => onChange(type)}>
                      <RadioGroup
                        value={value}
                        name="mission-type-radio-buttons-group"
                        className={classes.princingCheckboxContainer}
                      >
                        {princingMethods.map((method) => {
                          return (
                            <FormControlLabel
                              key={method.toString()}
                              value={method.value}
                              control={<Radio style={{ margin: '0px 0px 0px -4px' }} />}
                              label={
                                <span style={{ fontFamily: 'Work Sans' }}>{method.label}</span>
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )
                }
              />
            </Box>
            {pricingMethod === 'PRESET' && (
              <Box className={classes.availabilityLabelWrapper}>
                <Label
                  data-testid="label-missions-pricing-preset-value"
                  subLabel={formatMessage({
                    id: 'SERVICES_MISSION_FORM.PRICING_METHOD_VALUE_SUB_LABEL',
                  })}
                  error={!!errors?.presetValue}
                >
                  {formatMessage({ id: 'SERVICES_MISSION_FORM.PRICING_METHOD_VALUE_LABEL' })}
                </Label>
                <Controller
                  name="presetValue"
                  control={control}
                  data-testid="controller-input-missions-pricing-preset-value"
                  render={
                    /* istanbul ignore next */ ({ field: { onChange, value } }) => (
                      <div className={classes.princingValueWrapper}>
                        <Input
                          placeholder="0"
                          optionalText=""
                          width="100%"
                          height="48px"
                          data-testid="input-missions-pricing-preset-value"
                          maxLength={9}
                          type="numeric"
                          hasError={errors.presetValue !== undefined}
                          errorText={errors?.presetValue?.message.toString()}
                          value={value}
                          onChange={onChange}
                          onKeyDown={(e) => {
                            const validChars = [
                              '1',
                              '2',
                              '3',
                              '4',
                              '5',
                              '6',
                              '7',
                              '8',
                              '9',
                              '0',
                              'Backspace',
                              'ArrowRight',
                              'ArrowLeft',
                            ];
                            if (!validChars.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <div className={classes.princingValueSufix}>
                          <span>
                            {formatMessage({
                              id: 'SERVICES_MISSION_FORM.PRICING_METHOD_VALUE_SUFIX',
                            })}
                          </span>
                        </div>
                      </div>
                    )
                  }
                />
              </Box>
            )}
          </Grid>
        )}

        <Grid item>
          <Controller
            name="description"
            control={control}
            data-testid="controller-input-missions-description"
            render={
              /* istanbul ignore next */ ({ field: { onChange, value } }) => (
                <Textarea
                  data-testid="services-script-textarea"
                  counter
                  style={{
                    height: '152px',
                    overflow: 'auto',
                    resize: !isExistingTitleCheckEnabled ? 'vertical' : 'none',
                  }}
                  textareaMaxLength={300}
                  handleChange={onChange}
                  value={value}
                  placeholder={formatMessage({
                    id: 'SERVICES_MISSION_FORM.DESCRIPTION_PLACEHOLDER',
                  })}
                  title={formatMessage({ id: 'SERVICES_MISSION_FORM.DESCRIPTION_LABEL' })}
                  error={errors.description?.message}
                />
              )
            }
          />
        </Grid>
      </Grid>
      <section className={classes.modalButtonsContainer}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          onClick={() => {
            onCancel();
            clearServicesMissionErrors();
          }}
          data-testid="add-service-cancel-button"
        >
          {formatMessage({ id: 'SERVICES_MISSION.BTN_CANCEL' })}
        </Button>
        <Button
          type="submit"
          className={classes.applyButton}
          data-testid="add-service-apply-button"
        >
          {isEdit
            ? formatMessage({ id: 'SERVICES_MISSION.BTN_TITLE' })
            : formatMessage({ id: 'SERVICES_MISSION_FORM.TITLE' })}
        </Button>
      </section>
    </form>
  );
};

export default ServiceForm;
