import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette }) =>
  createStyles({
    modal: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px;',
      maxWidth: '395px',
    },
    actionsModal: {
      display: 'flex',
      justifyContent: 'end',
      columnGap: '16px;',
    },
    titleModal: {
      fontSize: '32px',
      fontWeight: 500,
      color: palette.info.dark,
    },
    btnDanger: {
      backgroundColor: palette.error.main,
      '&:hover': {
        color: `${palette.common.white} !important`,
      },
    },
  }),
);
