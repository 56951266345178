import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../../config/axios/axiosInstance';
import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '../../../../config/featureToggles';
import { CustomMissionById } from '../../../../domains/CustomMission';
import { getApiHostGrowMissionsService } from '../../../host/HostService';

export async function getSponsoredMissionById(
  missionId: string,
  vendorId?: string,
): Promise<CustomMissionById> {
  const isGetSponsoredMissionByIdV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const baseUrl = `${getApiHostGrowMissionsService()}/grow-missions/admin`;
  let uri = '';

  if (isGetSponsoredMissionByIdV2Enabled) {
    uri = `/v2/${missionId}/search-mission?vendorId=${vendorId ?? ''}`;
  } else {
    uri = `/${missionId}/search-mission`;
  }

  const log = useLogService();

  return axios
    .get<CustomMissionById>(`${baseUrl}${uri}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
