import React, { useEffect, useState, ReactElement } from 'react';
import { ButtonDefault } from '@admin-portal-shared-components/button-default';
import { useStore } from 'effector-react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { getUserInfos } from '@config/utils/functions';
import { isFeatureEnabled } from 'grow-shared-services';
import { servicesChangesSaved, servicesUxButtonClicked } from '../../config/typewriter';
import CustomToast, { TOAST_TYPES } from '../../components/CustomToast/CustomToast';
import { formatMessage } from '../../i18n/formatters';
import useServicesMissionsStyles from './servicesMission.styles';
import Textarea from '../../components/Textarea/Textarea';
import { BASE_NAME } from '../../config/constants';
import {
  updateServicesScriptEffect,
  getServicesScriptEffect,
  setScript,
} from '../../stores/servicesMission/ServiceScript/ServicesScriptEvents';
import {
  getServicesCatalogEffect,
  deleteServicesCatalogEffect,
  updateServicesCatalogPositionEffect,
  deleteCatalogItem,
  setNewOrder,
} from '../../stores/servicesMission/ServiceScript/ServicesCatalogEvents';
import Plus from '../../assets/icons/Plus';
import { clearServicesMissionErrors } from '../../stores/servicesMission/Services/ServicesMissionEvents';
import ReturnIcon from '../../assets/icons/ReturnIcon';
import { ConfirmationModal, ServiceForm } from './components';
import { TLP_SERVICE_MISSIONS_CATALOG } from '../../config/featureToggles';
import { useAnalytics } from '../../Analytics/useAnalytics';
import ServicesScriptStore from '../../stores/servicesMission/ServiceScript/ServicesScriptStore';
import ServicesCatalogStore from '../../stores/servicesMission/ServiceScript/ServicesCatalogStore';
import { CatalogItem } from '../../stores/servicesMission/ServiceScript/ServicesCatalogState';
import ServicesMissionGenericModal from './components/GenericModal/GenericModal';
import DraggableList from './components/DragAndDropComponents/DraggableList/DraggableList';

const ServicesMissions = (): ReactElement => {
  const classes = useServicesMissionsStyles();
  const history = useHistory();
  const { country } = getUserInfos();

  const serviceScriptStates = useStore(ServicesScriptStore);
  const { vendorId } = getUserInfos();

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isServiceFormModalOpen, setIsServiceFormModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const { dispatchGenericEvent } = useAnalytics();
  const { data } = useStore(ServicesCatalogStore);
  const [catalogItemSelected, setCatalogItemSelected] = useState<string>('');
  const [serviceToEdit, setServiceToEdit] = useState<string>(null);

  const isServicesMissionCatalogEnabled = isFeatureEnabled(TLP_SERVICE_MISSIONS_CATALOG);

  const handleReturn = () => {
    history.push(`${BASE_NAME}/management`);
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsChanged(true);
    setScript(event.target.value);
  };

  const handleSave = () => {
    setIsChanged(false);
    updateServicesScriptEffect({ script: serviceScriptStates.script, country });
    dispatchGenericEvent(servicesChangesSaved, {
      script: serviceScriptStates.script,
    });

    const unWatchupdateServicesScriptDone = updateServicesScriptEffect.done.watch(() => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_OK' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchupdateServicesScriptDone === 'function') {
        unWatchupdateServicesScriptDone();
      }
    });

    const unWatchupdateServicesScriptFail = updateServicesScriptEffect.fail.watch(() => {
      setIsChanged(true);
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_ERROR' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchupdateServicesScriptFail === 'function') {
        unWatchupdateServicesScriptFail();
      }
    });
  };

  useEffect(() => {
    getServicesScriptEffect({ country, vendorId });
    getServicesCatalogEffect(vendorId);
  }, [country, vendorId]);

  const handleClose = () => {
    setConfirmationModal(!confirmationModal);
    setCatalogItemSelected('');
  };

  const handleEdit = async ({ id }: CatalogItem) => {
    setServiceToEdit(id);
    setIsServiceFormModalOpen(true);
  };

  const handleConfirmationModal = (item: CatalogItem) => {
    setCatalogItemSelected(item.id);
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteServiceItem = () => {
    setConfirmationModal(!confirmationModal);
    deleteServicesCatalogEffect({ id: catalogItemSelected, vendorId });
    deleteCatalogItem(catalogItemSelected);
    setCatalogItemSelected('');

    const unWatchdeleteServicesItemDone = deleteServicesCatalogEffect.done.watch(() => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_DELETE_OK' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchdeleteServicesItemDone === 'function') {
        unWatchdeleteServicesItemDone();
      }
    });

    const unWatchupdateServicesScriptFail = deleteServicesCatalogEffect.fail.watch(() => {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_DELETE_ERROR' }),
      });

      /* istanbul ignore next */
      if (typeof unWatchupdateServicesScriptFail === 'function') {
        unWatchupdateServicesScriptFail();
      }
    });
  };

  /* istanbul ignore next */
  const reorder = (list: CatalogItem[], startIndex: number, endIndex: number): CatalogItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const changePositionByOrder = (list: CatalogItem[]) => {
    return list.map((item, index) => ({ ...item, position: index }));
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    const newItems = forceOrderMissions(
      changePositionByOrder(reorder(data, source.index, destination.index)),
    );
    setNewOrder(newItems);

    const updateServiceCataloPosition: Array<{ id: string; position: number }> = newItems?.map(
      (item) => ({ id: item.id, position: item.position }),
    );
    updateServicesCatalogPositionEffect({
      request: { services: updateServiceCataloPosition },
      vendorId,
    });

    const unWatchUpdateServicesCatalogPositionDone = updateServicesCatalogPositionEffect.done.watch(
      () => {
        CustomToast({
          type: TOAST_TYPES.SUCCESS,
          message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_OK' }),
        });

        /* istanbul ignore next */
        if (typeof unWatchUpdateServicesCatalogPositionDone === 'function') {
          unWatchUpdateServicesCatalogPositionDone();
        }
      },
    );

    const unWatchUpdateServicesCatalogPositionFail = updateServicesCatalogPositionEffect.fail.watch(
      () => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MESSAGE_ERROR' }),
        });

        /* istanbul ignore next */
        if (typeof unWatchUpdateServicesCatalogPositionFail === 'function') {
          unWatchUpdateServicesCatalogPositionFail();
        }
      },
    );
  };

  const forceOrderMissions = (list: Array<CatalogItem>): CatalogItem[] => {
    return list.sort((a, b) => {
      if (a.availability && !b.availability) {
        return -1;
      }
      if (!a.availability && b.availability) {
        return 1;
      }

      return a.position - b.position;
    });
  };

  return (
    <main className={classes.wrapper}>
      <div className={classes.container}>
        <section className={classes.header} data-testid="services-missions-header-container">
          <div className={classes.returnContainer}>
            <IconButton
              onClick={handleReturn}
              className={classes.returnButton}
              data-testid="return-services-missions-button"
            >
              <ReturnIcon />
            </IconButton>

            <Typography
              variant="h2"
              className={classes.mainTitle}
              data-testid="services-missions-main-title"
            >
              {formatMessage({ id: 'SERVICES_MISSION.PAGE_TITLE' })}
            </Typography>
          </div>

          <ButtonDefault
            disabled={!isChanged || serviceScriptStates.script.length < 0}
            size="large"
            variant="primary"
            leading
            data-testid="service-save-scrip-button"
            onClick={handleSave}
          >
            {formatMessage({ id: 'SERVICES_MISSION.BTN_TITLE' })}
          </ButtonDefault>
        </section>

        <section className={classes.mainContent}>
          <div className={classes.scrollableContent}>
            <div className={classes.textareaContent}>
              <Textarea
                data-testid="services-script-textarea"
                counter
                style={{
                  maxHeight: '106px',
                  minHeight: '106px',
                  overflow: 'auto',
                  resize: 'none',
                }}
                handleChange={handleTextareaChange}
                value={serviceScriptStates.script}
                placeholder={formatMessage({ id: 'SERVICES_MISSION.TEXTAREA_PLACEHOLDER' })}
                subTitle={formatMessage({ id: 'SERVICES_MISSION.SUBTITLE' })}
                title={formatMessage({ id: 'SERVICES_MISSION.TITLE' })}
              />
            </div>

            {isServicesMissionCatalogEnabled && (
              <div
                className={classes.catalogManagementContent}
                data-testid="services-mission-catalog"
              >
                <div className={classes.catalogManagementHeader}>
                  <Typography className={classes.title} data-testid="services-textarea-title">
                    {formatMessage({ id: 'SERVICES_MISSION.SERVICES' })}
                  </Typography>
                  <ButtonDefault
                    size="large"
                    variant="primary"
                    icon={Plus}
                    leading
                    style={{ paddingLeft: '8px' }}
                    data-testid="new-service-catalog-management-button"
                    css={{
                      '& div': {
                        background: '#1AF07E',
                        fontSize: '1rem !important',
                      },
                      '&:hover': {
                        color: 'RGBA(255,255,255,0.95)',
                      },
                    }}
                    onClick={() => {
                      setIsServiceFormModalOpen(true);
                      dispatchGenericEvent(servicesUxButtonClicked, {
                        button_name: 'add-service',
                        button_label: 'Add service',
                        screen_section: 'Services Listing',
                      });
                    }}
                  >
                    {formatMessage({ id: 'SERVICES_MISSION.BTN_ADD_SERVICE' })}
                  </ButtonDefault>
                </div>

                <div className={classes.listContainer}>
                  <DraggableList
                    data-testid="services-list-services"
                    list={forceOrderMissions(data)}
                    onDragEnd={onDragEnd}
                    handleEdit={handleEdit}
                    handleDelete={handleConfirmationModal}
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <ServicesMissionGenericModal
          open={isServiceFormModalOpen}
          showCloseButton={false}
          onClose={() => {
            setIsServiceFormModalOpen(false);
            setServiceToEdit(null);
            clearServicesMissionErrors();
          }}
          title={
            serviceToEdit
              ? formatMessage({ id: 'SERVICES_MISSION_FORM.EDIT_TITLE' })
              : formatMessage({ id: 'SERVICES_MISSION_FORM.TITLE' })
          }
          testId="add-service-modal"
          containerClassname={classes.serviceModal}
        >
          <ServiceForm
            onCancel={() => {
              setIsServiceFormModalOpen(false);
              setServiceToEdit(null);
            }}
            onFinishSubmit={() => {
              setIsServiceFormModalOpen(false);
              setServiceToEdit(null);
              getServicesCatalogEffect(vendorId);
            }}
            data-testid="services-add-service-form"
            serviceId={serviceToEdit}
          />
        </ServicesMissionGenericModal>

        <ConfirmationModal
          data-testid="services-confirmation-modal-delete"
          onClose={handleClose}
          open={confirmationModal}
          onConfirm={handleDeleteServiceItem}
        />
      </div>
    </main>
  );
};

export default ServicesMissions;
