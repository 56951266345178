import React from 'react';

function Thrive(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="56px"
      height="56px"
      viewBox="0 0 56 01"
    >
      <defs>
        <path
          d="M11.6666667,10 C12.5213931,10 13.2258453,10.6434003 13.3221204,11.4722981 L13.3333333,11.6666667 L13.3333333,28.3333333 C13.3333333,29.2538079 12.5871412,30 11.6666667,30 C10.8119403,30 10.1074881,29.3565997 10.0112129,28.5277019 L10,28.3333333 L10,11.6666667 C10,10.7461921 10.7461921,10 11.6666667,10 Z M21.6666667,0 C22.5213931,0 23.2258453,0.643400317 23.3221204,1.47229812 L23.3333333,1.66666667 L23.3333333,28.3333333 C23.3333333,29.2538079 22.5871412,30 21.6666667,30 C20.8119403,30 20.1074881,29.3565997 20.0112129,28.5277019 L20,28.3333333 L20,1.66666667 C20,0.746192084 20.7461921,0 21.6666667,0 Z M1.66666667,20 C2.52139307,20 3.22584527,20.6434003 3.32212045,21.4722981 L3.33333333,21.6666667 L3.33333333,28.3333333 C3.33333333,29.2538079 2.58714125,30 1.66666667,30 C0.811940268,30 0.107488066,29.3565997 0.0112128855,28.5277019 L0,28.3333333 L0,21.6666667 C0,20.7461921 0.746192084,20 1.66666667,20 Z"
          id="path-1"
        />
      </defs>
      <g
        id="&lt;&lt;here&gt;&gt;-Missions-management-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Missions.-Custom-missions-MVP.-New-custom-mission.-Details"
          transform="translate(-400.000000, -338.000000)"
        >
          <g id="content" transform="translate(332.000000, 256.000000)">
            <g id="Type" transform="translate(32.000000, 32.000000)">
              <g id="Group-6" transform="translate(16.000000, 22.000000)">
                <g id="Group" transform="translate(20.000000, 0.000000)">
                  <circle id="Oval" fill="#FFFF00" cx="28" cy="28" r="28" />
                  <g
                    id="ZZ_Color-Override/Primary/Black"
                    transform="translate(16.125000, 13.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1" />
                    </mask>
                    <g id="Mask" fillRule="nonzero" />
                    <g
                      id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                      mask="url(#mask-2)"
                      fill="#000000"
                      fillOpacity="0.9"
                    >
                      <g transform="translate(-8.125000, -5.000000)" id="Rectangle">
                        <rect x="0" y="0" width="40" height="40" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Thrive;
