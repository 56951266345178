import { createStore } from 'effector';
import {
  getServicesCatalogEffect,
  servicesCatalogError400,
  setItemAvailability,
  deleteServicesCatalogEffect,
  deleteCatalogItem,
  updateServicesCatalogEffect,
  setNewOrder,
  updateServicesCatalogPositionEffect,
} from './ServicesCatalogEvents';
import { ServicesCatalogState, CatalogItem } from './ServicesCatalogState';

const initialState: ServicesCatalogState = {
  loading: false,
  done: false,
  error: false,
  data: [],
};

const ServicesCatalogStore = createStore(initialState)
  .on(getServicesCatalogEffect, (state: ServicesCatalogState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(servicesCatalogError400, (state: ServicesCatalogState) => {
    return { ...state, loading: false, done: true, error: false };
  })
  .on(getServicesCatalogEffect.done, (state: ServicesCatalogState, { result }) => {
    return {
      ...state,
      ...result,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(getServicesCatalogEffect.fail, (state: ServicesCatalogState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(deleteServicesCatalogEffect, (state: ServicesCatalogState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(deleteServicesCatalogEffect.done, (state: ServicesCatalogState) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(deleteServicesCatalogEffect.fail, (state: ServicesCatalogState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(setItemAvailability, (state: ServicesCatalogState, item: CatalogItem) => {
    const data = state.data.map((obj) => {
      if (obj.id === item.id) {
        return item;
      }
      return obj;
    });

    return {
      ...state,
      data,
    };
  })
  .on(setNewOrder, (state: ServicesCatalogState, obj: CatalogItem[]) => {
    return {
      ...state,
      data: obj,
    };
  })
  .on(deleteCatalogItem, (state: ServicesCatalogState, id: string) => {
    return {
      ...state,
      data: state.data.filter((obj) => obj.id !== id),
    };
  })
  .on(updateServicesCatalogEffect, (state: ServicesCatalogState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(updateServicesCatalogEffect.done, (state: ServicesCatalogState) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(updateServicesCatalogEffect.fail, (state: ServicesCatalogState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(updateServicesCatalogPositionEffect, (state: ServicesCatalogState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(updateServicesCatalogPositionEffect.done, (state: ServicesCatalogState) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(updateServicesCatalogPositionEffect.fail, (state: ServicesCatalogState) => {
    return { ...state, loading: false, done: true, error: true };
  });

export default ServicesCatalogStore;
