import { createIntl, createIntlCache, defineMessages, IntlShape } from 'react-intl';
import MessageMap from './domains/MessageMap';
import en from './languages/en';
import esDO from './languages/es-DO';
import ptBR from './languages/pt-BR';

interface FlatMessageMap {
  [key: string]: string;
}

const MESSAGES: { [language: string]: MessageMap } = {
  'en-US': en,
  'pt-BR': ptBR,
  'es-419': esDO,
};
export const DEFAULT_LANGUAGE = 'en-US';

const flattenMessages = (nestedMessages: MessageMap): FlatMessageMap =>
  Object.entries(nestedMessages).reduce(
    (messages, [messageKey, message]) =>
      Object.assign(
        messages,
        Object.fromEntries(
          Object.entries(message).map(([key, value]) => [`${messageKey}.${key}`, value]),
        ),
      ),
    {},
  );

const validateSelectedLanguage = (language: string) => {
  if (!MESSAGES[language]) {
    return DEFAULT_LANGUAGE;
  }
  return language;
};

const defaultMessageMap = flattenMessages(MESSAGES[DEFAULT_LANGUAGE]);

const fillFlattenedMessaged = (messageMap: FlatMessageMap): FlatMessageMap => ({
  ...defaultMessageMap,
  ...messageMap,
});

const intlProvider = (locale: string): IntlShape => {
  const cache = createIntlCache();
  const language = validateSelectedLanguage(locale);
  return createIntl(
    {
      locale: language,
      messages: defineMessages(fillFlattenedMessaged(flattenMessages(MESSAGES[language]))),
    },
    cache,
  );
};

export default intlProvider;
