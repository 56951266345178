import { createEffect, createEvent } from 'effector';
import { execute as GetServicesScriptUseCase } from './GetServicesScriptUseCase';
import { execute as UpdateServicesScriptUseCase } from './UpdateServicesScriptUseCase';
import UpdateServicesScriptRequest from '../../../services/servicesMission/domains/UpdateServicesScriptRequest';
import GetServiceScriptRequest from '../../../services/servicesMission/domains/GetServiceScriptRequest';

export const getServicesScriptEffect = createEffect({
  async handler(body: GetServiceScriptRequest) {
    return GetServicesScriptUseCase(body);
  },
});

export const servicesScriptError400 = createEvent('servicesScriptError400');

export const updateServicesScriptEffect = createEffect({
  async handler(body: UpdateServicesScriptRequest) {
    return UpdateServicesScriptUseCase(body);
  },
});

export const setScript = createEvent<string>('setScript');
