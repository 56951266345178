import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import Modal from '../../../../components/modal';
import GenericModalStyles from './GenericModal.styles';

interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  title: string;
  testId: string;
  showCloseButton?: boolean;
  children?: React.ReactChild;
  containerClassname?: string;
}

const ServicesMissionGenericModal = ({
  open,
  onClose,
  title,
  testId,
  showCloseButton = true,
  children,
  containerClassname,
}: Props): JSX.Element => {
  const classes = GenericModalStyles();

  const handleIconButtonClick = () => {
    onClose(false);
  };

  return (
    <Modal open={open} onClose={onClose} data-testid={`${testId}-container`}>
      <div className={containerClassname || classes.genericModalContainer}>
        <header className={classes.modalHeaderContainer}>
          <Typography
            className={classes.modalHeaderTitle}
            variant="h2"
            data-testid={`${testId}-title`}
          >
            {title}
          </Typography>
          {showCloseButton && (
            <IconButton
              className={classes.closeButton}
              onClick={handleIconButtonClick}
              data-testid={`${testId}-close-button`}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          )}
        </header>
        <section className={classes.modalDescriptionContainer}>{children}</section>
      </div>
    </Modal>
  );
};

export default ServicesMissionGenericModal;
