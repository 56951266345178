import React from 'react';

function Draggable(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#939191" fillRule="nonzero">
          <g>
            <g transform="matrix(1 0 0 -1 0 32)">
              <path d="M21.7 24.173l-4.367 4.37V20h13.334a1.333 1.333 0 000-2.667H1.333a1.333 1.333 0 000 2.667h13.334v8.562l-4.388-4.39a1.332 1.332 0 10-1.884 1.886l4.769 4.77a3.997 3.997 0 005.654 0l4.767-4.77a1.334 1.334 0 10-1.884-1.885zm0-16.346l-4.367-4.37V12h13.334a1.333 1.333 0 010 2.667H1.333a1.333 1.333 0 010-2.667h13.334V3.438l-4.388 4.39a1.332 1.332 0 11-1.884-1.886l4.769-4.77a3.997 3.997 0 015.654 0l4.767 4.77a1.334 1.334 0 11-1.884 1.885z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Draggable;
