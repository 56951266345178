import React from 'react';
import classNames from 'classnames';
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import ButtonStyles from './Button.styles';

export interface Props extends ButtonBaseProps {
  loading?: boolean;
  loadingProps?: CircularProgressProps; // it's possible to pass any prop of material CircularProgress using this prop
  variant?: 'contained' | 'outlined' | 'reset' | 'text';
  testId?: string;
  disabled?: boolean;
}

const Button: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  ({ loading, disabled, variant, className, loadingProps, testId, children, ...props }, ref) => {
    const classes = ButtonStyles();

    return (
      <ButtonBase
        disabled={loading || disabled}
        className={classNames(className, classes.rounded, {
          [classes.button]: !className,
          [classes.disabled]: disabled,
          [classes.contained]: !disabled && variant === 'contained',
          [classes.outlined]: !disabled && variant === 'outlined',
        })}
        data-testid={testId}
        ref={ref}
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={15}
            className={classNames(loadingProps?.className, {
              [classes.loading]: !loadingProps?.className,
              [classes.loadingContained]: !disabled && variant === 'contained',
              [classes.loadingOutlined]: !disabled && variant === 'outlined',
            })}
            {...loadingProps}
          />
        ) : (
          children
        )}
      </ButtonBase>
    );
  },
);

Button.defaultProps = {
  disabled: false,
  variant: 'contained',
  loading: false,
  loadingProps: {},
  testId: '',
};

export default Button;
