import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import { UpdateServiceCatalogRequest } from '../domains/UpdateServiceCatalogRequest';

export async function updateServicesCatalog(
  request: UpdateServiceCatalogRequest,
  vendorId?: string,
): Promise<any> {
  const isUpdateCatalogV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const log = useLogService();
  const baseUrl = `${getApiHostGrowMissionsService()}/grow-missions/admin`;
  let uri = '';

  if (isUpdateCatalogV2Enabled) {
    uri = `/v2/services/${request.id}?vendorId=${vendorId ?? ''}`;
  } else {
    uri = `/services/${request.id}`;
  }

  return axios
    .put(`${baseUrl}${uri}`, request.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
