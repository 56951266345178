import { AxiosError } from 'axios';
import { createStore } from 'effector';
import {
  createServicesMissionEffect,
  editServicesMissionEffect,
  getServicesMissionEffect,
  servicesMissionError400,
  clearServicesMissionErrors,
} from './ServicesMissionEvents';
import { ServicesMissionState } from './ServicesMissionState';

const initialState: ServicesMissionState = {
  loading: false,
  done: false,
  error: false,
  errors: [],
  servicesMissions: [],
  serviceMission: null,
};

const ServicesMissionStore = createStore(initialState)
  .on(servicesMissionError400, (state: ServicesMissionState) => {
    return { ...state, loading: false, done: true, error: false };
  })
  .on(clearServicesMissionErrors, (state: ServicesMissionState) => {
    return { ...state, errors: [] };
  })
  .on(createServicesMissionEffect.done, (state: ServicesMissionState, { result }) => {
    return {
      ...state,
      ...result,
      loading: false,
      done: true,
      error: false,
      errors: [],
    };
  })
  .on(createServicesMissionEffect.fail, (state: ServicesMissionState, { error }) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: true,
      errors: (error as AxiosError)?.response.data.errors,
    };
  })
  .on(createServicesMissionEffect, (state: ServicesMissionState) => {
    return { ...state, loading: true, done: false, error: false, errors: [] };
  })
  .on(editServicesMissionEffect.done, (state: ServicesMissionState, { result }) => {
    return {
      ...state,
      ...result,
      loading: false,
      done: true,
      error: false,
      errors: [],
    };
  })
  .on(editServicesMissionEffect.fail, (state: ServicesMissionState, { error }) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: true,
      errors: (error as AxiosError)?.response.data.errors,
    };
  })
  .on(editServicesMissionEffect, (state: ServicesMissionState) => {
    return { ...state, loading: true, done: false, error: false, errors: [] };
  })
  .on(getServicesMissionEffect.done, (state: ServicesMissionState, { result }) => {
    return {
      ...state,
      serviceMission: result,
      loading: false,
      done: true,
      error: false,
    };
  })
  .on(getServicesMissionEffect.fail, (state: ServicesMissionState) => {
    return { ...state, loading: false, done: true, error: true };
  })
  .on(getServicesMissionEffect, (state: ServicesMissionState) => {
    return { ...state, loading: true, done: false, error: false };
  });

export default ServicesMissionStore;
