import React from 'react';

function RewardsRedemptionIcon(): JSX.Element {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="153.507%" x2="50%" y2="0%" id="tz3lcqp02a">
          <stop stopColor="#DCD4F4" offset="0%" />
          <stop stopColor="#F0ECFC" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M5.266 36.985a.276.276 0 0 1-.247-.096C1.881 33.1 0 28.263 0 22.994 0 10.84 10.009.988 22.355.988c12.347 0 22.356 9.852 22.356 22.006 0 5.27-1.882 10.106-5.02 13.895a.276.276 0 0 1-.247.096c-5.384-.686-11.127-1.057-17.089-1.057-5.961 0-11.704.37-17.089 1.057z"
          fill="url(#tz3lcqp02a)"
        />
        <path
          d="m24.657 18.036 3.052 1.018a1.99 1.99 0 0 0 1.805-.281l2.582-1.897c.218-.16.527-.005.523.262l-.04 3.172a1.924 1.924 0 0 0 .829 1.603l2.63 1.831a.32.32 0 0 1-.09.571l-3.078.942c-.617.189-1.1.664-1.292 1.272l-.957 3.029a.33.33 0 0 1-.58.09l-1.86-2.59a1.974 1.974 0 0 0-1.629-.816l-3.222.04a.322.322 0 0 1-.266-.515l1.927-2.542c.387-.51.493-1.174.286-1.777l-1.035-3.004c-.087-.253.158-.494.415-.408z"
          fill="#FF0"
        />
        <path
          d="m32.588 8.342-22.51 3.907c-1.743-3.17-.853-7.12 2.088-9.268l.725-.529a2.042 2.042 0 0 1 2.63.191l5.393 5.311 3.223-6.806A2.03 2.03 0 0 1 26.542.083l.865.253c3.51 1.028 5.718 4.44 5.181 8.006z"
          fill="#8A48DB"
        />
        <path
          d="m32.588 8.342-22.51 3.907a7.165 7.165 0 0 1 1.596-8.875 3.07 3.07 0 0 0-.137.446c-.658 2.846 1.902 5.416 4.822 4.91l2.424-.421A3.651 3.651 0 0 1 21.271 7.2l2.866-6.053A2.03 2.03 0 0 1 26.542.083l.865.253c.088.026.176.053.263.082L25.576 4.84c-.49 1.036.406 2.187 1.55 1.988l5.406-.938c.157.79.182 1.617.056 2.453z"
          fill="#C9ABFF"
        />
        <path
          d="M25.017 10.167a3.523 3.523 0 0 1-.099 1.658 3.796 3.796 0 0 1-.224.046c-1.986.345-3.879-.96-4.229-2.914a3.523 3.523 0 0 1 .099-1.658c.074-.018.149-.033.225-.046 1.985-.345 3.878.96 4.228 2.914zM10.31 41.534a22.362 22.362 0 0 1-5.291-4.646v-11.26c0-.744.612-1.347 1.368-1.347h5.292c-.755 0-1.368.603-1.368 1.347v15.906zm1.574-24.071-.792-4.423a1.367 1.367 0 0 0-1.585-1.093l-5.406.938a1.35 1.35 0 0 0-1.11 1.561l.792 4.423a1.367 1.367 0 0 0 1.585 1.093l5.406-.938a1.35 1.35 0 0 0 1.11-1.561z"
          fill="#8A48DB"
        />
        <path
          d="M39.692 36.888C35.592 41.838 29.349 45 22.355 45a22.48 22.48 0 0 1-14.234-5.037V25.628c0-.744.613-1.347 1.369-1.347h28.833c.756 0 1.369.603 1.369 1.347v11.26zm1.113-24.445-.792-4.423a1.367 1.367 0 0 0-1.586-1.093L7.695 12.261a1.35 1.35 0 0 0-1.11 1.561l.792 4.423a1.367 1.367 0 0 0 1.586 1.093l30.732-5.334a1.35 1.35 0 0 0 1.11-1.561z"
          fill="#B476FF"
        />
        <path
          d="m27.044 16.31-8.986 1.56a.091.091 0 0 1-.106-.074l-1.274-7.112a.09.09 0 0 1 .074-.104l8.986-1.56a.091.091 0 0 1 .106.073l1.274 7.112a.09.09 0 0 1-.074.105zm-9.342 28.213a22.79 22.79 0 0 0 4.653.477 22.79 22.79 0 0 0 4.654-.477V24.28a.09.09 0 0 0-.091-.09h-9.125a.09.09 0 0 0-.091.09v20.242z"
          fill="#C9ABFF"
        />
        <path
          d="m28.567 24.31-1.598.62c-.37.144-.662.432-.807.795L25.53 27.3a.24.24 0 0 1-.444 0l-.631-1.574a1.422 1.422 0 0 0-.807-.794l-1.599-.621a.234.234 0 0 1 0-.438l1.599-.621c.369-.143.661-.431.807-.794l.631-1.574a.24.24 0 0 1 .444 0l.632 1.574c.145.363.437.65.807.794l1.598.621a.234.234 0 0 1 0 .438zM40.097 18.155l-1.184.46c-.273.106-.49.319-.597.588l-.467 1.165a.178.178 0 0 1-.329 0l-.467-1.165a1.053 1.053 0 0 0-.597-.588l-1.183-.46a.173.173 0 0 1 0-.324l1.183-.46c.273-.106.49-.318.597-.587l.467-1.165a.178.178 0 0 1 .33 0l.466 1.165c.108.269.324.481.597.588l1.184.46c.149.057.149.265 0 .323z"
          fill="#FFB042"
        />
      </g>
    </svg>
  );
}

export default RewardsRedemptionIcon;
