import React from 'react';
import { Modal as ModalBase, Box } from '@material-ui/core';
import classNames from 'classnames';
import ModalStyles from './Modal.styles';

interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> & React.ReactNode;
  isEditSponsored?: boolean;
}

const Modal: React.FunctionComponent<Props> = ({
  open,
  onClose,
  children,
  isEditSponsored,
  ...props
}) => {
  const classes = ModalStyles();
  return (
    <ModalBase open={open} onClose={onClose} className={classes.modalBaseContainer} {...props}>
      <Box
        className={classNames(
          classes.modalContentContainer,
          isEditSponsored && classes.editPageModalContentContainer,
        )}
      >
        {children}
      </Box>
    </ModalBase>
  );
};

export default Modal;
