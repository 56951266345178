import React from 'react';

function SuccessIconToastify(): JSX.Element {
  return (
    <svg
      style={{ marginTop: '4px' }}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.63636364 C4.93317593,1.63636364 1.63636364,4.93317593 1.63636364,9 C1.63636364,13.0668241 4.93317593,16.3636364 9,16.3636364 C13.0668241,16.3636364 16.3636364,13.0668241 16.3636364,9 C16.3636364,4.93317593 13.0668241,1.63636364 9,1.63636364 Z M12.6629126,6.08708739 C13.013111,6.43728581 13.028337,6.99560938 12.7085907,7.36390564 L12.6629126,7.41291261 L8.53791261,11.5379126 C8.18771419,11.888111 7.62939062,11.903337 7.26109436,11.5835907 L7.21208739,11.5379126 L5.33708739,9.66291261 C4.97097087,9.29679608 4.97097087,8.70320392 5.33708739,8.33708739 C5.68728581,7.98688898 6.24560938,7.97166296 6.61390564,8.29140934 L6.66291261,8.33708739 L7.875,9.549375 L11.3370874,6.08708739 C11.7032039,5.72097087 12.2967961,5.72097087 12.6629126,6.08708739 Z"
          id="path-1"
        />
      </defs>
      <g id="Missions-management-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Missions.-Custom-missions-MVP---Activated-and-inactivated"
          transform="translate(-894.000000, -436.000000)"
        >
          <g
            id="UserFeedback/Alerts/Dismissable/Toast/Success/Default-Copy-4"
            transform="translate(883.000000, 421.000000)"
          >
            <g id="Group" transform="translate(8.000000, 8.000000)">
              <g id="02_Icons/checkmark-2" transform="translate(3.000000, 7.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Combined-Shape" fillRule="nonzero" />
                <g
                  id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                  mask="url(#mask-2)"
                  fill="#1F8E4A"
                >
                  <g transform="translate(-3.000000, -3.000000)" id="Rectangle">
                    <rect x="0" y="0" width="24" height="24" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SuccessIconToastify;
