import React from 'react';
import { ButtonDefault } from '@admin-portal-shared-components/button-default';
import { Typography } from '@material-ui/core';
import useStyles from './ConfirmationModal.styles';
import Modal from '../../../../components/modal';
import { formatMessage } from '../../../../i18n/formatters';

interface Props {
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

const ConfirmationModal: React.FunctionComponent<Props> = ({
  open,
  onClose,
  onConfirm,
  ...props
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} {...props}>
      <div className={classes.modal}>
        <div>
          <Typography className={classes.titleModal}>
            {formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MODAL_TITLE' })}
          </Typography>
        </div>
        <div>
          <Typography>
            {formatMessage({ id: 'SERVICES_MISSION.CONFIRMATION_MODAL_MESSAGE' })}
          </Typography>
        </div>
        <div className={classes.actionsModal}>
          <ButtonDefault
            className={classes.btnDanger}
            size="large"
            variant="primary"
            data-testid="return-step-button"
            onClick={onConfirm}
          >
            {formatMessage({ id: 'SERVICES_MISSION.BTN_DELETE' })}
          </ButtonDefault>
          <ButtonDefault
            size="large"
            variant="secondary"
            leading
            data-testid="services-modal-cancel-button"
            onClick={onClose}
          >
            {formatMessage({ id: 'SERVICES_MISSION.BTN_CANCEL' })}
          </ButtonDefault>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {};

export default ConfirmationModal;
