import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme, { active: boolean }>(() =>
  createStyles({
    iconContainer: {
      border: ({ active }) => (active ? '1px solid #000000' : '1px solid #00000055'),
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '42px',
      height: '42px',
      padding: '6px',
      opacity: ({ active }) => (active ? 1 : 0.3),
      '&:hover': {
        opacity: ({ active }) => (active ? 1 : 0.6),
      },
      backgroundColor: 'transparent',
    },
  }),
);
