import React from 'react';

import { ThemeProvider as HubThemeProvider } from '@hexa-ui/theme';
import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { useAnalyticsService, useFeatureToggleService } from 'admin-portal-shared-services';
import { InitialDataWrapper } from 'grow-shared-services';
import ReactDOM from 'react-dom';
import { toast, ToastContainer } from 'react-toastify';

import { toastContainerId } from './components/CustomToast/CustomToast';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './config/constants';
import { removeWhiteSpaceAndLineBreaks } from './config/utils/functions';
import { EnvProvider } from './EnvProvider';
import { EnvConfig } from './EnvProvider/EnvProvider.types';
import Router from './Router';
import CustomTheme from './theme';
import ToastfyCustomStyles from './ToastifyOverride.styles';
import './stores/initGlobalStores';
import 'react-toastify/dist/ReactToastify.min.css';

function App(props: EnvConfig): JSX.Element {
  const { TLP_SEGMENT_KEY, ONCUSTOMER_OPTIMIZELY_KEY } = props;

  const classes = ToastfyCustomStyles();
  const headNode = document.head;
  const analyticsService = useAnalyticsService();
  analyticsService.load(TLP_SEGMENT_KEY);

  useFeatureToggleService().loadToggles();

  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-grow-missions',
    seed: 'admin-portal-grow-missions',
  });

  const metaTagsSEO = (
    <>
      <meta name="Googlebot" content="noindex, nofollow, nosnippet" />
      <meta name="robots" content="nosnippet" />
      <meta
        name="description"
        content={removeWhiteSpaceAndLineBreaks(`A gateway to access all applications and data 
            regarding flexibal missions.`)}
      />
    </>
  );

  return (
    <InitialDataWrapper
      projectName={WRAPPER_PROJECT_NAME_INSTANCE}
      optKey={ONCUSTOMER_OPTIMIZELY_KEY}
    >
      <ThemeProvider theme={CustomTheme}>
        <HubThemeProvider theme="grow">
          <StylesProvider generateClassName={generateClassName}>
            <EnvProvider env={props}>
              {ReactDOM.createPortal(metaTagsSEO, headNode)}
              <ToastContainer
                className={classes.toastContainer}
                toastClassName={classes.toast}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={3000}
                hideProgressBar
                draggable={false}
                closeButton={<HighlightOff className={classes.closeIcon} />}
                containerId={toastContainerId}
                enableMultiContainer
                limit={10}
              />
              <CssBaseline>
                <Router />
              </CssBaseline>
            </EnvProvider>
          </StylesProvider>
        </HubThemeProvider>
      </ThemeProvider>
    </InitialDataWrapper>
  );
}

export default App;
