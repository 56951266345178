import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    list: {
      maxHeight: '300px',
      overflowY: 'auto',
      width: '100%',
      minHeight: '300px',
    },
  }),
);
