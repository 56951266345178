import MessageMap from '../domains/MessageMap';

const en: MessageMap = {
  TargetCallList: {
    TITLE: 'Call list',
    COMPLETED_TEXT: 'Calls completed',
    TARGET_TEXT: 'Target',
  },
  HomeMainPage: {
    ERROR_GET_AGENT_KPIS:
      'USER INFORMATION UNAVAILABLE - We can’t reach your data for now. Please try again later.',
    CALL_LIST_COMPLETE: 'The Call list is complete, all POCs have been contacted',
    HAS_OPEN_CALL: 'Resume last client',
    GET_NEXT_POC: 'Go to the next client',
    UPDATE_CARD_BUTTON: 'Update',
    MONTHLY_RESULTS: 'Monthly results',
    DAILY_RESULTS: 'Daily results',
    NO_SEGMENTATION:
      'Contact your administrator/supervisor to associate a segmentation to your profile.',
  },
  HitRate: {
    TITLE: 'Hit rate',
    COMPLETED_TEXT: 'Effective calls',
    TARGET_TEXT: 'Total calls',
  },
  AverageHandleTime: {
    TITLE: 'AHT',
    COMPLETED_TEXT: 'Time on call',
    TARGET_TEXT: 'Target',
    TIME: 'min',
  },
  ToplineUplift: {
    TITLE_CD: 'Topline uplift',
    COMPLETED_TEXT: 'Uplifted',
    TARGET_TEXT: 'Target',
    TOPLINE_TOTAL_GAP: 'Total gap',
  },
  ErrorHandling_KPI: {
    ERROR_UPDATE_KPI: "We can't reach your data for now. Please try again later.",
  },
  Search_Component: {
    CLEAR_SEARCH_BUTTON: 'Clear search',
    ITEMS_FOUND_LABEL: 'Items found',
    PLACEHOLDER: 'Search by POC',
  },
  NPS: {
    NPS_TITLE: 'Net Promoter Score (NPS)',
    NPS_ERROR: 'NPS could not be loaded. Please, try again soon.',
    NPS_CLOSE: 'Answer later',
    NPS_SUBMIT: 'Submit',
  },
  TargetProducts: {
    TARGET_PRODUCTS: 'TARGET PRODUCTS',
    UNIT_PRICE: 'Price (Unit)',
    NOT_FOUND: 'There are no items to be displayed',
  },
  TotalPerformance: {
    TITLE: 'Total performance',
    EFFECTIVE_CALLS: 'Effective calls',
    TOTAL_CALLS: 'Total calls',
    REVENUE_UPLIFTED: 'Net revenue uplifted',
    TARGET_UPLIFTED: 'Target',
  },
  MISSIONS_MANAGEMENT: {
    LOCAL_MISSION: 'Local missions',
    NEW_MISSION: 'Create mission',
    TITLE: 'Missions management',
    PRIMARY_TAB_LABEL: 'Primary missions',
    SECONDARY_TAB_LABEL: 'Secondary missions',
    APPLY_CHANGES_BUTTON: 'Apply changes',
    REWARDSCHALLENGES_TITLE: 'Rewards challenges',
    PRIMARY_MISSION_SPONSORED_TITLE: 'Sponsored',
    PRIMARY_MISSION_SERVICES_TITLE: 'Services',
    LOCAL_MISSIONS_TITLE: 'Local missions',
    TOPLINEGAP_DESCRIPTION:
      'Understand where’s the customer gap and recommend products to help them grow.',
    MARKETPLACE_DESCRIPTION: 'Offer the purchase of marketplace products.',
    LOCAL_MISSIONS_DESCRIPTION: 'Customized missions created by the operations back office team.',
    REWARDSCHALLENGES_DESCRIPTION:
      'Advise the customers to accept and do the available challenges to earn points.',
    DIGITAL_SERVICER_DESCRIPTION: 'Offer new services available for our customers.',
    PRIMARY_MISSIONS_OBS:
      'The primary missions will define the POCs on the call list and its order.\n\nChoose the active missions and reorder their priority by dragging the cards.',
    SECONDARY_MISSIONS_OBS:
      'The secondary missions will be shown to POCs that are already on the call list if they meet the mission criteria. Choose the active missions. ',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    PRIMARY_MISSION_CARD_TYPE: 'Primary mission',
    SECONDARY_MISSION_CARD_TYPE: 'Secondary misson',

    SECONDARY_MISSION_QUICKORDER: 'Quick order',
    SECONDARY_MISSION_REWARDSREDEMPTION: 'Rewards redeem',
    SECONDARY_MISSION_TOPLINEGAP: 'Topline gap',
    SECONDARY_MISSION_REWARDSCHALLENGES: 'Rewards challenges',

    BUTTONS_AREA_ANYCHANGES_TEXT: 'Any changes made will only take effect by tomorrow.',
    BUTTONS_AREA_DISCARDCHANGES_BUTTON: 'Discard changes',
    BUTTONS_AREA_SAVECHANGES_BUTTON: 'Save changes',

    APPLY_CHANGES_MODAL_TITLE: 'Are you sure you want to apply the changes?',
    APPLY_CHANGES_MODAL_DESCRIPTION: 'it will take just a few minutes to activate your changes.',
    APPLY_CHANGES_MODAL_DESCRIPTION_SECONDARY: 'The changes will be live just tomorrow.',
    APPLY_CHANGES_CONFIRM: 'Yes, apply',
    APPLY_CHANGES_CANCEL: 'No, cancel',

    DISCARD_CHANGES_MODAL_TITLE: 'Are you sure you want to leave?',
    DISCARD_CHANGES_MODAL_DESCRIPTION:
      'The changes that you made on the mission management will be lost.',
    DISCARD_CHANGES_CONFIRM: 'Yes, leave',
    DISCARD_CHANGES_CANCEL: 'No, cancel',

    DISCARD_CHANGES_TAB_MODAL_TITLE: 'Are you sure you want to switch tabs?',
    DISCARD_CHANGES_TAB_MODAL_DESCRIPTION: 'The changes that you made on this tab will be lost.',
    DISCARD_CHANGES_TAB_CONFIRM: 'Yes, switch',
    DISCARD_CHANGES_TAB_CANCEL: 'No, cancel',
    EDIT_TEXT: 'Edit',

    ERROR_TO_APPLY_CHANGES:
      'Something went wrong! The changes were not save, try applying them again.',
    LOADING_TEXT: 'Processing...',
    SUCCESS_UPDATE_MISSIONS: 'The changes have been successfully applied.',
    ERROR_UPDATE_MISSIONS_ALL_DISABLED: 'You must have at least 1 primary mission active.',
    ERROR_TO_LOAD_PRIMARY_MISSIONS: 'It was not possible to load the primary missions.',
    ERROR_TO_LOAD_SECONDARY_MISSIONS: 'It was not possible to load the secondary missions.',
    ERROR_UPDATE_MISSIONS_INVALID_FIRST_POSITION:
      'The changes couldn’t be saved. Topline gap or Marketplace must be in the first place of the list.',

    SECONDARY_WARNING_TOAST: 'The changes on the secondary missions will be live just tomorrow!',

    PRIMARY_MISSION_SERVICES_SWITCH_HOVER:
      'This mission requires agent’s exclusivity. Go to agent setup to activate it for specific agents.',
    SPONSORED_MISSIONS: 'Sponsored missions',
    NEW_SPONSORED_MISSIONS: 'New sponsored mission',
    NEW_SPONSORED_MISSION_FIRST_STEP: 'Main details',
    NEW_SPONSORED_MISSION_SECOND_STEP: 'Content',
    NEW_SPONSORED_MISSION_THIRD_STEP: 'Agents setup',
    NEW_SPONSORED_MISSION_FOURTH_STEP: 'Call list',
    SERVICES_MISSIONS: 'Services mission',

    BACK_BUTTON: 'Back',
    FINISH_BUTTON: 'Finish',
    NEXT_BUTTON: 'Next',
    CANCEL_BUTTON: 'Cancel',
    PROCESSING_BUTTON: 'Processing...',

    NEW_SPONSORED_MISSION_TITLE: 'Mission title',
    NEW_SPONSORED_MISSION_TITLE_EXEMPLE: 'ex: New Michelob Ultra Longneck',
    NEW_SPONSORED_SPONSOR_PARTNER: 'Sponsor partner',
    NEW_SPONSORED_SPONSOR_PARTNER_EXEMPLE: 'ex: Diageo',
    NEW_SPONSORED_START_DATE: 'Start date',
    NEW_SPONSORED_END_DATE: 'End date',

    NEW_SPONSORED_MANDATORY_FIELD: 'This field is required.',
    NEW_SPONSORED_MANDATORY_CHAR: 'The mission title can’t contain these special characters.',
    NEW_SPONSORED_FIRST_STEP_INVALID_END_DATE: 'End Date must be later than Start Date.',
    NEW_SPONSORED_FIRST_STEP_INVALID_CHARACTER: 'We only support letters, numbers, and hyphens.',
    NEW_SPONSORED_FIRST_STEP_INVALID_TITLE: `There's already a mission with this title, try another one.`,

    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_LABEL_TITLE: 'Team allocation',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_PLACEHOLDER: 'Insert specialization',
    NEW_SPONSORED_THIRD_STEP_INVALID_CHARACTER: 'We only support letters, numbers, and hyphens.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_MAX_LENGTH:
      'We only support the maximum 30 characters.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_HINT:
      'The agents that will perform the mission are those that have the same specialization defined above. To assign specializations to agents, you need to access the Agent setup.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_TOOLTIP:
      'On the agent setup page, you select agents and then assign a specialization. This same specialization needs to be entered on this page.\n\nIt is possible to set all agents to perform this mission, for that you have to assign all of them with the same specialization.',

    NEW_SPONSORED_CANT_CHANGE_STEP: "You couldn't go to next step now. ",

    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT1: 'Drag the missions to',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT2_BOLD: ' reorder',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT3: ' and',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT4_BOLD: ' prioritize',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT5: ' according to your needs.',
    NEW_MISSIONS_MANAGEMENT_ERROR_UPDATE_MISSIONS_ALL_DISABLED:
      'You must have at least 1 mission active.',

    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_TITLE: 'Discard the changes?',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'The changes will be discarded immediately, and this action can’t be undone.',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CONFIRM: 'Yes, discard',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CANCEL: 'Cancel',

    NEW_MISSIONS_MANAGEMENT_ERROR_TO_LOAD_MISSIONS: 'It was not possible to load the missions.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING: 'Parameters',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING_SUBTEXT:
      'These are the main parameters and limit of missions set to your business unit.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_TITLE: 'Help',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_SUBTITLE: 'Changing the parameters',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_TEXT:
      'To change the parameters, contact your responsible business team.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_SUBTITLE: 'Exceeding the limit',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_TEXT:
      'When the limit is exceeded, the low priority missions may not be shown to the agents.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FOOTER_TEXT:
      'To make sure a mission will be shown, make it the top priority.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TITLE: 'Shared limit of missions',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TEXT:
      'The maximum of missions that can be performed by each agent, which helps on keeping missions effectiveness.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALMISSIONS: 'Global missions',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALTOOLTIP:
      'Topline Gap, Marketplace, Rewards Challenges and Rewards Redemption',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALMISSIONS: 'Local missions',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALTOOLTIP:
      'The local missions with the most priority are the ones that will be assigned to the agents.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TOTAL: 'Total',

    TAG_INPUT_LABEL_FIELD_TYPE: '(Optional)',
    TAG_INPUT_PLACEHOLDER: 'Example: PortfolioExpansion, BeyondBeer',
  },
  LOCAL_MISSIONS_FILTER: {
    LABEL: 'Filter',
    DATE_ERROR: 'Enter a valid date',
    CLEAR_ALL: 'Clear all',
    CANCEL: 'Cancel',
    APPLY: 'Apply',
    SPONSORED: 'Show only sponsored',
    STATUS_WARNING:
      'Paused, expired, and concluded missions are kept in the history for 90 days after reaching their duration period.',
  },
  LOCAL_MISSIONS: {
    TABLE_DESCRIPTION:
      'Create missions for specific contacts and time period, following your parameters.',
    CREATE_NEW_MISSION: 'Create new mission',
    ACTIVE: 'Active',
    ALL: 'All missions',
    TABLE_START: 'Start',
    TABLE_END: 'End',
    TABLE_PERIOD: 'Period',
    TABLE_IDENTIFICATION: 'Identification',
    TABLE_TYPE: 'Type',
    TABLE_TYPE_THRIVE: 'Thrive',
    TABLE_TYPE_COVERAGE: 'Coverage',
    TABLE_TYPE_SIMPLE: 'Simple',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Priority',
    TABLE_SCHEDULED: 'Scheduled',
    TABLE_ONGOING: 'Ongoing',
    TABLE_CONCLUDED: 'Concluded',
    TABLE_EXPIRED: 'Expired',
    TABLE_PAUSED: 'Paused',
    TABLE_CATEGORY: 'Category',
    TABLE_OTHERS: 'Others',
    TOAST_SAVE_SUCCESS: 'The changes were successfully saved.',
    TOAST_SAVE_ERROR: 'The changes couldn’t be saved.',
    TOAST_PRIORITY_EDIT_SUCCESS_BODY: 'The mission priority was successfully changed from',
    TOAST_PRIORITY_EDIT_SUCCESS_PREPOSITION: 'to',
    TOAST_PRIORITY_EDIT_SUCCESS_FINAL: 'and will take effect by tomorrow',
    TOAST_PRIORITY_EDIT_ERROR: 'The mission priority couldn’t be changed. Try again.',

    NOT_RESULTS_FOUND: 'No results found',
    NOT_RESULTS_DESCRIPTION:
      'Try searching again for a different mission name or tag or using other filters.',

    TITLE_FIRST_STEP: 'Main',
    TITLE_SECOND_STEP: 'Content',
    TITLE_THIRD_STEP: 'Audience',
    TITLE_FOURTH_STEP: 'Team',
    MISSION_TYPE_TITLE: 'Type',
    MISSION_TYPE_COVERAGE: 'Coverage',
    MISSION_TYPE_SIMPLE: 'Simple',
    MISSION_TYPE_SURVEY: 'Survey',
    MISSION_TYPE_SERVICES: 'Services',
    MISSION_CATEGORY: 'Category',
    MISSION_CATEGORY_DIGITAL_EDUCATION: 'Digital Education',
    MISSION_CATEGORY_VALUE_CREATION: 'Value Creation',

    STEP_FOUR_TEAM_ALLOCATION_TITLE: 'Team allocation',
    STEP_FOUR_SEGMENTATION_LABEL_TITLE: 'Segmentation',
    STEP_FOUR_SEGMENTATION_INPUT_PLACEHOLDER: 'Enter the segmentation name',
    STEP_FOUR_SEGMENTATION_DESCRIPTION:
      'Add a segmentation to assign collaborators to perform this mission.',
    TIME_PERIOD_TITLE: 'Time period',
    TIME_PERIOD_SUBTITLE: 'Define a start and end date.',

    SPONSOR_TITLE: 'Sponsor',
    SPONSOR_OPTIONAL_TAG: '(Optional)',
    SPONSOR_SUBTITLE: 'Partner that sponsors this mission.',
    SPONSOR_PLACEHOLDER: 'Enter the partner name',
    ERROR_TO_LOAD_LOCAL_MISSIONS: "We couldn't load the missions",

    SPONSOR_CHECKBOX_TITLE: 'Sponsored mission',
    SPONSOR_CHECKBOX_SUBTITLE: 'There is a partner sponsoring this mission',
    SPONSOR_CHECKBOX_PLACEHOLDER: 'Enter the sponsor name',
    SPONSOR_CHECKBOX_ERROR: 'You need to add the sponsor name',

    SPONSOR_DROPDOWN_PLACEHOLDER: 'Select a sponsor',
    SPONSOR_DROPDOWN_ERROR: 'You need to select a sponsor',
    SPONSOR_DROPDOWN_REQUEST_ERROR: 'Unable to load the sponsors.',

    DUPLICATE_TITLE: 'Duplicate mission',
    DUPLICATE_BUTTON: 'Duplicate',
    DUPLICATE_CANCEL_BUTTON: 'Cancel',

    DELETE_BUTTON: 'Delete',
    DELETE_MODAL_TITLE: 'Delete this mission?',
    DELETE_MODAL_DESCRIPTION:
      'The mission will be deleted immediately, and this action can’t be undone.',
    DELETE_MODAL_DELETE_BUTTON: 'Yes, delete',
    DELETE_MODAL_CANCEL_BUTTON: 'Go back',
    DELETE_MODAL_TOAST_SUCCESS: 'The mission was successfully deleted.',
    DELETE_MODAL_TOAST_ERROR: 'Unable to delete this mission.',

    EXPORT_BUTTON: 'Export report',

    DUPLICATE_MANDATORY_FIELD: 'This field is required.',
    DUPLICATE_MANDATORY_CHAR: 'The mission title can’t contain these special characters.',
    DUPLICATE_INVALID_END_DATE: 'End Date must be later than Start Date.',
    DUPLICATE_INVALID_CHARACTER: 'We only support letters, numbers, and hyphens.',

    DUPLICATE_REQUIRED_LIST_ITEM: `It's mandatory to add at least one item to the list`,
    DUPLICATE_SEGMENTATION_INPUT_MAX_LENGTH: 'We only support the maximum 30 characters.',
    DUPLICATE_DIGITALIZATION_MANDATORY: 'Ticking at least one level is required.',
    DUPLICATE_CSV_REQUIRED: 'Upload a file is required.',

    DUPLICATE_DISCARD_CHANGES_MODAL_TITLE: 'Discard the changes?',
    DUPLICATE_DISCARD_CHANGES_MODAL_DESCRIPTION:
      "The changes will be discarded immediately, and this action can't be undone.",
    DUPLICATE_MODAL_DISCARD_BUTTON: 'Yes, discard',
    DUPLICATE_MODAL_CANCEL_BUTTON: 'Cancel',

    MISSION_DETAILS_TITLE: 'Mission details',

    MISSION_DETAILS_LABEL_CONTENT_NONE_TEXT: 'None',

    MISSION_DETAILS_MAIN_SECTION_TITLE: 'Main',
    MISSION_DETAILS_MAIN_SECTION_ACTIVATION_LABEL: 'Activation',
    MISSION_DETAILS_MAIN_SECTION_STATUS_LABEL: 'Status',
    MISSION_DETAILS_MAIN_SECTION_TYPE_LABEL: 'Type',
    MISSION_DETAILS_MAIN_SECTION_CATEGORY_LABEL: 'Category',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_LABEL: 'Sponsor',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_VALUE_NOT_SPONSORED: 'None (not sponsored)',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_LABEL: 'Period',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_TO_TEXT: 'to',
    MISSION_DETAILS_MAIN_SECTION_TAGS_LABEL: 'Tags',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_LABEL: 'Created on',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_BY_TEXT: 'By',
    MISSION_DETAILS_MAIN_SECTION_STATUS_ON_TEXT: 'On',
    MISSION_DETAILS_MAIN_SECTION_STATUS_OFF_TEXT: 'Off',

    MISSION_DETAILS_TEAM_SECTION_TITLE: 'Team',
    MISSION_DETAILS_TEAM_SECTION_SEGMENTATION_LABEL: 'Segmentation',

    MISSION_DETAILS_CONTENT_SECTION_TITLE: 'Content',
    MISSION_DETAILS_CONTENT_SECTION_SCRIPT_LABEL: 'Script',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_LABEL: 'Effectiveness Criteria',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_QUANTITY_LABEL: 'Minimum of the total',

    MISSION_DETAILS_AUDIENCE_SECTION_TITLE: 'Audience',
    MISSION_DETAILS_AUDIENCE_SECTION_CSV_PREVIEW_DOWNLOAD_BUTTON: 'Download',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_TITLE: 'Filters',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_BLOCKLIST: 'Block list',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_DIGITALIZATION_LEVEL: 'Digitalization level',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_NO_DIGITALIZATION: 'No digitalization',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_INITIAL_ADOPTION: 'Initial adoption',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_ENGAGED: 'Engaged',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_FULL_DIGITAL: 'Full digital',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL: 'When to call',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL_DELIVERY_WINDOW: 'Delivery window',
    MISSION_DETAILS_AUDIENCE_SECTION_STOP_CALLING_WHEN: 'Stop calling when',

    EDIT_BUTTON_TOOLTIP: "You can only edit missions that haven't started yet",
    PLACEHOLDER_LOCAL_MISSIONS: 'Search by mission name or tag',

    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Activate local missions in the Missions management, so agent can perform these missions.',

    NO_SPONSOR_FOUND: 'No sponsor found',
  },
  SPONSORED_MISSIONS: {
    SAVE: 'Save',
    NO_ACTIVE_MISSIONS_YET: 'No active missions created yet',
    NO_MISSIONS_YET: 'No missions created yet.',
    YOU_CAN: 'You can',
    CREATE_NEW_MISSIONS: 'create a new mission',
    ARTICLE: 'The',
    MISSION_ACTIVATION_SUCCESS_MESSAGE:
      'mission was successfully activated, and will take effect by tomorrow.',
    MISSION_INACTIVATION_SUCCESS_MESSAGE:
      'mission was successfully deactivated, and will take effect by tomorrow.',
    MISSION_ACTIVATION_ERROR_MESSAGE: 'mission couldn`t be activated.',
    MISSION_INACTIVATION_ERROR_MESSAGE: 'mission couldn`t be deactivated.',
    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Activate sponsored missions in the Missions management, so agent can perform these missions.',
    ERROR_TO_LOAD_SPONSORED_MISSIONS: 'It was not possible to load the sponsored missions.',

    MISSION_TYPE: 'Mission type',
    PACKAGE_TYPE_LABEL: 'Packaging',
    PACKAGE_TYPE_BOX: 'Box',
    PACKAGE_TYPE_UNIT: 'Unit',
    PACKAGE_TYPE_DISPLAY: 'Display',

    STEP_TWO_LIST_IMG: 'IMG',
    STEP_TWO_LIST_NAMEANDCODE: 'NAME AND CODE',
    STEP_TWO_LIST_DESCRIPTION: 'Description',
    STEP_TWO_LIST_MIN_QUANTITY: 'Min. quantity',
    STEP_TWO_LIST_SUGGESTEDQUANTITY: 'SUGGESTED QUANTITY',
    STEP_TWO_NOPRODUCTS: 'No products added yet.',
    STEP_TWO_LIST_ADDPRODUCTS: 'You can use the field above to add',

    STEP_TWO_ERROR_GET_ITEM: "The item couldn't be added now.",

    STEP_TWO_PRODUCTS_AND_COMBOS: 'Products and Combos',
    STEP_TWO_PRODUCTS_AND_COMBOS_PLACEHOLDER: 'Type the product or combo code...',
    STEP_TWO_PRODUCTS_AND_COMBOS_SUBTITLE:
      'The items and the suggested quantity to be offered in the mission.',
    STEP_TWO_PRODUCTS_AND_COMBOS_NEW_SUBTITLE: 'List of items to be offered in this mission.',

    STEP_TWO_NO_ITEMS_FOUND: 'No items found with this code.',
    STEP_TWO_ITEM_ALREADY_ADDED: 'The item you are trying to add is already in the list',
    STEP_TWO_ITEM_REQUIRED: `It's mandatory to add at least one item to the list`,
    STEP_TWO_MAXIMUM_REACHED: `The item couldn't be added because the maximum limit was reached.`,

    STEP_TWO_SCRIPT_TITLE: `Script`,
    STEP_FIELD_OPTIONAL: `(optional)`,
    STEP_TWO_SCRIPT_HINT: `Define step-by-step instructions for the agents.`,
    STEP_TWO_SCRIPT_NEW_HINT: `Define a brief script for the agent speech.`,

    STEP_TWO_EFFECTIVENESS_TITLE: `Effectiveness criteria`,
    STEP_TWO_EFFECTIVENESS_SUBTITLE: `Conditions for this mission to be considered successful after its completion.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_TITLE: `Minimum of the total`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_SUBTITLE: `Requires selling a minimum quantity of any of the items.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_QUANTITY_INPUT_TITLE: `Minimum of the total`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_TITLE: `Minimum per item`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_SUBTITLE: `Requires selling a minimum quantity of each item.`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_TITLE: `Agent check`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_SUBTITLE: `Requires agents to mark the mission as complete.`,

    STEP_TWO_COMBO_CHIP: 'Combo',
    STEP_TWO_FREE_GOOD_CHIP: 'Free good',

    STEP_FOUR_POCS: 'POCs',
    STEP_FOUR_POCS_UPDATED: 'POCs list',

    STEP_FOUR_CSV_TITLE: 'Upload a CSV file',
    STEP_FOUR_CSV_SUBTITLE: 'or drag and drop it here',
    STEP_FOUR_CSV_TEMPLATE: 'Use the CSV file from our template.',
    STEP_FOUR_CSV_DOWNLOAD_TEMPLATE: 'Download template',

    STEP_FOUR_POC_FILTER_TITLE: 'Filters',

    STEP_FOUR_POC_FILTER_DESCRIPTION:
      'Select the filters to define the eligible POCs for this mission.',

    STEP_FOUR_POC_FILTER_DESCRIPTION_UPDATED: 'Filter the POCs for this mission.',

    STEP_FOUR_DELIVERY_WINDOW_TITLE: 'Delivery window',
    STEP_FOUR_DELIVERY_WINDOW_DESCRIPTION:
      'POCs will only be contacted on days with the best lead time.',
    STEP_FOUR_BLOCK_LIST_TITLE: 'Block list',
    STEP_FOUR_BLOCK_LIST_DESCRIPTION: `POCs on the block list won't be contacted.`,
    STEP_FOUR_BLOCK_LIST_DESCRIPTION_DISABLED: `This setting is defined by your business unit.`,

    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE: 'Choose digitalization level',
    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE_UPDATED: 'Digitalization level',

    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION:
      'The groups selected will be contacted. POCs are divided considering the percentage of purchases through BEES.',
    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION_UPDATED:
      'Groups are divided considering the percentage of purchases through BEES.',

    STEP_FOUR_DIGITALIZATION_TITLE: 'Digitalization',
    STEP_FOUR_DIGITALIZATION_TITLE_UPDATED: 'No digitalization',

    STEP_FOUR_DIGITALIZATION_MANDATORY: 'Ticking at least one level is required.',

    STEP_FOUR_ADOPTION_TITLE: 'Adoption',
    STEP_FOUR_ADOPTION_TITLE_UPDATED: 'Initial adoption',

    STEP_FOUR_ENGAGEMENT_TITLE: 'Engagement',
    STEP_FOUR_ENGAGEMENT_TITLE_UPDATED: 'Engaged',

    STEP_FOUR_FULL_DIGITAL_TITLE: 'Full digital',
    STEP_FOUR_FULL_DIGITAL_TITLE_UPDATED: 'Full digital',

    STEP_FOUR_CSV_REQUIRED: 'Upload a file is required.',
    STEP_FOUR_CSV_ONLY: 'We only support CSV files.',
    STEP_FOUR_CSV_MAX_SIZE: 'We support files with a maximum size of 1MB.',
    STEP_FOUR_CSV_MAX_SIZE_2MB: 'We support files with a maximum size of 2MB.',

    SUCCESS_CREATE_MISSION:
      'The mission was successfully created, and will take effect by tomorrow.',
    ERROR_CREATE_MISSION: "The mission couldn't be  created.",
    WARNING_CREATE_MISSION:
      'Activate Sponsored mission in the Missions management, so agents can perform these missions. ',
    ERROR_TEMPLATE_CSV: `The file couldn't be uploaded because is not following the template format.`,
    EXPIRED_MISSION_SWITCH_HOVER: `It’s not possible to activate an expired mission.`,
    SPONSORED_JUST_TOMORROW: 'The changes on the sponsored missions will be live just tomorrow!',
    SPONSORED_EXPIRED_STARTED: `It's not possible to edit a mission that has started or expired.`,
    EDIT_SPONSORED_MISSIONS: 'Edit sponsored mission',
    EDIT_MISSION: 'Edit mission',
    SAVE_EDIT_SPONSORED_MISSION_ERROR: `The changes couldn't be saved.`,
    SAVE_EDIT_SPONSORED_MISSION_SUCESS: 'Changes successfully saved.',
    EDIT_SPONSORED_DOWNLOAD_CSV_FILE: 'Download',

    STEP_FOUR_GET_STARTED: 'Get started filling up our',
    STEP_FOUR_TEMPLATE_FILE: 'template file.',
    STEP_FOUR_UPLOAD_FILE: 'Upload a file',
    STEP_FOUR_DRAG_AND_DROP: 'or drag and drop it here',
    STEP_FOUR_CSV_MAX_1MB: 'CSV (max. 1MB)',
    STEP_FOUR_CSV_MAX_2MB: 'CSV (max. 2MB)',

    STEP_FOUR_WHEN_TO_CALL: 'When to call',
    STEP_FOUR_WHEN_TO_CALL_DESCRIPTION: 'The best time for calling the POCs.',

    STOP_CALLING_WHEN_LABEL: 'Stop calling when',
    STOP_CALLING_SELECT_OPTION: 'Select an option',
    STOP_CALLING_FIRST_CONTACT_OPTION: 'First contact is made',
    STOP_CALLING_EFFECTIVENESS_OPTION: 'Effectiveness criteria has been reached',

    OVERRIDE_CSV_ALERT: "The POC's segmentation will be replaced with the one you've set.",

    DISCARD_CHANGES_BUTTON: 'Discard changes',
    EDIT_DISCARD_CHANGES_MODAL_TITLE: 'Discard the changes?',
    EDIT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      "The changes will be discarded immediately, and this action can't be undone.",
    EDIT_MODAL_DISCARD_BUTTON: 'Yes, discard',
    EDIT_MODAL_CANCEL_BUTTON: 'Cancel',
  },
  SERVICES_MISSION: {
    PAGE_TITLE: 'Services mission',
    TITLE: 'Script',
    SUBTITLE: 'Define step-by-step instructions for the agents.',
    BTN_TITLE: 'Save changes',
    CONFIRMATION_MESSAGE_OK: 'Changes successfully saved!',
    CONFIRMATION_MESSAGE_ERROR: `The changes couldn't be saved now.`,
    TEXTAREA_PLACEHOLDER: `1. Introduce the reason for the call;\r\n2. Ask how the customer is and try to understand its current needs;\r\n3. Offer the available services;`,
    BTN_ADD_SERVICE: 'Add Service',
    SERVICES: 'Services',
    BTN_CANCEL: 'Cancel',
    BTN_DELETE: 'Delete',
    CONFIRMATION_MODAL_TITLE: 'Delete Service ?',
    CONFIRMATION_MODAL_MESSAGE: `it will no longer appear to the agents but all registers will be kept. This action will only take effect tomorrow and can't be undone.`,
    CONFIRMATION_MESSAGE_DELETE_OK: 'Service Successfully deleted',
    CONFIRMATION_MESSAGE_DELETE_ERROR: "The service couldn't be deleted now",
  },
  SERVICES_MISSION_FORM: {
    EDIT_TITLE: 'Edit service',
    TITLE: 'Add service',
    ICON_LABEL: 'Icon',
    TITLE_LABEL: 'Title',
    TITLE_PLACEHOLDER: 'ex: Bill payment',
    AVAILABILITY_LABEL: 'Availability',
    AVAILABILITY_PLACEHOLDER: 'The service is enabled.',
    PRICING_METHOD_LABEL: 'Pricing method',
    PRICING_METHOD_SUB_LABEL: "If it's a preset cost or by consumption.",
    PRICING_METHOD_PRESET: 'Preset',
    PRICING_METHOD_VARIABLE: 'Variable',
    PRICING_METHOD_VALUE_LABEL: 'Value',
    PRICING_METHOD_VALUE_SUB_LABEL: 'How much it will cost in rewards points',
    PRICING_METHOD_VALUE_SUFIX: 'points',
    DESCRIPTION_LABEL: 'Description',
    DESCRIPTION_PLACEHOLDER: "Briefly describe what's for and how it works.",
    SUBMIT_SUCCESS: 'Service succesfully created',
    SUBMIT_ERROR: 'The service couldn’t be created now!',
    EDIT_SUBMIT_SUCCESS: 'Changes Successfully Saved!',
    REQUIRED_FIELD_MESSAGE: 'This field is required.',
    EXISTING_TITLE_ERROR_MESSAGE: "There's already a mission with this title, try another one.",
    GREATER_THAN_ZERO_FIELD_MESSAGE: 'number must be greater than zero',
  },
  ERROR: {
    SOMETHING_WRONG: 'Ooops! Something went wrong.',
    TRY_AGAIN: 'Try again',
    TRY_LATER: 'Something went wrong. Please try again later.',
  },
  TOAST: {
    TRY_AGAIN: 'Try again',
  },
  RICH_TEXT: {
    BOLD: 'Bold',
    ITALIC: 'Italic',
    BULLET_LIST: 'Bullet list',
    NUMBERED_LIST: 'Numbered list',
    PLACEHOLDER_PT1: 'Example: Greet the customer and tell the reason of the call.',
    PLACEHOLDER_PT2:
      'Then remind them about the benefits of purchasing directly from the BEES App.',
  },
};

export default en;
