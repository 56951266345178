import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '../../../config/featureToggles';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import { UpdateServiceCatalogPositionRequest } from '../domains/UpdateServiceCatalogPositionRequest';

const PATH = '/grow-missions/admin';
const ENDPOINT = 'services/position';

export async function updateServicesCatalogPosition(
  request: UpdateServiceCatalogPositionRequest,
  vendorId?: string,
): Promise<any> {
  const log = useLogService();

  const isEditSponsoredV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const url = `${getApiHostGrowMissionsService()}${PATH}/`;
  let uri = '';

  if (isEditSponsoredV2Enabled) {
    uri = `v2/${ENDPOINT}/?vendorId=${vendorId ?? ''}`;
  } else {
    uri = `${ENDPOINT}`;
  }

  return axios
    .put(`${url}${uri}`, request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
