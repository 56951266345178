import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Store = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="store-path-1"
          d="M9.25 25.413c3.083 0 5.798-1.52 7.474-3.849 1.615 2.324 4.295 3.85 7.335 3.85a8.926 8.926 0 007.326-3.832 9.163 9.163 0 007.449 3.831c5.882 0 10.331-5.7 8.894-11.406L44.924 2.873A3.8 3.8 0 0041.234 0H6.744a3.799 3.799 0 00-3.688 2.873L.269 13.941c-.795 3.156.25 5.906 2.096 8.41v21.83c0 2.095 1.691 3.8 3.789 3.8h35.685a3.795 3.795 0 003.79-3.8V22.148c0-.919-.772-1.663-1.691-1.663-.92 0-1.692.744-1.692 1.663V44.18c0 .262-.145.52-.407.52h-10.46V31.714c0-.918-.747-1.675-1.666-1.675H18.281c-.92 0-1.666.757-1.666 1.675V44.7H6.154c-.262 0-.406-.258-.406-.52V24.715c1.025.456 2.265.698 3.502.698zM33.019 3.281h8.215c.219 0 .408.17.462.381l2.803 11.145c.912 3.62-1.91 7.275-5.651 7.275a5.832 5.832 0 01-5.83-5.837V3.28zm-14.558 0h11.277v12.964c0 3.13-2.449 5.843-5.645 5.843-3.249 0-5.632-2.744-5.632-5.896V3.28zm1.537 30.141h7.997V44.7h-7.997V33.422zM3.494 14.752l2.788-11.09a.497.497 0 01.462-.381h8.333v12.91c0 3.25-2.625 5.894-5.875 5.897-3.782-.003-6.63-3.67-5.708-7.336z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(8 8.01)">
          <mask id="store-mask-2" fill="#fff">
            <use xlinkHref="#store-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#store-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-8 -8.01)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Store.defaultProps = {
  width: 24,
  height: 24,
};

export default Store;
