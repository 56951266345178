import { FormatDateOptions, MessageDescriptor } from 'react-intl';
import provider, { DEFAULT_LANGUAGE } from './provider';

let intlProvider = provider(DEFAULT_LANGUAGE);

export const updateIntlProvider = (language: string): void => {
  intlProvider = provider(language);
};

export const formatMessage = (message: MessageDescriptor): string =>
  intlProvider.formatMessage(message);

export const formatDate = (date: number | Date, options?: FormatDateOptions): string =>
  intlProvider.formatDate(date, {
    timeZone: 'UTC',
    ...options,
  });
