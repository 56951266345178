import React, { ReactText } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import ErrorIconToastify from '../../assets/icons/ErrorIconToast';
import SuccessIconToastify from '../../assets/icons/SuccessIconToast';
import WarningIconToastify from '../../assets/icons/WarningIconToast';

export enum TOAST_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
}

export enum TOAST_COLORS {
  SUCCESS = '#E6F2EB',
  ERROR = '#FFDEDE',
  WARNING = '#ffebd0',
  INFO = '#E9F1FF',
}

export const toastContainerId = 'toastContainer';

interface CustomToastProps {
  message?: string;
  toastId?: string;
  type?: TOAST_TYPES;
  button?: JSX.Element;
}

const CustomToast = ({
  type = TOAST_TYPES.DEFAULT,
  message,
  toastId = message,
  button,
}: CustomToastProps): ReactText => {
  const content = button ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="body2">{message}</Typography>
      <div style={{ fontFamily: 'Work Sans' }}> {button} </div>
    </div>
  ) : (
    <Typography variant="body2">{message}</Typography>
  );

  if (type && type === TOAST_TYPES.SUCCESS) {
    return toast(content, {
      icon: <SuccessIconToastify />,
      style: {
        backgroundColor: TOAST_COLORS.SUCCESS,
      },
      toastId,
      containerId: toastContainerId,
    });
  }

  if (type && type === TOAST_TYPES.ERROR) {
    return toast(content, {
      icon: <ErrorIconToastify />,
      style: {
        backgroundColor: TOAST_COLORS.ERROR,
      },
      toastId,
      containerId: toastContainerId,
    });
  }

  if (type && type === TOAST_TYPES.WARNING) {
    return toast(content, {
      icon: <WarningIconToastify />,
      style: {
        backgroundColor: TOAST_COLORS.WARNING,
      },
      toastId,
      containerId: toastContainerId,
    });
  }

  // TODO: create a toast with type info and color: #E9F1FF

  return toast(message);
};

export default CustomToast;
