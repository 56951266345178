import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Currency = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="currency-path-1"
          d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0zm0 4.364C13.155 4.364 4.364 13.155 4.364 24c0 10.845 8.791 19.636 19.636 19.636 10.845 0 19.636-8.791 19.636-19.636 0-10.845-8.791-19.636-19.636-19.636zm0 1.454c1.119 0 2.041.842 2.167 1.928l.015.254-.001 3.636h5.092a2.182 2.182 0 01.254 4.35l-.254.014H26.18v5.818h1.455a7.273 7.273 0 01.4 14.535l-.4.01h-1.455V40a2.182 2.182 0 01-4.348.254L21.818 40v-3.637h-6.545a2.182 2.182 0 01-.255-4.348l.255-.015h6.545v-5.819h-1.454a7.273 7.273 0 01-.4-14.534l.4-.01 1.454-.001V8c0-1.205.977-2.182 2.182-2.182zm3.636 20.364l-1.455-.001V32h1.455a2.91 2.91 0 00.28-5.805l-.28-.013zM21.818 16h-1.454a2.91 2.91 0 00-.28 5.805l.28.013h1.454V16z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(8 7.667)">
          <mask id="currency-mask-2" fill="#fff">
            <use xlinkHref="#currency-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#currency-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-8 -7.667)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Currency.defaultProps = {
  width: 24,
  height: 24,
};

export default Currency;
