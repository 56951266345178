import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import { CatalogItem } from '../domains/GetServiceCatalogResponse';

export async function getServicesCatalog(vendorId?: string): Promise<CatalogItem[]> {
  const isCustomMissionV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);
  const log = useLogService();
  let url = `${getApiHostGrowMissionsService()}/grow-missions/admin/services`;

  if (isCustomMissionV2Enabled) {
    url = `${getApiHostGrowMissionsService()}/grow-missions/admin/v2/services?vendorId=${
      vendorId ?? ''
    }`;
  }

  return (
    axios
      .get<CatalogItem[]>(`${url}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        log.error(error);
        throw error;
      })
  );
}
