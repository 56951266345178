import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: 'flex',
      borderRadius: '0.5rem',
      maxWidth: '100%',
    },
    title: {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.875rem',
    },
    errorLabel: {
      color: 'rgb(201,32,29)',
      fontWeight: 'bold',
    },
    textarea: {
      width: '100%',
      minWidth: '100%',
      borderRadius: '0.5rem',
      border: `solid 1px ${palette.grey.A100}`,
      padding: '1rem',
      outline: 'none',
      '&:focus': {
        border: `solid 1px ${palette.common.black}`,
      },
      fontSize: '1rem',
      lineHeight: '1.5',
      maxWidth: '100%',
      fontFamily: 'Work Sans',
    },
    textareaContainer: {
      flex: 1,
      maxWidth: '100%',
    },
    textareaInfos: {
      display: 'flex',
      justifyContent: 'end',
      color: palette.text.secondary,
    },
    counter: {
      marginTop: '-5px',
      fontSize: '0.750rem',
      color: palette.text.disabled,
    },
    subtitle: {
      fontSize: '0.750rem',
      color: palette.text.disabled,
    },
    errorMessage: {
      fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '$4',
      paddingBottom: '$1',
      display: 'flex',
      alignItems: 'center',
      color: 'rgb(201,32,29)',
    },
  }),
);
