import { useLogService } from 'admin-portal-shared-services';

import axios from '../../../../config/axios/axiosInstance';
import { CustomMissionById } from '../../../../domains/CustomMission';
import { getApiHostGrowMissionsService } from '../../../host/HostService';

export async function getSponsoredMissionByIdV3(missionId: string): Promise<CustomMissionById> {
  const baseUrl = `${getApiHostGrowMissionsService()}/v3/custom-missions/${missionId}`;

  const log = useLogService();

  return axios
    .get<CustomMissionById>(`${baseUrl}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
