import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    wrapper: {
      height: 'calc(95vh - 3.75rem)',
      width: '100vw',
      marginTop: '0.25rem',
    },
    container: {
      height: '100%',
      width: '100%',
      marginTop: '0.625rem',
      paddingLeft: '6.5rem',
      paddingRight: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '24px',
    },
    mainTitle: {
      fontSize: '32px',
      fontWeight: 600,
      color: palette.info.dark,
      marginLeft: '16px',
      paddingTop: '4px',
      width: 'fit-content',
    },
    scrollableContent: {
      margin: '1.5rem',
      padding: '0.5rem',
      maxHeight: '100%',
      overflowY: 'auto',
    },
    mainContent: {
      width: '100%',
      height: '92.5%',
      margin: '0rem 0em',
      display: 'flex',
      overflow: 'hidden',
      boxShadow: '0rem 0.5rem 1.5rem 0rem rgb(0 0 0 / 16%)',
      paddingTop: '0',
      borderRadius: '27px',
      marginBottom: '0em',
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
    },
    returnButton: {
      height: '48px',
      width: '48px',
      background: palette.grey[100],
      '&:hover': {
        background: palette.grey[100],
      },
    },
    returnContainer: {
      display: 'flex',
    },
    textareaContent: {
      marginBottom: '34px',
    },
    catalogManagementContent: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0.5rem',
      maxWidth: '100%',
      rowGap: '1rem',
    },
    catalogManagementHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.5rem',
      fontFamily: 'Barlow',
    },
    listContainer: {
      borderRadius: '0.5rem',
      maxWidth: '100%',
      border: '1px solid  rgba(0,0,0,0.15)',
    },
    serviceModal: {
      minWidth: '580px',
    },

    listItem: {
      width: '100%',
      display: 'block',
      padding: '1rem',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&:last-child': {
        border: 'none',
      },
    },
    draggingListItem: {
      background: '#ebebeb',
    },
  }),
);
