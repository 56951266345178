import React from 'react';
import LabelStyles from './Label.styles';

interface Props {
  children: React.ReactChild;
  subLabel?: string;
  error?: boolean;
}

const Label = ({ children, error, subLabel }: Props): JSX.Element => {
  const classes = LabelStyles({ error });
  return (
    <>
      <span className={classes.text}>{children}</span>
      {subLabel && (
        <span data-testid="label-sub-description" className={classes.subLabel}>
          {subLabel}
        </span>
      )}
    </>
  );
};

export default Label;
