import { updateServicesCatalogPosition } from '../../../services/servicesMission/updateServicesCatalogPosition/updateServicesCatalogPosition';
import { servicesCatalogError400 } from './ServicesCatalogEvents';
import { UpdateServiceCatalogPositionRequest } from './domains/updateServicesCatalogPositionRequest';

export function execute(
  request: UpdateServiceCatalogPositionRequest,
  vendorId?: string,
): Promise<any> {
  return updateServicesCatalogPosition(request, vendorId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;

      if (code === 400) {
        servicesCatalogError400();
      }

      throw error;
    });
}
