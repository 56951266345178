import { createEffect, createEvent } from 'effector';

import {
  GetCustomMissionCsvByIdProps,
  CustomMissionByIdProps,
} from '../../../domains/CustomMission';
import { GetPocDownloadCSVFileUseCase } from '../../../usecase/pocDownloadCSVFile/GetPocDownloadCSVFileUseCase';
import { execute as GetSponsoredMissionById } from './GetSponsoredMissionByIdUseCase';

export const setIsSponsoredMission = createEvent<boolean>('setIsSponsoredMission');

export const getSponsoredMissionByIdEffect = createEffect({
  async handler({ missionId, vendorId = null }: CustomMissionByIdProps) {
    return GetSponsoredMissionById({ missionId, vendorId });
  },
});

export const getSponsoredMissionCsvFileEffect = createEffect({
  async handler({
    missionId,
    fileName,
    type,
    vendorId = null,
    vendorName = null,
  }: GetCustomMissionCsvByIdProps) {
    const result = await GetPocDownloadCSVFileUseCase({
      missionId,
      type,
      fileName,
      vendorId,
      vendorName,
    });
    return result as File;
  },
});

export const resetSponsoredMissionByIdEffect = createEvent('resetSponsoredMissionById');
