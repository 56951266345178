import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    containerBody: {
      display: 'flex',
    },
    loadingView: {
      left: 'calc(50% - 22px)',
      top: 'calc(50% - 22px)',
      position: 'absolute',
    },
  }),
);

export default useStyles;
