import React from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { CatalogItem } from '../../../../../stores/servicesMission/ServiceScript/ServicesCatalogState';
import DraggableListItem from '../DraggableListItem/DraggableListItem';
import useStyles from './DraggableList.styles';

interface DraggableListProps {
  list: Array<CatalogItem>;
  onDragEnd?: OnDragEndResponder;
  handleEdit: ({ id }: CatalogItem) => void;
  handleDelete: (item: CatalogItem) => void;
}

const DraggableList = React.memo(
  ({ list, onDragEnd, handleEdit, handleDelete }: DraggableListProps) => {
    const classes = useStyles();

    return (
      <div className={classes.list}>
        <DragDropContext onDragEnd={onDragEnd} data-testid="dragNdrop-context">
          <Droppable droppableId="droppable" data-testid="droppable-list">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list?.map((item, index) => (
                  <DraggableListItem
                    catalogItem={item}
                    index={index}
                    key={item.id}
                    data-testid="draggable-list-item"
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  },
);

export default DraggableList;
