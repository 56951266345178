import React from 'react';
import Label from '../../../../components/Label/Label';
import { formatMessage } from '../../../../i18n/formatters';
import Styles from './IconSelectInput.styles';
import { getIcons } from '../../../../config/utils/functions';
import IconButton from './components/IconButton/IconButton';

export interface IconOptions {
  package: string;
  calendar: string;
  gift: string;
  currency: string;
  shield: string;
  zap: string;
  store: string;
  globe: string;
  barcode: string;
  starUnfilled: string;
}
interface Props {
  onChange(val: keyof IconOptions): void;
  value: keyof IconOptions;
}

const IconSelectInput = ({ onChange, value }: Props): JSX.Element => {
  const classes = Styles();
  const icons = [
    'package',
    'calendar',
    'gift',
    'currency',
    'shield',
    'zap',
    'store',
    'globe',
    'barcode',
    'starUnfilled',
  ];

  const handleIconButtonClick = (val: keyof IconOptions) => {
    onChange(val);
  };

  return (
    <>
      <Label>{formatMessage({ id: 'SERVICES_MISSION_FORM.ICON_LABEL' })}</Label>
      <div className={classes.iconWrapper}>
        {icons.map((icon) => {
          return (
            <IconButton
              onClick={handleIconButtonClick}
              icon={getIcons(icon, { width: 24, height: 24 })}
              name={icon as keyof IconOptions}
              active={value === icon}
              key={icon}
            />
          );
        })}
      </div>
    </>
  );
};

export default IconSelectInput;
