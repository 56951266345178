import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '@config/featureToggles';
import { getUserInfos } from '@config/utils/functions';
import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../config/axios/axiosInstance';
import { getApiHostGrowMissionsService } from '../../host/HostService';
import { RequestBody, Response } from '../domains/CreateServicesMission';

export async function createServiceMission(body: RequestBody): Promise<Response> {
  const { vendorId } = getUserInfos();

  const isCustomMissionV2 = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const baseUrl = `${getApiHostGrowMissionsService()}/grow-missions/admin`;
  const url = isCustomMissionV2
    ? `${baseUrl}/v2/services?vendorId=${vendorId ?? ''}`
    : `${baseUrl}/services`;

  const log = useLogService();

  return axios
    .post<Response>(`${url}`, body)
    .then((response) => {
      log.info(`${url} returned success`);
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
