import { createStore } from 'effector';
import { GetSponsoredMissionByIdState } from './GetSponsoredMissionByIdState';
import {
  getSponsoredMissionByIdEffect,
  getSponsoredMissionCsvFileEffect,
  resetSponsoredMissionByIdEffect,
  setIsSponsoredMission,
} from './GetSponsoredMissionByIdEvents';

const initialState: GetSponsoredMissionByIdState = {
  loading: false,
  done: false,
  error: false,
  loadingCsv: false,
  doneCsv: false,
  errorCsv: false,
  isSponsoredMission: false,
  SponsoredMissionById: {
    id: null,
    audience: null,
    csvFile: null,
    csvFileName: null,
    csvFileSize: null,
    missionTitle: null,
    missionType: null,
    missionCategory: null,
    startDate: null,
    endDate: null,
    createDate: null,
    sponsorPartnerId: null,
    script: null,
    segmentation: null,
    country: null,
    effectiveness: null,
    pocsFiltering: {
      blockList: false,
      deliveryWindow: false,
      digitalClassification: [],
    },
    enabled: false,
    combos: [],
    items: [],
    stopCallingWhen: '',
    fullName: null,
    tags: [],
    email: null,
  },
};

const SponsoredMissionByIdStore = createStore(initialState)
  .on(getSponsoredMissionByIdEffect.done, (state: GetSponsoredMissionByIdState, payload) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: false,
      SponsoredMissionById: payload.result,
    };
  })
  .on(getSponsoredMissionByIdEffect.fail, (state: GetSponsoredMissionByIdState) => {
    return {
      ...state,
      loading: false,
      done: true,
      error: true,
    };
  })
  .on(setIsSponsoredMission, (state: GetSponsoredMissionByIdState, isChecked: boolean) => {
    return {
      ...state,
      isSponsoredMission: isChecked,
    };
  })
  .on(getSponsoredMissionByIdEffect, (state: GetSponsoredMissionByIdState) => {
    return { ...state, loading: true, done: false, error: false };
  })
  .on(getSponsoredMissionCsvFileEffect.done, (state: GetSponsoredMissionByIdState, payload) => {
    return {
      ...state,
      loadingCsv: false,
      doneCsv: true,
      errorCsv: false,
      SponsoredMissionById: {
        ...state.SponsoredMissionById,
        csvFile: payload.result,
      },
    };
  })
  .on(getSponsoredMissionCsvFileEffect.fail, (state: GetSponsoredMissionByIdState) => {
    return {
      ...state,
      loadingCsv: false,
      doneCsv: true,
      errorCsv: true,
    };
  })
  .on(getSponsoredMissionCsvFileEffect, (state: GetSponsoredMissionByIdState) => {
    return { ...state, loadingCsv: true, doneCsv: false, errorCsv: false };
  })
  .reset(resetSponsoredMissionByIdEffect);

export default SponsoredMissionByIdStore;
