export const ROOT_NAME = '/';
export const BASE_NAME = '/beesgrow';
export const WRAPPER_PROJECT_NAME_INSTANCE = 'cx-tlp-portal-admin-mfe';
export const LOCAL_STORAGE_PORTAL_CONFIG = 'portal-config';
export const ENTER_KEY_CODE = 'Enter';
export const SERVICE_MISSION = '#digital_services';

export enum PACKAGE_TYPE {
  EA = 'EA',
  DS = 'DS',
  CS = 'CS',
}
