import MessageMap from '../domains/MessageMap';

const ptBR: MessageMap = {
  TargetCallList: {
    TITLE: 'Call list',
    COMPLETED_TEXT: 'Chamadas encerradas',
    TARGET_TEXT: 'Meta',
  },
  HomeMainPage: {
    ERROR_GET_AGENT_KPIS:
      'Não conseguimos recuperar sua informação neste momento. Por favor, tente novamente mais tarde.',
    CALL_LIST_COMPLETE: 'A lista de chamadas está completa, todos os PDVs foram contatados',
    HAS_OPEN_CALL: 'Retomar o último cliente',
    GET_NEXT_POC: 'Ir para o próximo cliente',
    UPDATE_CARD_BUTTON: 'Atualizar',
    MONTHLY_RESULTS: 'Resultados mensais',
    DAILY_RESULTS: 'Resultados diários',
    NO_SEGMENTATION:
      'Entre em contato com seu administrador/supervisor para associar uma segmentação ao seu perfil.',
  },
  HitRate: {
    TITLE: 'Hit rate',
    COMPLETED_TEXT: 'Chamadas atendidas',
    TARGET_TEXT: 'Total de chamadas',
  },
  AverageHandleTime: {
    TITLE: 'TMA',
    COMPLETED_TEXT: 'Tempo em chamadas',
    TARGET_TEXT: 'Meta',
    TIME: 'min',
  },
  ToplineUplift: {
    TITLE_CD: 'Topline convertido',
    COMPLETED_TEXT: 'Convertido',
    TARGET_TEXT: 'Meta',
    TOPLINE_TOTAL_GAP: 'Gap total',
  },
  ErrorHandling_KPI: {
    ERROR_UPDATE_KPI:
      'Não foi possível acessar seus dados por enquanto. Tente novamente mais tarde.',
  },
  Search_Component: {
    CLEAR_SEARCH_BUTTON: 'Limpar busca',
    ITEMS_FOUND_LABEL: 'Itens encontrados',
    PLACEHOLDER: 'Pesquisar por PDV',
  },
  NPS: {
    NPS_TITLE: 'Net Promoter Score (NPS)',
    NPS_ERROR: 'NPS could not be loaded. Please, try again soon.',
    NPS_CLOSE: 'Answer later',
    NPS_SUBMIT: 'Submit',
  },
  TargetProducts: {
    TARGET_PRODUCTS: 'TARGET PRODUCTS',
    UNIT_PRICE: 'Price (Unit)',
    NOT_FOUND: 'There are no items to be displayed',
  },
  TotalPerformance: {
    TITLE: 'Total performance',
    EFFECTIVE_CALLS: 'Effective calls',
    TOTAL_CALLS: 'Total calls',
    REVENUE_UPLIFTED: 'Net revenue uplifted',
    TARGET_UPLIFTED: 'Target',
  },
  MISSIONS_MANAGEMENT: {
    LOCAL_MISSION: 'Missões locais',
    NEW_MISSION: 'Criar missão',
    TITLE: 'Gerenciamento de missões',
    PRIMARY_TAB_LABEL: 'Missões primárias',
    SECONDARY_TAB_LABEL: 'Missões secundárias',
    APPLY_CHANGES_BUTTON: 'Aplicar mudanças',
    REWARDSCHALLENGES_TITLE: 'Desafios com recompensas',
    PRIMARY_MISSION_SPONSORED_TITLE: 'Patrocinadas',
    PRIMARY_MISSION_SERVICES_TITLE: 'Serviços',
    LOCAL_MISSIONS_TITLE: 'Missões locais',
    TOPLINEGAP_DESCRIPTION:
      'Entenda quais itens não estão sendo adquiridos pelo seu cliente e recomende produtos para ajudá-lo a crescer.',
    MARKETPLACE_DESCRIPTION: 'Ofereça produtos de marketplace.',
    LOCAL_MISSIONS_DESCRIPTION:
      'Missões customizadas criadas pela equipe de back office de operações.',
    REWARDSCHALLENGES_DESCRIPTION:
      'Oriente seu cliente a aceitar e participar de desafios e assim ganhar pontos.',
    DIGITAL_SERVICER_DESCRIPTION: 'Ofereça novos serviços para nossos clientes.',
    PRIMARY_MISSIONS_OBS:
      'As missões primárias definirão os PDVs da call list e sua ordem.\n\nEscolha as missões que ficarão ativas e reordene sua prioridade arrastando os cards.',
    SECONDARY_MISSIONS_OBS:
      'As missões secundárias serão mostradas aos PDVs que já estão na call list caso elas atendam aos critérios da missão. Escolha as missões ativas.',
    ACTIVE: 'Ativa',
    INACTIVE: 'Inativa',
    PRIMARY_MISSION_CARD_TYPE: 'Missão primária',
    SECONDARY_MISSION_CARD_TYPE: 'Missão secundária',

    SECONDARY_MISSION_QUICKORDER: 'Pedido fácil',
    SECONDARY_MISSION_REWARDSREDEMPTION: 'Resgate de pontos',
    SECONDARY_MISSION_TOPLINEGAP: 'Topline gap',
    SECONDARY_MISSION_REWARDSCHALLENGES: 'Desafios com recompensas',

    BUTTONS_AREA_ANYCHANGES_TEXT: 'Quaisquer alterações feitas só entrarão em vigor amanhã.',
    BUTTONS_AREA_DISCARDCHANGES_BUTTON: 'Descartar mudanças',
    BUTTONS_AREA_SAVECHANGES_BUTTON: 'Salvar alterações',

    APPLY_CHANGES_MODAL_TITLE: 'Tem certeza que deseja fazer essas mudanças?',
    APPLY_CHANGES_MODAL_DESCRIPTION: 'Levará apenas alguns minutos para ativar suas alterações.',
    APPLY_CHANGES_MODAL_DESCRIPTION_SECONDARY: 'As mudanças estarão disponíveis somente amanhã.',
    APPLY_CHANGES_CONFIRM: 'Sim, aplicar',
    APPLY_CHANGES_CANCEL: 'Não, cancelar',

    DISCARD_CHANGES_MODAL_TITLE: 'Tem certeza que deseja sair?',
    DISCARD_CHANGES_MODAL_DESCRIPTION:
      'As alterações que você fez no gerenciamento da missão serão perdidas.',
    DISCARD_CHANGES_CONFIRM: 'Sim, sair',
    DISCARD_CHANGES_CANCEL: 'Não, cancelar',

    DISCARD_CHANGES_TAB_MODAL_TITLE: 'Tem certeza que deseja mudar de aba?',
    DISCARD_CHANGES_TAB_MODAL_DESCRIPTION: 'As alterações que você fez nessa aba serão perdidas.',
    DISCARD_CHANGES_TAB_CONFIRM: 'Sim, trocar',
    DISCARD_CHANGES_TAB_CANCEL: 'Não, cancelar',
    EDIT_TEXT: 'Editar',

    ERROR_TO_APPLY_CHANGES: 'Algo deu errado! Suas alterações não foram salvas, tente novamente.',
    LOADING_TEXT: 'Processando...',
    SUCCESS_UPDATE_MISSIONS: 'As alterações foram feitas com sucesso.',
    ERROR_UPDATE_MISSIONS_ALL_DISABLED: 'Você deve ter pelo menos 1 missão primária ativa.',
    ERROR_TO_LOAD_PRIMARY_MISSIONS: 'Não foi possível carregar as missões primárias.',
    ERROR_TO_LOAD_SECONDARY_MISSIONS: 'Não foi possível carregar as missões secundárias.',
    ERROR_UPDATE_MISSIONS_INVALID_FIRST_POSITION:
      'As alterações não puderam ser salvas. Topline gap ou Marketplace devem estar no primeiro lugar da lista.',

    SECONDARY_WARNING_TOAST:
      'As mudanças nas missões secundárias estarão disponíveis somente amanhã!',

    PRIMARY_MISSION_SERVICES_SWITCH_HOVER:
      'Esta missão exige exclusividade do agente. Vá para a configuração do agente para ativá-lo para agentes específicos.',
    SPONSORED_MISSIONS: 'Missões patrocinadas',
    NEW_SPONSORED_MISSIONS: 'Nova missão patrocinada',
    NEW_SPONSORED_MISSION_FIRST_STEP: 'Detalhes principais',
    NEW_SPONSORED_MISSION_SECOND_STEP: 'Conteúdo',
    NEW_SPONSORED_MISSION_THIRD_STEP: 'Configuração de agentes',
    NEW_SPONSORED_MISSION_FOURTH_STEP: 'Call list',
    SERVICES_MISSIONS: 'Objetivo dos serviços',

    BACK_BUTTON: 'Voltar',
    FINISH_BUTTON: 'Finalizar',
    NEXT_BUTTON: 'Próximo',
    CANCEL_BUTTON: 'Cancelar',
    PROCESSING_BUTTON: 'Procesando...',

    NEW_SPONSORED_MISSION_TITLE: 'Título da missão',
    NEW_SPONSORED_MISSION_TITLE_EXEMPLE: 'ex: Nova Michelob Ultra Longneck',
    NEW_SPONSORED_SPONSOR_PARTNER: 'Parceiro patrocinador',
    NEW_SPONSORED_SPONSOR_PARTNER_EXEMPLE: 'ex: Diageo',
    NEW_SPONSORED_START_DATE: 'Data de início',
    NEW_SPONSORED_END_DATE: 'Data final',

    NEW_SPONSORED_MANDATORY_FIELD: 'Este campo é obrigatório.',
    NEW_SPONSORED_MANDATORY_CHAR: 'O título da missão não pode conter esses caracteres especiais.',
    NEW_SPONSORED_FIRST_STEP_INVALID_END_DATE:
      'A data de término deve ser posterior à data de início.',
    NEW_SPONSORED_FIRST_STEP_INVALID_CHARACTER: 'Suportamos apenas letras, números e hífens.',
    NEW_SPONSORED_FIRST_STEP_INVALID_TITLE: 'Já existe uma missão com este título, tente outro.',

    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_LABEL_TITLE: 'Alocação da equipe',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_PLACEHOLDER: 'Insira a especialização',
    NEW_SPONSORED_THIRD_STEP_INVALID_CHARACTER: 'Suportamos apenas letras, números e hífens.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_MAX_LENGTH: 'Suportamos o máximo de 30 caracteres.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_HINT:
      'Os agentes que irão realizar a missão são aqueles que possuem a mesma especialização definida acima. Para atribuir especializações aos agentes, você precisa acessar a Configuração do agente.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_TOOLTIP:
      'Na página de configuração do agente, você seleciona os agentes e atribui uma especialização. Esta mesma especialização precisa ser inserida nesta página.\n\nÉ possível definir todos os agentes para realizar esta missão, para isso você deve atribuir a todos eles a mesma especialização.',

    NEW_SPONSORED_CANT_CHANGE_STEP: 'Você não pode ir para o próximo passo agora. ',

    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT1: 'Arraste as missões para',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT2_BOLD: ' reordená-las',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT3: ' e',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT4_BOLD: ' priorizá-las',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT5: ' de acordo com suas necessidades.',
    NEW_MISSIONS_MANAGEMENT_ERROR_UPDATE_MISSIONS_ALL_DISABLED:
      'Você deve ter pelo menos 1 missão ativa.',

    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_TITLE: 'Descartar alterações?',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'As alterações serão descartadas imediatamente e esta ação não poderá ser desfeita.',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CONFIRM: 'Sim, descartar',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CANCEL: 'Cancelar',

    NEW_MISSIONS_MANAGEMENT_ERROR_TO_LOAD_MISSIONS: 'Não foi possível carregar as missões.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING: 'Parâmetros',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING_SUBTEXT:
      'Estes são os principais parâmetros e número das missões definidas para seu negócio.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_TITLE: 'Ajuda',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_SUBTITLE: 'Mudando parâmetros',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_TEXT:
      'Para alterar parâmetros, entre em contato com a equipe comercial responsável.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_SUBTITLE: 'Excedendo o limite',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_TEXT:
      'Quando o limite é excedido, as missões de baixa prioridade talvez não sejam mostradas para agentes.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FOOTER_TEXT:
      'Para garantir que uma missão seja exibida, torne-a a prioridade máxima.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TITLE: 'Limite compartilhado de missões',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TEXT:
      'O máximo de missões que podem ser realizadas por cada agente. Isso ajuda a manter a eficácia das missões.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALMISSIONS: 'Missões globais',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALTOOLTIP:
      'Topline Gap, Marketplace, Desafios  e Resgate de Recompensas',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALMISSIONS: 'Missões locais',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALTOOLTIP:
      'As missões locais com maior prioridade são as que serão atribuídas aos agentes.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TOTAL: 'Total',
    TAG_INPUT_LABEL_FIELD_TYPE: '(Opcional)',
    TAG_INPUT_PLACEHOLDER: 'Exemplo: PortfolioExpansion, BeyondBeer',
  },
  LOCAL_MISSIONS_FILTER: {
    LABEL: 'Filtrar',
    DATE_ERROR: 'Insira uma data válida',
    CLEAR_ALL: 'Limpar tudo',
    CANCEL: 'Cancelar',
    APPLY: 'Aplicar',
    SPONSORED: 'Mostrar apenas as patrocinadas',
    STATUS_WARNING:
      'As missões pausadas, expiradas e concluídas são mantidas no histórico por 90 dias após atingirem seu período de duração.',
  },
  LOCAL_MISSIONS: {
    TABLE_DESCRIPTION:
      'Crie missões para contatos e período de tempo específicos, tudo isso seguindo seus critérios.',
    CREATE_NEW_MISSION: 'Criar nova missão',
    ACTIVE: 'Ativas',
    ALL: 'Todas as missões',
    TABLE_START: 'Início',
    TABLE_END: 'Fim',
    TABLE_PERIOD: 'Período',
    TABLE_IDENTIFICATION: 'Identificação',
    TABLE_TYPE: 'Tipo',
    TABLE_TYPE_THRIVE: 'Crescimento',
    TABLE_TYPE_COVERAGE: 'Cobertura',
    TABLE_TYPE_SIMPLE: 'Simples',
    TABLE_STATUS: 'Status',
    TABLE_PRIORITY: 'Prioridade',
    TABLE_SCHEDULED: 'Agendada',
    TABLE_ONGOING: 'Em andamento',
    TABLE_CONCLUDED: 'Concluída',
    TABLE_EXPIRED: 'Expirada',
    TABLE_PAUSED: 'Pausada',
    TABLE_CATEGORY: 'Categoria',
    TABLE_OTHERS: 'Outros',
    TOAST_SAVE_SUCCESS: 'As alterações foram salvas com sucesso.',
    TOAST_SAVE_ERROR: 'As alterações não puderam ser salvas.',
    TOAST_PRIORITY_EDIT_SUCCESS_BODY: 'A prioridade da missão foi alterada com sucesso de',
    TOAST_PRIORITY_EDIT_SUCCESS_FINAL: 'Essa mudança entrará em vigor amanhã',
    TOAST_PRIORITY_EDIT_SUCCESS_PREPOSITION: 'para',
    TOAST_PRIORITY_EDIT_ERROR: 'A prioridade da missão não pôde ser alterada. Tente novamente.',

    NOT_RESULTS_FOUND: 'Nenhum resultado encontrado',
    NOT_RESULTS_DESCRIPTION:
      'Tente pesquisar novamente por um nome ou legenda da missão ou use outros filtros.',

    TITLE_FIRST_STEP: 'Principal',
    TITLE_SECOND_STEP: 'Conteúdo',
    TITLE_THIRD_STEP: 'Audiência',
    TITLE_FOURTH_STEP: 'Time',
    MISSION_TYPE_TITLE: 'Tipo',
    MISSION_TYPE_COVERAGE: 'Cobertura',
    MISSION_TYPE_SIMPLE: 'Simples',
    MISSION_TYPE_SURVEY: 'Pesquisa',
    MISSION_TYPE_SERVICES: 'Serviços',
    MISSION_CATEGORY: 'Categoria',
    MISSION_CATEGORY_DIGITAL_EDUCATION: 'Educação Digital',
    MISSION_CATEGORY_VALUE_CREATION: 'Criação de Valor',

    STEP_FOUR_TEAM_ALLOCATION_TITLE: 'Alocação da equipe',
    STEP_FOUR_SEGMENTATION_LABEL_TITLE: 'Segmentação',
    STEP_FOUR_SEGMENTATION_INPUT_PLACEHOLDER: 'Digite o nome da segmentação',
    STEP_FOUR_SEGMENTATION_DESCRIPTION:
      'Adicione uma segmentação para atribuir colaboradores para realizar esta missão.',
    TIME_PERIOD_TITLE: 'Período de tempo',
    TIME_PERIOD_SUBTITLE: 'Defina uma data inicial e final.',

    SPONSOR_TITLE: 'Patrocinador',
    SPONSOR_OPTIONAL_TAG: '(Opcional)',
    SPONSOR_SUBTITLE: 'Parceiro que patrocina esta missão.',
    SPONSOR_PLACEHOLDER: 'Digite o nome do parceiro',
    ERROR_TO_LOAD_LOCAL_MISSIONS: 'Não foi possível carregar as missões',

    SPONSOR_CHECKBOX_TITLE: 'Missão patrocinada',
    SPONSOR_CHECKBOX_SUBTITLE: 'Há um parceiro patrocinando esta missão',
    SPONSOR_CHECKBOX_PLACEHOLDER: 'Coloque o nome do patrocinador',
    SPONSOR_CHECKBOX_ERROR: 'Você precisa adicionar o nome do patrocinador',

    SPONSOR_DROPDOWN_PLACEHOLDER: 'Selecione um patrocinador',
    SPONSOR_DROPDOWN_ERROR: 'Você precisa selecionar um patrocinador',
    SPONSOR_DROPDOWN_REQUEST_ERROR: 'Não foi possível carregar os patrocinadores.',

    DUPLICATE_TITLE: 'Duplicar missão',
    DUPLICATE_BUTTON: 'Duplicar',
    DUPLICATE_CANCEL_BUTTON: 'Cancelar',

    DELETE_BUTTON: 'Excluir / Apagar',
    DELETE_MODAL_TITLE: 'Excluir esta missão?',
    DELETE_MODAL_DESCRIPTION:
      'A missão será excluída imediatamente e esta ação não pode ser desfeita.',
    DELETE_MODAL_DELETE_BUTTON: 'Sim, excluir',
    DELETE_MODAL_CANCEL_BUTTON: 'Voltar',
    DELETE_MODAL_TOAST_SUCCESS: 'A missão foi deletada com sucesso.',
    DELETE_MODAL_TOAST_ERROR: 'Não é possível excluir esta missão.',

    EXPORT_BUTTON: 'Exportar relatório',

    DUPLICATE_MANDATORY_FIELD: 'Este campo é obrigatório.',
    DUPLICATE_MANDATORY_CHAR: 'O título da missão não pode conter esses caracteres especiais.',
    DUPLICATE_INVALID_END_DATE: 'A data de término deve ser posterior à data de início.',
    DUPLICATE_INVALID_CHARACTER: 'Suportamos apenas letras, números e hífens.',

    DUPLICATE_REQUIRED_LIST_ITEM: `É obrigatório adicionar pelo menos um item na lista`,
    DUPLICATE_SEGMENTATION_INPUT_MAX_LENGTH: 'Suportamos o máximo de 30 caracteres.',
    DUPLICATE_DIGITALIZATION_MANDATORY: 'É necessário marcar pelo menos um nível.',
    DUPLICATE_CSV_REQUIRED: 'O upload de um arquivo é obrigatório.',

    DUPLICATE_DISCARD_CHANGES_MODAL_TITLE: 'Descartar alterações?',
    DUPLICATE_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'As alterações serão descartadas imediatamente e esta ação não poderá ser desfeita.',
    DUPLICATE_MODAL_DISCARD_BUTTON: 'Sim, descartar',
    DUPLICATE_MODAL_CANCEL_BUTTON: 'Cancelar',

    MISSION_DETAILS_TITLE: 'Detalhes da missão',

    MISSION_DETAILS_LABEL_CONTENT_NONE_TEXT: 'Nenhum',

    MISSION_DETAILS_MAIN_SECTION_TITLE: 'Principal',
    MISSION_DETAILS_MAIN_SECTION_ACTIVATION_LABEL: 'Ativação',
    MISSION_DETAILS_MAIN_SECTION_STATUS_LABEL: 'Status',
    MISSION_DETAILS_MAIN_SECTION_TYPE_LABEL: 'Tipo',
    MISSION_DETAILS_MAIN_SECTION_CATEGORY_LABEL: 'Categoria',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_LABEL: 'Patrocinador',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_VALUE_NOT_SPONSORED: 'Nenhum (não patrocinado)',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_LABEL: 'Período',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_TO_TEXT: 'até',
    MISSION_DETAILS_MAIN_SECTION_TAGS_LABEL: 'Tags',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_LABEL: 'Criada em',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_BY_TEXT: 'Por',
    MISSION_DETAILS_MAIN_SECTION_STATUS_ON_TEXT: 'On',
    MISSION_DETAILS_MAIN_SECTION_STATUS_OFF_TEXT: 'Off',

    MISSION_DETAILS_TEAM_SECTION_TITLE: 'Time',
    MISSION_DETAILS_TEAM_SECTION_SEGMENTATION_LABEL: 'Segmentação',

    MISSION_DETAILS_CONTENT_SECTION_TITLE: 'Conteúdo',
    MISSION_DETAILS_CONTENT_SECTION_SCRIPT_LABEL: 'Roteiro',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_LABEL: 'Critérios de eficácia',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_QUANTITY_LABEL: 'Mínimo do total',

    MISSION_DETAILS_AUDIENCE_SECTION_TITLE: 'Audiência',
    MISSION_DETAILS_AUDIENCE_SECTION_CSV_PREVIEW_DOWNLOAD_BUTTON: 'Baixar',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_TITLE: 'Filtros',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_DIGITALIZATION_LEVEL: 'Nível de digitalização',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_NO_DIGITALIZATION: 'Sem digitalização',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_INITIAL_ADOPTION: 'Adoção inicial',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_ENGAGED: 'Comprometido',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_FULL_DIGITAL: 'Totalmente digital',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL: 'Quando ligar',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL_DELIVERY_WINDOW: 'Janela de entrega',
    MISSION_DETAILS_AUDIENCE_SECTION_STOP_CALLING_WHEN: 'Pare de ligar quando',

    EDIT_BUTTON_TOOLTIP: 'Você só pode editar missões que ainda não começaram',
    PLACEHOLDER_LOCAL_MISSIONS: 'Pesquise pelo nome da missão ou legenda',

    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Ative as missões locais no Gerenciamento de missões, para que o agente possa realizar essas missões.',

    NO_SPONSOR_FOUND: 'Nenhum patrocinador encontrado',
  },
  SPONSORED_MISSIONS: {
    SAVE: 'Salvar',
    NO_ACTIVE_MISSIONS_YET: 'Nenhuma missão ativa criada ainda',
    NO_MISSIONS_YET: 'Nenhuma missão criada ainda.',
    YOU_CAN: 'Você pode',
    CREATE_NEW_MISSIONS: 'criar uma nova missão',
    ARTICLE: 'A missão',
    MISSION_ACTIVATION_SUCCESS_MESSAGE: 'foi ativada com sucesso e entrará em vigor amanhã.',
    MISSION_INACTIVATION_SUCCESS_MESSAGE:
      'foi desativada e essa alteração entrará em vigor amanhã.',
    MISSION_ACTIVATION_ERROR_MESSAGE: 'não pôde ser ativada.',
    MISSION_INACTIVATION_ERROR_MESSAGE: 'não pôde ser desativada.',
    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Ative a missão patrocinada no Gerenciamento de missões, para que o agente possa realizar essas missões.',
    ERROR_TO_LOAD_SPONSORED_MISSIONS: 'Não foi possível carregar as missões patrocinadas.',

    MISSION_TYPE: 'Tipo de missão',
    PACKAGE_TYPE_LABEL: 'Embalagem',
    PACKAGE_TYPE_BOX: 'Caixa',
    PACKAGE_TYPE_UNIT: 'Unidade',
    PACKAGE_TYPE_DISPLAY: 'Display',

    STEP_TWO_LIST_IMG: 'IMG',
    STEP_TWO_LIST_NAMEANDCODE: 'NOME E CÓDIGO',
    STEP_TWO_LIST_DESCRIPTION: 'Descrição',
    STEP_TWO_LIST_MIN_QUANTITY: 'Quantidade mínima',
    STEP_TWO_LIST_SUGGESTEDQUANTITY: 'QUANTIDADE SUGERIDA',
    STEP_TWO_NOPRODUCTS: 'Nenhum produto adicionado ainda.',
    STEP_TWO_LIST_ADDPRODUCTS: ' Você pode usar o campo acima para adicionar.',

    STEP_TWO_ERROR_GET_ITEM: 'O item não pode ser adicionado agora.',

    STEP_TWO_PRODUCTS_AND_COMBOS: 'Produtos e Combos',
    STEP_TWO_PRODUCTS_AND_COMBOS_PLACEHOLDER: 'Digite o código do produto ou combo...',
    STEP_TWO_PRODUCTS_AND_COMBOS_SUBTITLE:
      'Os itens e a quantidade sugerida a serem oferecidos na missão.',
    STEP_TWO_PRODUCTS_AND_COMBOS_NEW_SUBTITLE: 'Lista de produtos a serem oferecidos nesta missão.',

    STEP_TWO_NO_ITEMS_FOUND: 'Nenhum item encontrado com este código.',
    STEP_TWO_ITEM_ALREADY_ADDED: 'O item que você está tentando adicionar já está na lista',
    STEP_TWO_ITEM_REQUIRED: 'É obrigatório adicionar pelo menos um item na lista',
    STEP_TWO_MAXIMUM_REACHED:
      'Não foi possível adicionar o item porque o limite máximo foi atingido.',

    STEP_TWO_SCRIPT_TITLE: `Roteiro`,
    STEP_FIELD_OPTIONAL: `(opcional)`,
    STEP_TWO_SCRIPT_HINT: `Defina instruções passo a passo para os agentes.`,
    STEP_TWO_SCRIPT_NEW_HINT: `Defina um breve roteiro para a fala do agente.`,

    STEP_TWO_COMBO_CHIP: 'Combo',
    STEP_TWO_FREE_GOOD_CHIP: 'Grátis',

    STEP_TWO_EFFECTIVENESS_TITLE: `Critérios de eficácia`,
    STEP_TWO_EFFECTIVENESS_SUBTITLE: `Condições para que esta missão seja considerada bem sucedida após sua conclusão.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_TITLE: `Mínimo do total`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_SUBTITLE: `Requer que os agentes marquem a missão como completa.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_QUANTITY_INPUT_TITLE: `Mínimo do total`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_TITLE: `Mínimo por item`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_SUBTITLE: `Requer a venda de uma quantidade mínima de cada item.`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_TITLE: `Verificação do agente`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_SUBTITLE: `Requer que os agentes marquem a missão como completa.`,

    STEP_FOUR_POCS: 'PDVs',
    STEP_FOUR_POCS_UPDATED: 'Lista dos PDVs',

    STEP_FOUR_CSV_TITLE: 'Carregue um arquivo CSV',
    STEP_FOUR_CSV_SUBTITLE: 'ou arraste e solte aqui',
    STEP_FOUR_CSV_TEMPLATE: 'Usar o arquivo CSV do nosso template.',
    STEP_FOUR_CSV_DOWNLOAD_TEMPLATE: 'Baixar template',

    STEP_FOUR_POC_FILTER_TITLE: 'Filtros',

    STEP_FOUR_POC_FILTER_DESCRIPTION:
      'Selecione os filtros para definir os PDVs elegíveis para esta missão.',
    STEP_FOUR_POC_FILTER_DESCRIPTION_UPDATED: 'Filtre os PDVs para esta missão.',

    STEP_FOUR_DELIVERY_WINDOW_TITLE: 'Janela de entrega',
    STEP_FOUR_DELIVERY_WINDOW_DESCRIPTION:
      'Os PDVs só serão contactados nos dias com o melhor lead time.',
    STEP_FOUR_BLOCK_LIST_TITLE: 'Block list',
    STEP_FOUR_BLOCK_LIST_DESCRIPTION: `Os PDVs na lista de bloqueio não serão contatados.`,
    STEP_FOUR_BLOCK_LIST_DESCRIPTION_DISABLED: `Essa configuração é definida por sua unidade de negócios.`,

    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE: 'Escolha os níveis de digitalização',
    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE_UPDATED: 'Nível de digitalização',

    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION:
      'Os grupos selecionados serão contatados. Os PDVs são divididas considerando o percentual de compras via BEES.',
    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION_UPDATED:
      'Os grupos são divididos considerando o percentual de compras via BEES.',

    STEP_FOUR_DIGITALIZATION_TITLE: 'Digitalização',
    STEP_FOUR_DIGITALIZATION_TITLE_UPDATED: 'Sem digitalização',

    STEP_FOUR_DIGITALIZATION_MANDATORY: 'É necessário marcar pelo menos um nível.',

    STEP_FOUR_ADOPTION_TITLE: 'Adoção',
    STEP_FOUR_ADOPTION_TITLE_UPDATED: 'Adoção inicial',

    STEP_FOUR_ENGAGEMENT_TITLE: 'Engajamento',
    STEP_FOUR_ENGAGEMENT_TITLE_UPDATED: 'Comprometido',

    STEP_FOUR_FULL_DIGITAL_TITLE: 'Totalmente digital',
    STEP_FOUR_FULL_DIGITAL_TITLE_UPDATED: 'Totalmente digital',

    STEP_FOUR_CSV_REQUIRED: 'O upload de um arquivo é obrigatório.',
    STEP_FOUR_CSV_ONLY: 'Oferecemos suporte apenas a arquivos CSV.',
    STEP_FOUR_CSV_MAX_SIZE: 'Suportamos arquivos com tamanho máximo de 1 MB.',
    STEP_FOUR_CSV_MAX_SIZE_2MB: 'Suportamos arquivos com tamanho máximo de 2 MB.',
    SUCCESS_CREATE_MISSION: 'A missão foi criada com sucesso e entrará em vigor amanhã.',
    ERROR_CREATE_MISSION: 'A missão não pôde ser criada.',
    WARNING_CREATE_MISSION:
      'Ative a missão patrocinada no gerenciamento de missões, para que os agentes possam realizar essas missões.',
    ERROR_TEMPLATE_CSV:
      'O arquivo não pôde ser carregado porque não está seguindo o formato do modelo.',
    EXPIRED_MISSION_SWITCH_HOVER: 'Não é possível ativar uma missão expirada.',
    SPONSORED_JUST_TOMORROW:
      'As mudanças nas missões patrocinadas estarão disponíveis somente amanhã!',
    SPONSORED_EXPIRED_STARTED: 'Não é possível editar uma missão iniciada ou expirada.',
    EDIT_SPONSORED_MISSIONS: 'Editar missão patrocinada',
    EDIT_MISSION: 'Editar missão',
    SAVE_EDIT_SPONSORED_MISSION_ERROR: 'As alterações não foram salvas.',
    SAVE_EDIT_SPONSORED_MISSION_SUCESS: 'Alterações salvas com sucesso.',
    EDIT_SPONSORED_DOWNLOAD_CSV_FILE: 'Baixar',

    STEP_FOUR_GET_STARTED: 'Comece preenchendo',
    STEP_FOUR_TEMPLATE_FILE: 'nosso template.',
    STEP_FOUR_UPLOAD_FILE: 'Carregar um arquivo',
    STEP_FOUR_DRAG_AND_DROP: 'ou arraste-o e solte aqui',
    STEP_FOUR_CSV_MAX_1MB: 'CSV (máx. 1MB)',
    STEP_FOUR_CSV_MAX_2MB: 'CSV (máx. 2MB)',

    STEP_FOUR_WHEN_TO_CALL: 'Quando ligar',
    STEP_FOUR_WHEN_TO_CALL_DESCRIPTION: 'A melhor hora para ligar para os PDVs.',

    STOP_CALLING_WHEN_LABEL: 'Pare de ligar quando',
    STOP_CALLING_SELECT_OPTION: 'Selecione uma opção',
    STOP_CALLING_FIRST_CONTACT_OPTION: 'O primeiro contato é feito',
    STOP_CALLING_EFFECTIVENESS_OPTION: 'Critérios de eficácia foram alcançados',

    OVERRIDE_CSV_ALERT: 'A segmentação do PDV será substituída pela que você definiu.',

    DISCARD_CHANGES_BUTTON: 'Descartar mudanças',
    EDIT_DISCARD_CHANGES_MODAL_TITLE: 'Descartar alterações?',
    EDIT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'As alterações serão descartadas imediatamente e esta ação não poderá ser desfeita.',
    EDIT_MODAL_DISCARD_BUTTON: 'Sim, descartar',
    EDIT_MODAL_CANCEL_BUTTON: 'Cancelar',
  },
  SERVICES_MISSION: {
    PAGE_TITLE: 'Configurações dos serviços',
    TITLE: 'Roteiro',
    SUBTITLE: 'Definir instruções passo a passo para os agentes.',
    BTN_TITLE: 'Salvar alterações',
    CONFIRMATION_MESSAGE_OK: 'Alterações salvas com sucesso!',
    CONFIRMATION_MESSAGE_ERROR: `As mudanças não puderam ser salvas agora`,
    TEXTAREA_PLACEHOLDER: `1. Apresente o motivo da chamada;\r\n2. Pergunte como está o cliente e tente entender suas necessidades atuais;\r\n3. Oferecer os serviços disponíveis;`,
    BTN_ADD_SERVICE: 'Adicionar serviço',
    SERVICES: 'Serviços',
    BTN_CANCEL: 'Cancelar',
    BTN_DELETE: 'Excluir',
    CONFIRMATION_MODAL_TITLE: 'Excluir Serviço ?',
    CONFIRMATION_MODAL_MESSAGE: `O serviço não aparecerá mais para os agentes, mas todos os registros serão mantidos. Esta ação só entrará em vigor amanhã e não pode ser desfeita.`,
    CONFIRMATION_MESSAGE_DELETE_OK: 'Serviço excluído com sucesso.',
    CONFIRMATION_MESSAGE_DELETE_ERROR: 'O serviço não pôde ser excluído agora',
  },
  SERVICES_MISSION_FORM: {
    EDIT_TITLE: 'Editar serviço',
    TITLE: 'Adicionar serviço',
    ICON_LABEL: 'ícone',
    TITLE_LABEL: 'Título',
    TITLE_PLACEHOLDER: 'ex: pagamento de contas',
    AVAILABILITY_LABEL: 'Disponibilidade',
    AVAILABILITY_PLACEHOLDER: 'O serviço está disponível.',
    PRICING_METHOD_LABEL: 'Método de precificação',
    PRICING_METHOD_SUB_LABEL: 'Se for um custo pré-definido ou por consumo.',
    PRICING_METHOD_PRESET: 'Predefinição',
    PRICING_METHOD_VARIABLE: 'Variável',
    PRICING_METHOD_VALUE_LABEL: 'Valor',
    PRICING_METHOD_VALUE_SUB_LABEL: 'Quanto custará em pontos',
    PRICING_METHOD_VALUE_SUFIX: 'pontos',
    DESCRIPTION_LABEL: 'Descrição',
    DESCRIPTION_PLACEHOLDER: 'Descreva brevemente para que serve e como funciona.',
    SUBMIT_SUCCESS: 'Serviço criado com sucesso.',
    SUBMIT_ERROR: 'O serviço não pôde ser criado agora!',
    EDIT_SUBMIT_SUCCESS: 'Alterações salvas com sucesso!',
    REQUIRED_FIELD_MESSAGE: 'Campo obrigatório.',
    EXISTING_TITLE_ERROR_MESSAGE: 'Já existe uma missão com este título, tente outro.',
    GREATER_THAN_ZERO_FIELD_MESSAGE: 'O número deve ser maior que zero',
  },
  ERROR: {
    SOMETHING_WRONG: 'Ooops! Algo deu errado.',
    TRY_AGAIN: 'Tente novamente',
    TRY_LATER: 'Algo deu errado. Por favor, tente novamente mais tarde.',
  },
  TOAST: {
    TRY_AGAIN: 'Tentar novamente',
  },
  RICH_TEXT: {
    BOLD: 'Negrito',
    ITALIC: 'Itálico',
    BULLET_LIST: 'Lista de marcadores',
    NUMBERED_LIST: 'Lista numerada',
    PLACEHOLDER_PT1: 'Exemplo: cumprimentar o cliente e informar o motivo da ligação.',
    PLACEHOLDER_PT2:
      'Em seguida, lembre-os sobre os benefícios de comprar diretamente do aplicativo BEES.',
  },
};

export default ptBR;
