import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Calendar = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="calendar-path-1"
          d="M30.545 0c1.12 0 2.042.842 2.168 1.927l.014.255v2.181h4.364a6.546 6.546 0 016.541 6.311l.004.235v30.546A6.545 6.545 0 0137.091 48H6.545A6.545 6.545 0 010 41.455V10.909a6.545 6.545 0 016.545-6.545l4.364-.001V2.182a2.182 2.182 0 014.349-.255l.015.255-.001 2.181h13.091V2.182C28.364.977 29.34 0 30.546 0zm8.727 21.818H4.363v19.637c0 1.152.894 2.096 2.027 2.176l.155.005h30.546a2.182 2.182 0 002.182-2.181l-.001-19.637zM10.909 8.727H6.545a2.182 2.182 0 00-2.181 2.182l-.001 6.545h34.909v-6.545a2.182 2.182 0 00-2.025-2.176l-.156-.006h-4.364v2.182a2.182 2.182 0 01-4.349.255l-.014-.255-.001-2.182H15.272v2.182a2.182 2.182 0 01-4.348.255l-.015-.255V8.727z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(10 8)">
            <mask id="calendar-mask-2" fill="#fff">
              <use xlinkHref="#calendar-path-1" />
            </mask>
            <g fill="#000" fillOpacity="0.9" mask="url(#calendar-mask-2)">
              <path d="M0 0H64V64H0z" transform="translate(-10 -8)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Calendar.defaultProps = {
  width: 24,
  height: 24,
};

export default Calendar;
