import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    toastContainer: {
      width: 'auto !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      marginTop: '62px',
      fontFamily: 'Work Sans',
      '& button': {
        padding: '0',
        marginLeft: 'auto',
        '& .admin-portal-grow-missions-MuiTypography-button': {
          textTransform: 'none',
        },
      },
      '& p': {
        overflowWrap: 'break-word',
        marginTop: '3px',
        inlineSize: '240px',
      },
      '& p:only-child': {
        inlineSize: '100%',
        maxWidth: '406px',
      },
    },
    toast: {
      maxWidth: '500px',
      minHeight: '1.5rem',
      borderRadius: '0.5rem',
      color: '#212121',
      '& .Toastify__toast-body': {
        alignItems: 'flex-start',
      },
      '& .Toastify__toast-body>div:last-child': {
        fontFamily: 'Work Sans',
        fontSize: 14,
      },
    },
    closeIcon: {
      fontSize: '16px',
      margin: '10px',
      color: 'rgba(0,0,0,0.55)',
    },
    headerIcon: {
      '&:hover': {
        cursor: 'default',
      },
    },
  }),
);
