import MessageMap from '../domains/MessageMap';

const es419: MessageMap = {
  TargetCallList: {
    TITLE: 'Itinerario',
    COMPLETED_TEXT: 'Llamadas completadas',
    TARGET_TEXT: 'Target',
  },
  HomeMainPage: {
    ERROR_GET_AGENT_KPIS:
      'No se pudo encontrar su información por ahora. Por favor, inténtelo de nuevo más tarde.',
    CALL_LIST_COMPLETE:
      'La lista de llamadas está completa, se han contactado todos los puntos de venta.',
    HAS_OPEN_CALL: 'Volver al cliente anterior',
    GET_NEXT_POC: 'Ir al siguiente cliente',
    UPDATE_CARD_BUTTON: 'Actualizar',
    MONTHLY_RESULTS: 'Resultados mensuales',
    DAILY_RESULTS: 'Resultados diarios',
    NO_SEGMENTATION:
      'Se ponga en contacto con su administrador/supervisor para asociar una segmentación a su perfil.',
  },
  HitRate: {
    TITLE: 'Contactabilidad',
    COMPLETED_TEXT: 'Llamadas efectivas',
    TARGET_TEXT: 'Llamadas totales',
  },
  NextPoc: {
    CALL_LIST_SERVICE_UNAVAILABLE: 'Servicio indisponible. Inténtelo de nuevo más tarde.',
    AGENT_CALL_SERVICE_UNAVAILABLE:
      'Desafortunadamente, nos enfrentamos a problemas técnicos para localizar su POC. Por favor inténtelo de nuevo más tarde.',
    CALL_LIST_SERVICE_UNAUTHORIZED:
      'Servicio no autorizado. Intente cerrar sesión y engressar sesión nuevamente.',
  },
  AverageHandleTime: {
    TITLE: 'TMA',
    COMPLETED_TEXT: 'Tiempo de guardia',
    TARGET_TEXT: 'Target',
    TIME: 'min',
  },
  ToplineUplift: {
    TITLE_CD: 'Topline recuperado',
    COMPLETED_TEXT: 'Recuperado',
    TARGET_TEXT: 'Target',
    TOPLINE_TOTAL_GAP: 'Gap total',
  },
  ErrorHandling_KPI: {
    ERROR_UPDATE_KPI: 'No podemos acceder a sus datos por ahora. Por favor, inténtelo de nuevo.',
  },
  NPS: {
    NPS_TITLE: 'Net promoter score (NPS)',
    NPS_ERROR: 'No se pudo cargar el NPS. Por favor, inténtalo de nuevo en unos minutos.',
    NPS_CLOSE: 'Responder después',
    NPS_SUBMIT: 'Enviar',
  },
  Search_Component: {
    CLEAR_SEARCH_BUTTON: 'Eliminar búsqueda',
    ITEMS_FOUND_LABEL: 'Artículos encontrados',
    PLACEHOLDER: 'Búsqueda por PDV',
  },
  TargetProducts: {
    TARGET_PRODUCTS: 'Productos a ofrecer',
    UNIT_PRICE: 'Precio unitario',
    NOT_FOUND: 'No hay artículos para mostrar.',
  },
  TotalPerformance: {
    TITLE: 'Rendimiento',
    EFFECTIVE_CALLS: 'Llamadas efectivas',
    TOTAL_CALLS: 'Llamadas totales',
    REVENUE_UPLIFTED: 'Ingreso neto recuperado',
    TARGET_UPLIFTED: 'Target',
  },
  MISSIONS_MANAGEMENT: {
    LOCAL_MISSION: 'Misiones locales',
    NEW_MISSION: 'Crear misión',
    TITLE: 'Administración de misiones',
    PRIMARY_TAB_LABEL: 'Misiones primarias',
    SECONDARY_TAB_LABEL: 'Misiones secundarias',
    APPLY_CHANGES_BUTTON: 'Aplicar cambios',
    REWARDSCHALLENGES_TITLE: 'Desafíos',
    PRIMARY_MISSION_SPONSORED_TITLE: 'Patrocinadas',
    PRIMARY_MISSION_SERVICES_TITLE: 'Servicios',
    LOCAL_MISSIONS_TITLE: 'Misiones locales',
    TOPLINEGAP_DESCRIPTION:
      'Comprenda qué artículos no compra su cliente y recomiende productos para ayudarlos a crecer.',
    MARKETPLACE_DESCRIPTION: 'Ofrezca productos del marketplace.',
    LOCAL_MISSIONS_DESCRIPTION:
      'Misiones personalizadas creadas por el equipo administrativo de operaciones.',
    REWARDSCHALLENGES_DESCRIPTION:
      'Guíe a su cliente para que acepte y participe en los desafíos y así gane puntos.',
    DIGITAL_SERVICER_DESCRIPTION: 'Ofrezca nuevos servicios a nuestros clientes.',
    PRIMARY_MISSIONS_OBS:
      'Las misiones primarias definirán los POCs en la lista y su orden.\n\nSelecciona las misiones activas y reordena su prioridad arrastrando las tarjetas. ',
    SECONDARY_MISSIONS_OBS:
      'Las misiones secundarias se mostrarán a los POCs que ya estén en la lista, en caso de que cumplan con los criterios establecidos. Selecciona las misiones activas. ',
    ACTIVE: 'Activo',
    INACTIVE: 'Inactivo',
    PRIMARY_MISSION_CARD_TYPE: 'Misión primaria',
    SECONDARY_MISSION_CARD_TYPE: 'Misión secundaria',

    SECONDARY_MISSION_QUICKORDER: 'Pedido fácil',
    SECONDARY_MISSION_REWARDSREDEMPTION: 'Canje de premios',
    SECONDARY_MISSION_TOPLINEGAP: 'Topline gap',
    SECONDARY_MISSION_REWARDSCHALLENGES: 'Canjear puntos',

    BUTTONS_AREA_ANYCHANGES_TEXT: 'Cualquier cambio realizado solo tendrá efecto mañana.',
    BUTTONS_AREA_DISCARDCHANGES_BUTTON: 'Descartar los cambios',
    BUTTONS_AREA_SAVECHANGES_BUTTON: 'Guardar cambios',

    APPLY_CHANGES_MODAL_TITLE: '¿Seguro que deseas aplicar los cambios?',
    APPLY_CHANGES_MODAL_DESCRIPTION: 'La activación de tus cambios tomará algunos minutos.',
    APPLY_CHANGES_MODAL_DESCRIPTION_SECONDARY: 'Los cambios solo estarán disponibles mañana.',
    APPLY_CHANGES_CONFIRM: 'Sí, aplicar',
    APPLY_CHANGES_CANCEL: 'No, cancelar',

    DISCARD_CHANGES_MODAL_TITLE: '¿Seguro que deseas salir?',
    DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Los cambios realizados en la administración de misiones se perderán.',
    DISCARD_CHANGES_CONFIRM: 'Sí, salir',
    DISCARD_CHANGES_CANCEL: 'No, cancelar',

    DISCARD_CHANGES_TAB_MODAL_TITLE: '¿Seguro que deseas cambiar de pestaña?',
    DISCARD_CHANGES_TAB_MODAL_DESCRIPTION: 'Los cambios que hagas en esta pestaña se perderán.',
    DISCARD_CHANGES_TAB_CONFIRM: 'Sí, cambiar',
    DISCARD_CHANGES_TAB_CANCEL: 'No, cancelar',
    EDIT_TEXT: 'Editar',

    ERROR_TO_APPLY_CHANGES:
      'Algo salió mal. Los cambios no se guardaron, intenta aplicarlos de nuevo.',
    LOADING_TEXT: 'Procesando...',
    SUCCESS_UPDATE_MISSIONS: 'Los cambios han sido aplicados exitosamente.',
    ERROR_UPDATE_MISSIONS_ALL_DISABLED: 'Deberás tener, por lo menos, una misión primaria activa.',
    ERROR_TO_LOAD_PRIMARY_MISSIONS: 'No fue posible cargar las misiones primarias.',
    ERROR_TO_LOAD_SECONDARY_MISSIONS: 'No fue posible cargar las misiones secundarias.',
    ERROR_UPDATE_MISSIONS_INVALID_FIRST_POSITION:
      'No se pudieron guardar los cambios. Topline gap o Marketplace debe estar en el primer lugar de la lista. ',

    SECONDARY_WARNING_TOAST:
      '¡Los cambios en las misiones secundarias estarán disponibles solamente mañana!',

    PRIMARY_MISSION_SERVICES_SWITCH_HOVER:
      'Esta misión requiere la exclusividad del agente. Vaya a la configuración del agente para activarlo para agentes específicos.',
    SPONSORED_MISSIONS: 'Misiones patrocinadas',
    NEW_SPONSORED_MISSIONS: 'Nueva misión patrocinada',
    NEW_SPONSORED_MISSION_FIRST_STEP: 'Detalles principales',
    NEW_SPONSORED_MISSION_SECOND_STEP: 'Contenido',
    NEW_SPONSORED_MISSION_THIRD_STEP: 'Configuración de agentes',
    NEW_SPONSORED_MISSION_FOURTH_STEP: 'Lista',
    SERVICES_MISSIONS: 'Misión de los servicios',

    BACK_BUTTON: 'Volver',
    FINISH_BUTTON: 'Finalizar',
    NEXT_BUTTON: 'Siguiente',
    CANCEL_BUTTON: 'Cancelar',
    PROCESSING_BUTTON: 'Procesando...',

    NEW_SPONSORED_MISSION_TITLE: 'Título de la misión',
    NEW_SPONSORED_MANDATORY_CHAR:
      'El título de la misión no puede contener estos caracteres especiales.',
    NEW_SPONSORED_MISSION_TITLE_EXEMPLE: 'ej: Nuevo Michelob Ultra cuello largo',
    NEW_SPONSORED_SPONSOR_PARTNER: 'Socio patrocinador',
    NEW_SPONSORED_SPONSOR_PARTNER_EXEMPLE: 'ej: Diageo',
    NEW_SPONSORED_START_DATE: 'Fecha de inicio',
    NEW_SPONSORED_END_DATE: 'Fecha final',

    NEW_SPONSORED_MANDATORY_FIELD: 'Este campo es obligatorio.',
    NEW_SPONSORED_FIRST_STEP_INVALID_END_DATE:
      'La fecha de finalización debe ser posterior a la fecha de inicio.',
    NEW_SPONSORED_FIRST_STEP_INVALID_CHARACTER: 'Solo admitimos letras, números y guiones.',
    NEW_SPONSORED_FIRST_STEP_INVALID_TITLE:
      'Ya existe una misión con este título, prueba con otro.',

    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_LABEL_TITLE: 'Asignación de equipo',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_PLACEHOLDER: 'Insertar especialización',
    NEW_SPONSORED_THIRD_STEP_INVALID_CHARACTER: 'Solo admitimos letras, números y guiones.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_INPUT_MAX_LENGTH:
      'Solo admitimos el máximo de 30 caracteres.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_HINT:
      'Los agentes que llevarán a cabo la misión son aquellos que tengan la misma especialización definida anteriormente. Para asignar especializaciones a los agentes, debe acceder a la Configuración del agente.',
    NEW_SPONSORED_THIRD_STEP_SEGMENTATION_TOOLTIP:
      'En la página de configuración de agentes, selecciona agentes y asigna una especialización. Esta misma especialización debe ingresarse en esta página.\n\nEs posible definir todos los agentes para llevar a cabo esta misión, para ello deberás asignarles a todos la misma especialización.',

    NEW_SPONSORED_CANT_CHANGE_STEP: 'No puede ir al siguiente paso ahora. ',

    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT1: 'Arrastra las misiones para',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT2_BOLD: ' reordenarlas',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT3: ' y',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT4_BOLD: ' priorizarlas',
    NEW_MISSIONS_MANAGEMENT_MISSIONS_OBS_PT5: ' según tus necesidades.',
    NEW_MISSIONS_MANAGEMENT_ERROR_UPDATE_MISSIONS_ALL_DISABLED:
      'Deberás tener, por lo menos, una misión activa.',

    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_TITLE: '¿Descartar los cambios?',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Los cambios se descartarán inmediatamente y esta acción no se puede deshacer.',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CONFIRM: 'Si, descartar',
    NEW_MISSIONS_MANAGEMENT_DISCARD_CHANGES_CANCEL: 'Cancelar',

    NEW_MISSIONS_MANAGEMENT_ERROR_TO_LOAD_MISSIONS: 'No fue posible cargar las misiones.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING: 'Parámetros',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HEADING_SUBTEXT:
      'Estos son los principales parámetros y número de misiones definidas para su negocio.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_TITLE: 'Ayuda',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_SUBTITLE: 'Cambiando los parámetros',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FIRST_TEXT:
      'Para cambiar los parámetros, entrar en contacto con el responsable de tu equipo de negocios.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_SUBTITLE: 'Sobrepasando el límite',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_SECOND_TEXT:
      'Cuando se excede el límite, es posible que las misiones de baja prioridad no se muestren a los agentes.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_HELPMODAL_FOOTER_TEXT:
      'Para asegurarse de que se muestre una misión, conviértala en la prioridad más alta.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TITLE: 'Límite de misión compartida',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TEXT:
      'El número máximo de misiones que puede realizar cada agente. Esto ayuda a mantener la eficacia de la misión.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALMISSIONS: 'Misiones globales',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_GLOBALTOOLTIP:
      'Topline gap, Marketplace, Desafíos y canjear puntos',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALMISSIONS: 'Misiones locales',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_LOCALTOOLTIP:
      'Las misiones locales con mayor prioridad son las que se asignarán a los agentes.',
    NEW_MISSIONS_MANAGEMENT_PARAMETERS_MISSIONSLIMIT_TOTAL: 'Total',
    TAG_INPUT_LABEL_FIELD_TYPE: '(Opcional)',
    TAG_INPUT_PLACEHOLDER: 'Ejemplo: PortfolioExpansion, BeyondBeer',
  },
  LOCAL_MISSIONS_FILTER: {
    LABEL: 'Filtrar',
    DATE_ERROR: 'Ingrese una fecha válida',
    CLEAR_ALL: 'Eliminar todos',
    CANCEL: 'Cancelar',
    APPLY: 'Aplicar',
    SPONSORED: 'Mostrar solamente las patrocinadas',
    STATUS_WARNING:
      'Misiones pausadas, expiradas y concluidas permanecerán en el historial 90 luego de alcanzar su período de duración.',
  },
  LOCAL_MISSIONS: {
    TABLE_DESCRIPTION:
      'Crear misiones para contactos específicos y períodos de tiempo, siguiendo tus parámetros.',
    CREATE_NEW_MISSION: 'Crear nueva misión',
    ACTIVE: 'Activas',
    ALL: 'Todas las misiones',
    TABLE_START: 'Inicio',
    TABLE_END: 'Fin',
    TABLE_PERIOD: 'Período',
    TABLE_IDENTIFICATION: 'Identificación',
    TABLE_TYPE: 'Tipo',
    TABLE_TYPE_THRIVE: 'Crecimiento',
    TABLE_TYPE_COVERAGE: 'Cobertura',
    TABLE_TYPE_SIMPLE: 'Simple',
    TABLE_STATUS: 'Estatus',
    TABLE_PRIORITY: 'Prioridad',
    TABLE_SCHEDULED: 'Programada',
    TABLE_ONGOING: 'En curso',
    TABLE_CONCLUDED: 'Concluida',
    TABLE_EXPIRED: 'Expirada',
    TABLE_PAUSED: 'Pausada',
    TABLE_CATEGORY: 'Categoria',
    TABLE_OTHERS: 'Otros',
    TOAST_SAVE_SUCCESS: 'Los cambios se han guardado correctamente.',
    TOAST_SAVE_ERROR: 'No se pudieron guardar los cambios.',
    TOAST_PRIORITY_EDIT_SUCCESS_BODY: 'La prioridad de la misión fue cambiada con éxito, de',
    TOAST_PRIORITY_EDIT_SUCCESS_FINAL: 'Este cambio entrará en vigencia mañana',
    TOAST_PRIORITY_EDIT_SUCCESS_PREPOSITION: 'a',
    TOAST_PRIORITY_EDIT_ERROR: 'La prioridad de la misión no se pudo cambiar. Vuelve a intentar.',

    NOT_RESULTS_FOUND: 'No se encontraron resultados',
    NOT_RESULTS_DESCRIPTION:
      'Intenta buscando de nuevo, mediante un nombre de misión diferente o tag, o utilizando otros filtros.',

    TITLE_FIRST_STEP: 'Principal',
    TITLE_SECOND_STEP: 'Contenido',
    TITLE_THIRD_STEP: 'Audiencia',
    TITLE_FOURTH_STEP: 'Equipo',
    MISSION_TYPE_TITLE: 'Tipo',
    MISSION_TYPE_COVERAGE: 'Cobertura',
    MISSION_TYPE_SIMPLE: 'Simple',
    MISSION_TYPE_SURVEY: 'Busca',
    MISSION_TYPE_SERVICES: 'Servicios',
    MISSION_CATEGORY: 'Categoría',
    MISSION_CATEGORY_DIGITAL_EDUCATION: 'Educación Digital',
    MISSION_CATEGORY_VALUE_CREATION: 'Creación de Valor',

    STEP_FOUR_TEAM_ALLOCATION_TITLE: 'Asignación de equipo',
    STEP_FOUR_SEGMENTATION_LABEL_TITLE: 'Segmentación',
    STEP_FOUR_SEGMENTATION_INPUT_PLACEHOLDER: 'Introduce el nombre de la segmentación',
    STEP_FOUR_SEGMENTATION_DESCRIPTION:
      'Agregue una segmentación para asignar colaboradores para realizar esta misión.',
    TIME_PERIOD_TITLE: 'Período de tiempo',
    TIME_PERIOD_SUBTITLE: 'Establezca una fecha de inicio y finalización.',

    SPONSOR_TITLE: 'Patrocinador',
    SPONSOR_OPTIONAL_TAG: '(Opcional)',
    SPONSOR_SUBTITLE: 'Socio que patrocina esta misión.',
    SPONSOR_PLACEHOLDER: 'Ingrese el nombre del socio',
    ERROR_TO_LOAD_LOCAL_MISSIONS: 'No fue posible cargar las misiones',

    SPONSOR_CHECKBOX_TITLE: 'Misión patrocinada',
    SPONSOR_CHECKBOX_SUBTITLE: 'Hay un socio patrociando esta missión',
    SPONSOR_CHECKBOX_PLACEHOLDER: 'Ingrese el nombre del patrocinador',
    SPONSOR_CHECKBOX_ERROR: 'Necesitas agregar el nombre del patrocinador',

    SPONSOR_DROPDOWN_PLACEHOLDER: 'Seleccione un patrocinador',
    SPONSOR_DROPDOWN_ERROR: 'Necesitas seleccionar un patrocinador',
    SPONSOR_DROPDOWN_REQUEST_ERROR: 'No se pudo cargar los patrocinadores.',

    DUPLICATE_TITLE: 'Duplicar misión',
    DUPLICATE_BUTTON: 'Duplicar',
    DUPLICATE_CANCEL_BUTTON: 'Cancelar',

    DELETE_BUTTON: 'Eliminar',
    DELETE_MODAL_TITLE: '¿Eliminar esta misión?',
    DELETE_MODAL_DESCRIPTION:
      'La misión se eliminará al instante. Esta acción no puede deshacerse.',
    DELETE_MODAL_DELETE_BUTTON: 'Sí, eliminar',
    DELETE_MODAL_CANCEL_BUTTON: 'Volver',
    DELETE_MODAL_TOAST_SUCCESS: 'La misión se eliminó correctamente.',
    DELETE_MODAL_TOAST_ERROR: 'No se pudo eliminar esta misión.',

    EXPORT_BUTTON: 'Exporte informe',

    DUPLICATE_MANDATORY_FIELD: 'Este campo es obligatorio.',
    DUPLICATE_MANDATORY_CHAR:
      'El título de la misión no puede contener estos caracteres especiales.',
    DUPLICATE_INVALID_END_DATE: 'La fecha de finalización debe ser posterior a la fecha de inicio.',
    DUPLICATE_INVALID_CHARACTER: 'Solo admitimos letras, números y guiones.',

    DUPLICATE_REQUIRED_LIST_ITEM: `Es obligatorio agregar al menos un elemento a la lista`,
    DUPLICATE_SEGMENTATION_INPUT_MAX_LENGTH: 'Solo admitimos el máximo de 30 caracteres.',
    DUPLICATE_DIGITALIZATION_MANDATORY: 'Es necesario marcar al menos un nivel.',
    DUPLICATE_CSV_REQUIRED: 'Cargar un archivo es obligatorio.',

    DUPLICATE_DISCARD_CHANGES_MODAL_TITLE: '¿Descartar los cambios?',
    DUPLICATE_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Los cambios se descartarán inmediatamente y esta acción no se puede deshacer.',
    DUPLICATE_MODAL_DISCARD_BUTTON: 'Si, descartar',
    DUPLICATE_MODAL_CANCEL_BUTTON: 'Cancelar',

    MISSION_DETAILS_TITLE: 'Detalles de la misión',

    MISSION_DETAILS_LABEL_CONTENT_NONE_TEXT: 'Ninguno',

    MISSION_DETAILS_MAIN_SECTION_TITLE: 'Principal',
    MISSION_DETAILS_MAIN_SECTION_ACTIVATION_LABEL: 'Activación',
    MISSION_DETAILS_MAIN_SECTION_STATUS_LABEL: 'Estatus',
    MISSION_DETAILS_MAIN_SECTION_TYPE_LABEL: 'Tipo',
    MISSION_DETAILS_MAIN_SECTION_CATEGORY_LABEL: 'Categoría',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_LABEL: 'Patrocinador',
    MISSION_DETAILS_MAIN_SECTION_SPONSOR_VALUE_NOT_SPONSORED: 'Ninguno (no patrocinada)',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_LABEL: 'Período',
    MISSION_DETAILS_MAIN_SECTION_PERIOD_TO_TEXT: 'a',
    MISSION_DETAILS_MAIN_SECTION_TAGS_LABEL: 'Tags',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_LABEL: 'Creado el',
    MISSION_DETAILS_MAIN_SECTION_CREATED_ON_BY_TEXT: 'Por',
    MISSION_DETAILS_MAIN_SECTION_STATUS_ON_TEXT: 'On',
    MISSION_DETAILS_MAIN_SECTION_STATUS_OFF_TEXT: 'Off',

    MISSION_DETAILS_TEAM_SECTION_TITLE: 'Equipo',
    MISSION_DETAILS_TEAM_SECTION_SEGMENTATION_LABEL: 'Segmentación',

    MISSION_DETAILS_CONTENT_SECTION_TITLE: 'Contenido',
    MISSION_DETAILS_CONTENT_SECTION_SCRIPT_LABEL: 'Guion',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_LABEL: 'Criterios de eficacia',
    MISSION_DETAILS_CONTENT_SECTION_EFFECTIVENESS_QUANTITY_LABEL: 'Mínimo del total',

    MISSION_DETAILS_AUDIENCE_SECTION_TITLE: 'Audiencia',
    MISSION_DETAILS_AUDIENCE_SECTION_CSV_PREVIEW_DOWNLOAD_BUTTON: 'Descargar',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_TITLE: 'Filtros',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_DIGITALIZATION_LEVEL: 'Nivel de digitalización',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_NO_DIGITALIZATION: 'Sin digitalización',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_INITIAL_ADOPTION: 'Adopción inicial',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_ENGAGED: 'Comprometido',
    MISSION_DETAILS_AUDIENCE_SECTION_FILTERS_FULL_DIGITAL: 'Totalmente digital',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL: 'Cuando llamar',
    MISSION_DETAILS_AUDIENCE_SECTION_WHEN_TO_CALL_DELIVERY_WINDOW: 'Ventana de entrega',
    MISSION_DETAILS_AUDIENCE_SECTION_STOP_CALLING_WHEN: 'Deja de llamar cuando',

    EDIT_BUTTON_TOOLTIP: 'Solamente es posible editar misiones que aún no han comenzado',
    PLACEHOLDER_LOCAL_MISSIONS: 'Buscar misión por nombre o tag',

    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Active las misiones locales en la Gestión de misiones, para que el agente pueda realizar estas misiones.',

    NO_SPONSOR_FOUND: 'Ningún patrocinador encontrado',
  },
  SPONSORED_MISSIONS: {
    SAVE: 'Guardar',
    NO_ACTIVE_MISSIONS_YET: 'Aún no se han creado misiones activas',
    NO_MISSIONS_YET: 'Aún no se han creado misiones.',
    YOU_CAN: 'Puedes',
    CREATE_NEW_MISSIONS: 'crear una nueva misión',
    ARTICLE: 'La misión',
    MISSION_ACTIVATION_SUCCESS_MESSAGE:
      'fue activada con éxito, y entrará en vigencia a partir de mañana. ',
    MISSION_INACTIVATION_SUCCESS_MESSAGE:
      'ha sido exitosamente desactivada, y entrará en vigencia a partir de mañana. ',
    MISSION_ACTIVATION_ERROR_MESSAGE: 'no pudo activarse. ',
    MISSION_INACTIVATION_ERROR_MESSAGE: 'no pudo desactivarse',
    MISSION_SWITCH_MESSAGE_WITH_SPONSORED_MISSION_DISABLED:
      'Active la misión patrocinada en la Gestión de misiones, para que el agente pueda realizar estas misiones.',
    ERROR_TO_LOAD_SPONSORED_MISSIONS: 'No fue posible cargar las misiones patrocinadas.',

    MISSION_TYPE: 'Tipo de misión',
    PACKAGE_TYPE_LABEL: 'Embalaje',
    PACKAGE_TYPE_BOX: 'Caja',
    PACKAGE_TYPE_UNIT: 'Unidad',
    PACKAGE_TYPE_DISPLAY: 'Display',

    STEP_TWO_LIST_IMG: 'IMG',
    STEP_TWO_LIST_NAMEANDCODE: 'NOMBRE Y CÓDIGO',
    STEP_TWO_LIST_DESCRIPTION: 'Descripción',
    STEP_TWO_LIST_MIN_QUANTITY: 'Cantidad mínima',
    STEP_TWO_LIST_SUGGESTEDQUANTITY: 'CANTIDAD SUGERIDA',
    STEP_TWO_NOPRODUCTS: 'No hay productos añadidos todavía.',
    STEP_TWO_LIST_ADDPRODUCTS: 'Puede usar el campo de arriba para agregar.',

    STEP_TWO_ERROR_GET_ITEM: 'El elemento no se puede agregar ahora.',

    STEP_TWO_PRODUCTS_AND_COMBOS: 'Productos y Combos',
    STEP_TWO_PRODUCTS_AND_COMBOS_PLACEHOLDER: 'Ingresa el código del producto o combo...',
    STEP_TWO_PRODUCTS_AND_COMBOS_SUBTITLE:
      'Los artículos y la cantidad sugerida que se ofrecerán en la misión.',
    STEP_TWO_PRODUCTS_AND_COMBOS_NEW_SUBTITLE:
      'Lista de artículos que se ofrecerán en esta misión.',

    STEP_TWO_NO_ITEMS_FOUND: 'No se encontraron artículos con este código.',
    STEP_TWO_ITEM_ALREADY_ADDED: 'El elemento que está tratando de agregar ya está en la lista',
    STEP_TWO_ITEM_REQUIRED: 'Es obligatorio agregar al menos un elemento a la lista',
    STEP_TWO_MAXIMUM_REACHED: 'No se pudo agregar el artículo porque se alcanzó el límite máximo.',

    STEP_TWO_SCRIPT_TITLE: `Guion`,
    STEP_FIELD_OPTIONAL: `(opcional)`,
    STEP_TWO_SCRIPT_HINT: `Definir instrucciones paso a paso para los agentes.`,
    STEP_TWO_SCRIPT_NEW_HINT: `Definir un breve guión para el agente. `,

    STEP_TWO_COMBO_CHIP: 'Combo',
    STEP_TWO_FREE_GOOD_CHIP: 'Bienes gratis',

    STEP_TWO_EFFECTIVENESS_TITLE: `Criterios de eficacia`,
    STEP_TWO_EFFECTIVENESS_SUBTITLE: `Condiciones para que esta misión se considere exitosa después de su finalización.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_TITLE: `Mínimo del total`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_ONE_SUBTITLE: `Requiere que los agente marquen la misión como completada.`,
    STEP_TWO_EFFECTIVENESS_AT_LEAST_QUANTITY_INPUT_TITLE: `Mínimo del total`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_TITLE: `Mínimo por artículo`,
    STEP_TWO_EFFECTIVENESS_SPECIFIED_QUANTITY_SUBTITLE: `Requiere vender una cantidad mínima de cada artículo.`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_TITLE: `Control del agente`,
    STEP_TWO_EFFECTIVENESS_AGENT_CHECK_SUBTITLE: `Requiere que los agente marquen la misión como completada.`,

    STEP_FOUR_POCS: 'PDVs',
    STEP_FOUR_POCS_UPDATED: 'Lista de PDVs',

    STEP_FOUR_CSV_TITLE: 'Cargue un archivo CSV',
    STEP_FOUR_CSV_SUBTITLE: 'o arrastre y suelte aquí',
    STEP_FOUR_CSV_TEMPLATE: 'Utilizar el archivo CSV de nuestra planilla.',
    STEP_FOUR_CSV_DOWNLOAD_TEMPLATE: 'Descargar planilla',

    STEP_FOUR_POC_FILTER_TITLE: 'Filtros',

    STEP_FOUR_POC_FILTER_DESCRIPTION:
      'Seleccione filtros para definir los PDVs elegibles para esta misión.',
    STEP_FOUR_POC_FILTER_DESCRIPTION_UPDATED: 'Filtra los PDVs para esta misión.',

    STEP_FOUR_DELIVERY_WINDOW_TITLE: 'Ventana de entrega',
    STEP_FOUR_DELIVERY_WINDOW_DESCRIPTION:
      'Solo se contactará a los PDVs en los días con el mejor tiempo de entrega.',
    STEP_FOUR_BLOCK_LIST_TITLE: 'Block list',
    STEP_FOUR_BLOCK_LIST_DESCRIPTION: `No se contactará a los POC en la lista negra.`,
    STEP_FOUR_BLOCK_LIST_DESCRIPTION_DISABLED: `Esta configuración está definida por su unidad de negocio.`,

    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE: 'Elija los niveles de digitalización',
    STEP_FOUR_DIGITAL_CLASSIFICATION_TITLE_UPDATED: 'Nivel de digitalización',

    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION:
      'Los grupos seleccionados serán contactados. Los PDVs se dividen considerando el porcentaje de compras vía BEES.',
    STEP_FOUR_DIGITAL_CLASSIFICATION_DESCRIPTION_UPDATED:
      'Los grupos se dividen considerando el porcentaje de compras a través de BEES.',

    STEP_FOUR_DIGITALIZATION_TITLE: 'Digitalización',
    STEP_FOUR_DIGITALIZATION_TITLE_UPDATED: 'Sin digitalización',

    STEP_FOUR_DIGITALIZATION_MANDATORY: 'Es necesario marcar al menos un nivel.',

    STEP_FOUR_ADOPTION_TITLE: 'Adopción',
    STEP_FOUR_ADOPTION_TITLE_UPDATED: 'Adopción inicial',

    STEP_FOUR_ENGAGEMENT_TITLE: 'Involucramiento',
    STEP_FOUR_ENGAGEMENT_TITLE_UPDATED: 'Comprometido',

    STEP_FOUR_FULL_DIGITAL_TITLE: 'Digital absoluto',
    STEP_FOUR_FULL_DIGITAL_TITLE_UPDATED: 'Totalmente digital',

    STEP_FOUR_CSV_REQUIRED: 'Cargar un archivo es obligatorio.',
    STEP_FOUR_CSV_ONLY: 'Solo admitimos archivos CSV.',
    STEP_FOUR_CSV_MAX_SIZE: 'Admitimos archivos con un tamaño máximo de 1 MB.',
    STEP_FOUR_CSV_MAX_SIZE_2MB: 'Admitimos archivos con un tamaño máximo de 2 MB.',
    SUCCESS_CREATE_MISSION:
      'La misión fue creada exitosamente, y entrará en vigencia a partir de mañana.',
    ERROR_CREATE_MISSION: 'No se pudo crear la misión.',
    WARNING_CREATE_MISSION:
      'Active la misión patrocinada en la gestión de misiones, para que los agentes puedan realizar estas misiones.',
    ERROR_TEMPLATE_CSV: 'No se pudo cargar el archivo porque no sigue el formato de la plantilla.',
    EXPIRED_MISSION_SWITCH_HOVER: 'No es posible activar una misión que se venció.',
    SPONSORED_JUST_TOMORROW:
      '¡Los cambios en las misiones patrocinadas estarán disponibles solamente mañana!',
    SPONSORED_EXPIRED_STARTED: 'No es posible editar una misión iniciada o vencida.',
    EDIT_SPONSORED_MISSIONS: 'Editar misión patrocinada',
    EDIT_MISSION: 'Editar misión',
    SAVE_EDIT_SPONSORED_MISSION_ERROR: `Los cambios no se pudieron guardar.`,
    SAVE_EDIT_SPONSORED_MISSION_SUCESS: 'Cambios guardados exitosamente.',
    EDIT_SPONSORED_DOWNLOAD_CSV_FILE: 'Descargar',

    STEP_FOUR_GET_STARTED: 'Comienza a llenar nuestro',
    STEP_FOUR_TEMPLATE_FILE: 'archivo modelo.',
    STEP_FOUR_UPLOAD_FILE: 'Cargar un archivo',
    STEP_FOUR_DRAG_AND_DROP: 'o arrástralo y suéltalo aquí',
    STEP_FOUR_CSV_MAX_1MB: 'CSV (max. 1MB)',
    STEP_FOUR_CSV_MAX_2MB: 'CSV (max. 2MB)',

    STEP_FOUR_WHEN_TO_CALL: 'Cuando llamar',
    STEP_FOUR_WHEN_TO_CALL_DESCRIPTION: 'El mejor momento para llamar a los PDVs.',

    STOP_CALLING_WHEN_LABEL: 'Deja de llamar cuando',
    STOP_CALLING_SELECT_OPTION: 'Seleccione una opción',
    STOP_CALLING_FIRST_CONTACT_OPTION: 'Se hace el primer contacto',
    STOP_CALLING_EFFECTIVENESS_OPTION: 'Se ha alcanzado el criterio de efectividad',

    OVERRIDE_CSV_ALERT: 'La segmentación del PDV será reemplazada con la que acabas de establecer.',

    DISCARD_CHANGES_BUTTON: 'Descartar cambios',
    EDIT_DISCARD_CHANGES_MODAL_TITLE: '¿Descartar los cambios?',
    EDIT_DISCARD_CHANGES_MODAL_DESCRIPTION:
      'Los cambios se descartarán inmediatamente y esta acción no se puede deshacer.',
    EDIT_MODAL_DISCARD_BUTTON: 'Si, descartar',
    EDIT_MODAL_CANCEL_BUTTON: 'Cancelar',
  },
  SERVICES_MISSION: {
    PAGE_TITLE: 'Misión de servicios',
    TITLE: 'Guion',
    SUBTITLE: 'Definir instrucciones paso a paso para los agentes.',
    BTN_TITLE: 'Guardar cambios',
    CONFIRMATION_MESSAGE_OK: '¡Cambios guardados con éxito!',
    CONFIRMATION_MESSAGE_ERROR: `Los cambios no se pudieron guardar ahora`,
    TEXTAREA_PLACEHOLDER: `1. Introducir el motivo de la llamada;\r\n2. Preguntar cómo está el cliente y tratar de entender sus necesidades actuales;\r\n3. Ofrecer los servicios disponibles;`,
    BTN_ADD_SERVICE: 'Adicionar servicio',
    SERVICES: 'Servicios',
    BTN_CANCEL: 'Cancelar',
    BTN_DELETE: 'Eliminar',
    CONFIRMATION_MODAL_TITLE: '¿Eliminar servicio?',
    CONFIRMATION_MODAL_MESSAGE: `el servicio ya no aparecerá a los agentes pero se mantendrán todos los registros. Esta acción solo tendrá efecto mañana y no se puede deshacer.`,
    CONFIRMATION_MESSAGE_DELETE_OK: 'Servicio eliminado con éxito',
    CONFIRMATION_MESSAGE_DELETE_ERROR: 'El servicio no se pudo eliminar ahora',
  },
  SERVICES_MISSION_FORM: {
    EDIT_TITLE: 'Editar servicio',
    TITLE: 'Adicionar servicio',
    ICON_LABEL: 'ícono',
    TITLE_LABEL: 'Título',
    TITLE_PLACEHOLDER: 'ej: Pago de factura',
    AVAILABILITY_LABEL: 'Disponibilidad',
    AVAILABILITY_PLACEHOLDER: 'El servicio está conectado',
    PRICING_METHOD_LABEL: 'Método de decisión de precios',
    PRICING_METHOD_SUB_LABEL: 'Si es un costo ya establecido o dependerá del consumo.',
    PRICING_METHOD_PRESET: 'Establecer',
    PRICING_METHOD_VARIABLE: 'Variable',
    PRICING_METHOD_VALUE_LABEL: 'Valor',
    PRICING_METHOD_VALUE_SUB_LABEL: 'Cuánto costará en puntos de premios',
    PRICING_METHOD_VALUE_SUFIX: 'puntos',
    DESCRIPTION_LABEL: 'Descripción',
    DESCRIPTION_PLACEHOLDER: 'Breve descripción para qué es y cómo funciona.',
    SUBMIT_SUCCESS: 'Servicio creado con éxito.',
    SUBMIT_ERROR: 'El servicio no se pudo crear ahora!',
    EDIT_SUBMIT_SUCCESS: '¡Cambios guardados con éxito!',
    REQUIRED_FIELD_MESSAGE: 'Este campo es obligatorio.',
    EXISTING_TITLE_ERROR_MESSAGE: 'Ya existe una misión con este título, prueba con otro.',
    GREATER_THAN_ZERO_FIELD_MESSAGE: 'número debe ser mayor que cero',
  },
  ERROR: {
    SOMETHING_WRONG: 'Ooops! Algo salió mal.',
    TRY_AGAIN: 'Intentar otra vez',
    TRY_LATER: 'Hubo un error. Por favor, inténtelo de nuevo más tarde.',
  },
  TOAST: {
    TRY_AGAIN: 'Intentar nuevamente',
  },
  RICH_TEXT: {
    BOLD: 'Negrita',
    ITALIC: 'Cursiva',
    BULLET_LIST: 'Lista con viñetas',
    NUMBERED_LIST: 'Lista ordenada',
    PLACEHOLDER_PT1: 'Ejemplo: Saludar al cliente y decirle el motivo de la llamada.',
    PLACEHOLDER_PT2:
      'Luego, recuérdeles los beneficios de comprar directamente desde la aplicación BEES.',
  },
};

export default es419;
