import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const Shield = ({ width, height }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 64 64"
    >
      <defs>
        <path
          id="shield-path-1"
          d="M18.87.139a2.182 2.182 0 011.532 0l17.455 6.545a2.182 2.182 0 011.416 2.043V24c0 6.479-3.591 12.314-9.473 17.46a49.014 49.014 0 01-6.7 4.9l-.773.464-.98.558-.735.388a2.182 2.182 0 01-1.951 0l-.736-.388-.98-.558-1.192-.724a49.014 49.014 0 01-6.28-4.64C3.59 36.314 0 30.48 0 24V8.727c0-.91.564-1.723 1.416-2.043zm.766 4.373L4.364 10.239V24c0 4.784 2.731 9.408 7.411 13.667l.57.51a44.706 44.706 0 005.72 4.223l.984.597.587.338.588-.338.983-.597a44.706 44.706 0 005.72-4.224c5.027-4.399 7.982-9.2 7.982-14.176V10.24L19.636 4.511zm8.089 13.582c.786.786.847 2.024.181 2.88l-.181.205-8.728 8.727a2.182 2.182 0 01-2.88.182l-.205-.182-4.364-4.363a2.182 2.182 0 012.88-3.267l.206.181 2.82 2.82 7.185-7.183a2.182 2.182 0 013.086 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(12 8)">
          <mask id="shield-mask-2" fill="#fff">
            <use xlinkHref="#shield-path-1" />
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#shield-mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-12 -8)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Shield.defaultProps = {
  width: 24,
  height: 24,
};

export default Shield;
