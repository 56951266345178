import { GROW_ORGANIZATION_ACTIVATION } from '@config/featureToggles';
import { getCSVFileService } from '@services/customMissions/sponsoredMission/getCSVFileService/GetCSVFileService';
import { isFeatureEnabled } from 'grow-shared-services';

import { GetCustomMissionCsvByIdProps } from '../../domains/CustomMission';
import { POC_FILE_TYPE } from '../../domains/PocDownloadCSVFile';
import { getDownloadPocFile } from '../../services/customMissions/sponsoredMission/getDownloadPocFile/getDownloadPocFileV3';
import { getPocReportService } from '../../services/customMissions/sponsoredMission/getPocReportService/GetPocReportService';

export async function GetPocDownloadCSVFileUseCase({
  missionId,
  type,
  fileName,
  vendorId,
  vendorName,
}: GetCustomMissionCsvByIdProps): Promise<ArrayBuffer | File> {
  const isGrowOrganizationActivationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isGrowOrganizationActivationEnabled) {
    const arrayBuffer = await getDownloadPocFile(missionId, type);
    return createFileFromBuffer(arrayBuffer, fileName);
  }

  const shouldFetchCSVFile = type === POC_FILE_TYPE.POC_LIST;
  const arrayBuffer = shouldFetchCSVFile
    ? await getCSVFileService(missionId, fileName, vendorId, vendorName)
    : await getPocReportService(missionId, vendorId, vendorName);

  return createFileFromBuffer(arrayBuffer, fileName);
}

async function createFileFromBuffer(arrayBuffer: ArrayBuffer, fileName: string) {
  const blob = new Blob([arrayBuffer]);
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: 'text/csv',
  });
}
