import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';

import axios from '../../../../config/axios/axiosInstance';
import { TLP_CUSTOM_MISSIONS_ENDPOINT_V2 } from '../../../../config/featureToggles';
import { getApiHost } from '../../../host/HostService';

export async function getCSVFileService(
  missionId: string,
  fileName: string,
  vendorId?: string,
  vendorName?: string,
): Promise<ArrayBuffer> {
  const isGetProductsAndCombosV2Enabled = isFeatureEnabled(TLP_CUSTOM_MISSIONS_ENDPOINT_V2);

  const log = useLogService();
  const baseUrl = `${getApiHost()}/api/v1/tlp-account-service/tlp-account`;
  let uri = '';
  if (isGetProductsAndCombosV2Enabled) {
    uri = `/v2/accounts-custom-mission/${missionId}/download?csvname=${fileName}&vendorId=${
      vendorId ?? ''
    }`;
  } else {
    uri = `/accounts-custom-mission/${missionId}/download?csvname=${fileName}`;
  }

  const getAdditionalHeaders = () => {
    if (isGetProductsAndCombosV2Enabled) {
      return {};
    }

    if (vendorName && vendorId) {
      return { vendorId, vendorName };
    }

    return {};
  };

  return axios
    .get(`${baseUrl}${uri}`, {
      headers: { ...getAdditionalHeaders() },
      responseType: 'blob',
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
