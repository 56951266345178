import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    modalBaseContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContentContainer: {
      backgroundColor: 'white',
      borderRadius: '24px',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      '&:focus': {
        outline: 'none',
      },
      padding: '2rem',
    },
    editPageModalContentContainer: {
      width: '424px',
    },
  }),
);
