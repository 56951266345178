import { createServiceMission } from '../../../services/servicesMission/createServiceMission/createServiceMission';
import CreateServicesMissionRequest from './domains/CreateServicesMissionRequest';
import { servicesMissionError400 } from './ServicesMissionEvents';

export function execute(service: CreateServicesMissionRequest) {
  return createServiceMission(service)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response.status;
      if (code === 400) {
        servicesMissionError400();
      }
      throw error;
    });
}
