import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    infoSection: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '28px',
    },
    infoIcon: {
      margin: '0',
      width: '42px',
      height: '42px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemTitle: {
      color: '#212121',
      fontSize: '0.875rem',
    },
    actionsSection: {
      display: 'flex',
      justifyContent: 'end',
      columnGap: '10px',
    },
    actionsBtn: {
      backgroundColor: 'rgba(0,0,0,0.05) !important',
      height: '40px',
      width: '40px',
    },
    draggableContainer: {
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    draggable: {
      margin: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      cursor: 'pointer',
    },
  }),
);
