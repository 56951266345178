import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    applyButton: {
      backgroundColor: '#000',
      color: '#fff',
      border: 'solid 1px #000',
      padding: '10px 20px',
      fontFamily: 'Work Sans',
    },
    cancelButton: {
      padding: '10px 20px',
      marginRight: 16,
      fontFamily: 'Work Sans',
      border: 'solid 1px #000',
    },
    closeButton: {
      padding: 0,
      height: '32px',
    },
    genericModalContainer: {
      // height: '15rem',
      // width: '33rem',
      display: 'flex',
      flexDirection: 'column',
    },

    icon: {},
    modalButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    modalDescriptionContainer: {
      display: 'flex',
      marginTop: '1rem',
      heigth: '100%',
      width: '100%',
      flex: 1,
      fontWeight: 400,
      fontSize: '1rem',
    },

    modalHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    modalHeaderTitle: {
      width: '65%',
    },
  }),
);
